import * as React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Images as images } from "./mockdata";
import "./App.css";
import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import Gallery from "./Components/Gallery";
import Description from "./Components/Description";
import MobileGallery from "./Components/MobileGallery";
import { mockdataProduct } from "../../data/data";
import { GET_PRODUCT } from "../../services/handleProduct/handleProduct";
import { GET_ALL_REVIEW_OF_PRODUCT } from "../../services/handleReviews/handleReviews";
import { CREATE_CART } from "../../services/handleCart/handleCart";
import { useQuery, useMutation } from "@tanstack/react-query";
import Back from "../../component/Back";

import NavbarSecond from "../../component/NavBarSecond/navbarSecond";
const ProductDetails = ({
  sendDataToBase,
  stateCart,
  stateAccount,
  stateData,
  IsDaiLy,
}) => {
  const [stateProduct, setStateproduct] = React.useState({});
  const { productid } = useParams();
  const [quant, setQuant] = React.useState(0);

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(3);
  const [Sortdate, setSortdate] = React.useState("oldest");
  const [stateReview, setStateReview] = React.useState({});
  const [stateTotalReview, setStateTotalReview] = React.useState(0);
  const [stateTotalPages, setStateTotalPages] = React.useState(0);
  const [orderedQuant, setOrderedQuant] = React.useState(0);
  const [IsSelectStore, setIsSelectStore] = React.useState(false);

  const DataGetProduct = useQuery({
    queryKey: ["GetProduct"],
    queryFn: async () => {
      const result = await GET_PRODUCT(productid);
      if (result?.data) {
        let StateDataProductConfig = {
          ...result?.data?.data,
        };
        setStateproduct(StateDataProductConfig);
      }
      return result; // Ensure the result is returned
    },
  });
  const DataGetAllReview = useQuery({
    queryKey: ["GET_ALL_REVIEW", page, limit],
    queryFn: async () => {
      const result = await GET_ALL_REVIEW_OF_PRODUCT({
        id: productid,
        page: page,
        limit: limit,
        date: Sortdate,
      });
      if (result?.data) {
        setStateReview(result?.data?.data);
        setStateTotalReview(result?.data?.data?.total_reviews);
        setStateTotalPages(result?.data?.data?.total_page);
      }
      return result; // Ensure the result is returned
    },
  });

  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };

  useEffect(() => {
    // Giả sử chúng ta có một số dữ liệu cần gửi lên Base
    let getonOrderedQuant = orderedQuant || 0;
    let getFonReset = resetQuant || null;
    const data = {
      onOrderedQuant: getonOrderedQuant,
      onReset: getFonReset,
      stateProduct: stateProduct,
    };

    sendDataToBase(data);

    window.scrollTo(0, 0);
  }, [orderedQuant]);
  const TrigeerSendBase = () => {
    sendDataToBase();
  };
  const addQuant = () => {
    setQuant(quant + 1);
  };

  const removeQuant = () => {
    setQuant(quant - 1);
  };

  const onSelectStore_RemoveQuant = () => {
    setIsSelectStore(true);
    setQuant(0);
  };

  const resetQuant = () => {
    setQuant(0);
    setOrderedQuant(0);
  };

  return (
    <>
      <NavbarSecond
        stateAccount={stateAccount}
        stateCart={stateCart}
        IsArrow={true}
        className="AddpadingForNavS"
      ></NavbarSecond>
      <main className="App">
        {DataGetProduct.isFetching ? (
          "Đang tải dữ liệu vui lòng chờ ..... "
        ) : (
          <>
            {" "}
            {stateProduct?._id ? (
              <Container
                style={{ backgroundColor: "white", padding: "0px" }}
                component="section"
                maxWidth={"lg"}
              >
                <section style={{ backgroundColor: "white" }} className="core">
                  <MobileGallery
                    IMAGES={stateProduct?.images || []}
                    THUMBS={stateProduct?.images || []}
                  />
                  <Description
                    IsDaiLy={IsDaiLy}
                    DataGetProduct={DataGetProduct}
                    IsSelectStore={IsSelectStore}
                    onSelectStore={onSelectStore_RemoveQuant}
                    TrigeerSendBase={TrigeerSendBase}
                    onQuant={quant}
                    onAdd={addQuant}
                    onRemove={removeQuant}
                    onSetOrderedQuant={setOrderedQuant}
                    stateProduct={stateProduct}
                    //STATUS ACCOUNT
                    stateData={stateData}
                    // Review

                    stateReview={stateReview}
                    stateTotalPages={stateTotalPages}
                    stateTotalReview={stateTotalReview}
                    setLimit={setLimit}
                    limit={limit}
                    isFetching={DataGetAllReview.isFetching}
                  />
                </section>
              </Container>
            ) : (
              "Dữ liệu sản phẩm này này không tìm thấy...."
            )}
          </>
        )}
      </main>
    </>
  );
};
export default ProductDetails;
