// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  width: 100%;
  margin: 10px;
  height: auto;
}

.image {
  display: block;
  width: 100%;

  transition: 0.5s ease;
  backface-visibility: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-container:hover .image {
    cursor: pointer;
  opacity: 0.3;
}

.image-container:hover .overlay {
    cursor: pointer;
  opacity: 1;
}

.text {
    cursor: pointer;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/productDetails/Components/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;;EAEX,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,UAAU;EACV,qBAAqB;EACrB,oCAAoC;AACtC;;AAEA;IACI,eAAe;EACjB,YAAY;AACd;;AAEA;IACI,eAAe;EACjB,UAAU;AACZ;;AAEA;IACI,eAAe;EACjB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;AACpB","sourcesContent":[".image-container {\r\n  position: relative;\r\n  width: 100%;\r\n  margin: 10px;\r\n  height: auto;\r\n}\r\n\r\n.image {\r\n  display: block;\r\n  width: 100%;\r\n\r\n  transition: 0.5s ease;\r\n  backface-visibility: hidden;\r\n}\r\n\r\n.overlay {\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  height: 100%;\r\n  width: 100%;\r\n  opacity: 0;\r\n  transition: 0.5s ease;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.image-container:hover .image {\r\n    cursor: pointer;\r\n  opacity: 0.3;\r\n}\r\n\r\n.image-container:hover .overlay {\r\n    cursor: pointer;\r\n  opacity: 1;\r\n}\r\n\r\n.text {\r\n    cursor: pointer;\r\n  color: white;\r\n  font-size: 20px;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
