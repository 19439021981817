import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import { SEARCH_PRODUCT } from "../../services/handleProduct/handleProduct";
import * as React from "react";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { styled } from "@mui/system";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import IMGSEARCHNOTFOUND from "../../assets/image/productPage/search-not-found.png";
import { LOG_OUT } from "../../services/handleAccount/handleAccount";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRef } from "react";
const NavbarSecond = ({
  className = "",
  stateCart,
  stateAccount,
  IsArrow,
  IsSearch = true,
  IsContactSearch = false,
  IsSticky = true,
  IsBackgroundTransparent = false,
  IsWhiteIcon = false,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [stateName, setStateName] = useState("");
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(10);
  const [stateProduct, setStateProduct] = useState([]);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const boxRef = useRef(null); // Tạo ref để tham chiếu đến Box

  const DataAllProduct = useQuery({
    queryKey: ["Get_All_Product_Search", stateName],
    queryFn: async () => {
      const result = await SEARCH_PRODUCT({
        page: statePage,
        limit: stateLimit,
        name: stateName,
      });
      if (result?.data?.data?.products) {
        setStateProduct(result?.data?.data?.products);
      }

      return result; // Ensure the result is returned
    },
  });
  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0 || window.pageYOffset <= 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsShowSearch(false);
      }
    };

    // Gắn sự kiện click vào document
    document.addEventListener("mousedown", handleClickOutside);

    // Dọn dẹp sự kiện khi component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let nav = useNavigate();

  const handleGoBack = () => {
    nav(-1); // Lùi về trang trước
  };
  return (
    <>
      <header
        style={{ background: IsBackgroundTransparent ? "transparent" : "" }}
        className={`${className ? "AddpadingForNavS" : ""}  ${
          IsSticky ? "sticky-header" : ""
        }   ${isScrolled ? "scrolled" : ""}`}
      >
        <nav
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {IsArrow ? (
            <ArrowBackIcon
              color="primary"
              sx={{ cursor: "pointer", color: IsWhiteIcon ? "white" : "" }}
              onClick={handleGoBack}
            ></ArrowBackIcon>
          ) : (
            ""
          )}
          {IsSearch ? (
            <div style={{ position: "relative", width: "100%" }}>
              {" "}
              {/* Đặt section và Box bên trong một container có position: relative */}
              <section style={{ flex: 0.9 }} className="left">
                <TextField
                  variant="outlined"
                  placeholder="Tìm kiếm sản phẩm"
                  onClick={() => {
                    if (IsContactSearch) {
                      setIsShowSearch(true);
                    } else {
                      nav("/product");
                    }
                  }}
                  value={stateName}
                  onChange={(e) => {
                    setStateName(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment sx={{ height: "40px" }} position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                    },
                  }}
                  fullWidth
                  sx={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "20px",
                    height: "40px",
                    cursor: "pointer",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none", // Xóa viền ngoài
                      },
                      "&:hover fieldset": {
                        border: "none", // Xóa viền khi hover
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // Xóa viền khi có focus
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px",
                      height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
                    },
                    "& .MuiInputBase-input": {
                      padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
                    },
                  }}
                />
              </section>
              {/* Thêm ô Box với position: absolute */}
              {isShowSearch ? (
                <Box
                  ref={boxRef}
                  sx={{
                    position: "absolute",
                    top: "calc(100% + 10px)", // Luôn nằm dưới section với khoảng cách 10px
                    left: 0,
                    right: 0,
                    padding: "16px",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    display: "flex",
                  }}
                >
                  {DataAllProduct.isFetching ? (
                    <>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            {" "}
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Skeleton
                              variant="rectangular"
                              width={210}
                              height={60}
                            />
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            {" "}
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Skeleton
                              variant="rectangular"
                              width={210}
                              height={60}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </>
                  ) : (
                    <>
                      {" "}
                      {stateProduct.length > 0 ? (
                        <>
                          <List
                            style={{ maxHeight: "500px", overflow: "scroll" }}
                          >
                            {stateProduct &&
                              stateProduct.map((item, index) => (
                                <ListItem>
                                  <ListItemIcon>
                                    <img
                                      width={35}
                                      height={35}
                                      src={item?.images[0]?.path}
                                    ></img>
                                  </ListItemIcon>
                                  <ListItemText
                                    sx={{
                                      "& .MuiListItemText-primary": {
                                        fontSize: "14px",
                                        color: "#393E40",
                                        fontWeight: 700,
                                      },
                                      "& .MuiListItemText-primary:hover": {
                                        color: "green",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={() => {
                                      nav(`/pDetails/${item?._id}`);
                                    }}
                                    primary={item?.name}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </>
                      ) : (
                        <Box margin={"auto"}>
                          <img
                            style={{ margin: "auto" }}
                            src={IMGSEARCHNOTFOUND}
                          ></img>
                          {/* Nội dung của Box */}
                        </Box>
                      )}
                    </>
                  )}

                  <Button
                    variant="text"
                    sx={{
                      position: "absolute",
                      top: "8%", // Canh giữa theo chiều dọc
                      right: "3px", // Khoảng cách từ button đến cạnh phải của Box
                      transform: "translateY(-50%)", // Điều chỉnh để button luôn ở giữa chiều cao của Box
                    }}
                    onClick={() => {
                      setIsShowSearch(false);
                    }}
                  >
                    <ClearRoundedIcon
                      sx={{ color: "#393E40" }}
                    ></ClearRoundedIcon>
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          <div className="right">
            <IconButton
              disableRipple
              sx={{ backgroundColor: IsWhiteIcon ? "white" : "" }}
              onClick={() => {
                // setShowCart(!showCart);
                nav("/cart");
              }}
            >
              <Badge
                invisible={
                  stateCart?.purchase_items
                    ? stateCart?.purchase_items.length === 0
                    : ""
                }
                badgeContent={
                  stateCart?.purchase_items
                    ? stateCart?.purchase_items.length
                    : 0
                }
                variant="standard"
                sx={{
                  color: "#fff",
                  fontFamily: "Kumbh sans",
                  fontWeight: 700,
                  "& .css-fvc8ir-MuiBadge-badge ": {
                    fontFamily: "kumbh sans",
                    fontWeight: 700,
                  },
                }}
              >
                <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.925 3.641H3.863L3.61.816A.896.896 0 0 0 2.717 0H.897a.896.896 0 1 0 0 1.792h1l1.031 11.483c.073.828.52 1.726 1.291 2.336C2.83 17.385 4.099 20 6.359 20c1.875 0 3.197-1.87 2.554-3.642h4.905c-.642 1.77.677 3.642 2.555 3.642a2.72 2.72 0 0 0 2.717-2.717 2.72 2.72 0 0 0-2.717-2.717H6.365c-.681 0-1.274-.41-1.53-1.009l14.321-.842a.896.896 0 0 0 .817-.677l1.821-7.283a.897.897 0 0 0-.87-1.114ZM6.358 18.208a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm10.015 0a.926.926 0 0 1 0-1.85.926.926 0 0 1 0 1.85Zm2.021-7.243-13.8.81-.57-6.341h15.753l-1.383 5.53Z"
                    fill="#69707D"
                    fillRule="nonzero"
                  />
                </svg>
              </Badge>
            </IconButton>

            <div class="btn-group">
              <SettingsIcon
                id="triggerId"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                sx={{ color: IsWhiteIcon ? "white" : "#898989" }}
              ></SettingsIcon>

              <div
                class="dropdown-menu dropdown-menu-start"
                aria-labelledby="triggerId"
                style={{ padding: "0px" }}
              >
                <a
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  href="/#/infor/password"
                  class="dropdown-item"
                >
                  <LockOutlinedIcon
                    sx={{ marginRight: "5px", fontSize: "16px" }}
                  ></LockOutlinedIcon>{" "}
                  <Typography
                    fontSize={"14px"}
                    sx={{ padding: "5px", marginTop: "2px" }}
                  >
                    Đổi mật khẩu
                  </Typography>
                </a>

                <a
                  class="dropdown-item"
                  style={{
                    cursor: "pointer",
                    color: "red",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    MutationLogout.mutate(
                      {},
                      {
                        onSuccess: (data) => {
                          localStorage.clear("token");
                          nav("/login");
                        },
                        onError: (err) => {
                          localStorage.clear("token");
                          nav("/login");
                        },
                      }
                    );
                  }}
                >
                  <LogoutOutlinedIcon
                    sx={{ marginRight: "5px", fontSize: "16px" }}
                  ></LogoutOutlinedIcon>{" "}
                  <Typography
                    fontSize={"14px"}
                    sx={{ padding: "5px", marginTop: "2px" }}
                  >
                    {" "}
                    Đăng xuất
                  </Typography>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default NavbarSecond;
