import { Avatar, Box, Button, Typography } from "@mui/material";
import Navbar from "../../component/Navbar/navbar";
import { GET_ALL_ACCOUNT } from "../../services/handleHomepage/handleHomepage";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
const Homepage = ({ stateProduct, stateCart, stateUser = [] }) => {
  return (
    <>
      <Navbar
        stateProduct={stateProduct}
        stateCart={stateCart}
        activeIndex={0}
        // handleItemClick={handleItemClick}
      ></Navbar>

      <Box className="AddpadingLeftProduct">
        {/* SỰ kiện thông báo */}
        {/* <Box
          borderTop={"solid 10px #eeedee"}
          borderBottom={"solid 10px #eeedee"}
          p={"15px"}
        >
          <Box
            pt={2}
            pb={2}
            borderBottom={"1px solid #a8adaf"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100px"}
            >
              <CalendarMonthOutlinedIcon></CalendarMonthOutlinedIcon>
              <Typography
                sx={{ fontSize: "14px", color: "#393E40", fontWeight: "700" }}
              >
                SỰ KIỆN
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100px"}
              color={"#1B3FE4"}
            >
              <Typography
                sx={{
                  color: "#1B3FE4",
                  fontSize: "13px",

                  fontWeight: "400",
                }}
              >
                Xem thêm
              </Typography>
              <ChevronRightOutlinedIcon></ChevronRightOutlinedIcon>
            </Box>
          </Box>
          <Box
            sx={{ overflowX: "scroll", overflowY: "hidden" }}
            width={"100%"}
            pt={2}
            pb={2}
            display={"flex"}
          >
            {[...Array(3)].map((item, inex) => (
              <Box
                pr={1}
                mr={1}
                borderRight={"1px solid #a8adaf"}
                display={"flex"}
                width={"300px"}
              >
                <Box display={"flex"} gap={1} flex={1} flexDirection={"column"}>
                  <Typography
                    display={"inline-block"}
                    sx={{
                      fontSize: "14px",
                      color: "#393E40",
                      fontWeight: "700",
                    }}
                    width={"160px"}
                    whiteSpace={""}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    [BACK TO SCHOOL] TRỢ THỦ ĐẮC LỰC
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#393E40",
                      fontWeight: "700",
                    }}
                    width={"170px"}
                  >
                    14:00 - 16/08/2024
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#393E40",
                    }}
                    width={"170px"}
                  >
                    TIKPII
                  </Typography>
                </Box>
                <Box width={"200px"} borderRadius={"10px"} bgcolor={"green"}>
                  <img
                    src={
                      "https://png.pngtree.com/png-vector/20221222/ourmid/pngtree-trendy-geometric-elements-with-upcoming-events-text-png-image_6533184.png"
                    }
                    width={"1005"}
                    height={"100%"}
                  ></img>
                </Box>
              </Box>
            ))}
          </Box>
        </Box> */}

        <Box
          bgcolor={"#fafbff"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          height={"80vh"}
          p={4}
          gap={2}
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          {stateUser.length > 0 ? (
            stateUser &&
            stateUser.map((item, index) => (
              <Box
                p={"16px"}
                sx={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "24px",
                  boxShadow: " 0 2px 6px rgba(0, 0, 0, 0.2)",
                  display: "flex",
                }}
                className="row"
              >
                <Box className="col-3">
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    src={item?.seller_info?.avatar}
                  ></Avatar>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  className="col-9"
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                      color: "#1F1F1F",
                      fontWeight: "600",
                    }}
                  >
                    {item?.seller_info?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#1F1F1F",
                    }}
                  >
                    Vừa có đơn hàng trong ngày, trị giá
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FF6314",
                      fontWeight: "600",
                    }}
                  >
                    đ{" "}
                    {parseInt(item?.purchase_total_price).toLocaleString(
                      "en-us"
                    )}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography p={3}>Đang tải dữ liệu ... </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
export default Homepage;
