import { Box, Button, Modal, Typography } from "@mui/material";
import Back from "../../component/Back";
import {
  Create_send_payment,
  GET_ALL_REQUEST_UPGRADE,
} from "../../services/handleInfor/handleInfor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../component/function";
import { styleModal, Input } from "../../component/component";
import { CloseModal } from "../../component/function";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ListUpgrade = () => {
  const [stateImg, setStateImg] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [stateListRQUpgrade, setStateListRQUpgrade] = useState([]);
  const [stateModal, setStateModal] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const DATA_ALL_PACKAGE = useQuery({
    queryKey: ["ALL_PACKAGE"],
    queryFn: async () => {
      const rs = await GET_ALL_REQUEST_UPGRADE();
      if (rs?.data?.data) {
        setStateListRQUpgrade(rs?.data?.data);
      }
      return rs;
    },
  });
  const mutation_send_payment = useMutation({
    mutationFn: (body) => {
      return Create_send_payment(body);
    },
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStateImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSend = (id) => {
    setLoading(true);
    if (imageFile) {
      const dataForm = new FormData();

      dataForm.append("image", imageFile);
      dataForm.append("upgrade_id", id);

      mutation_send_payment.mutate(dataForm, {
        onSuccess: (data) => {
          if (data?.data) {
            alert("Thao tác thành công ");
            setLoading(false);
            setImageFile(null);
            setStateImg("");
            handleClose();
            DATA_ALL_PACKAGE.refetch();
          } else {
            alert("Vui lòng thử lại !!");
            setLoading(false);
          }
        },
        onError: (err) => {
          console.log("err : ", err);
          alert("Vui lòng thử lại !!");
          setLoading(false);
        },
      });
    } else {
      alert("Vui lòng gửi hoá đơn giao dịch !!");
      setLoading(false);
    }
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Back content={"Danh sách yêu cầu nâng cấp"}></Back>
        <Box p={"16px"}>
          {stateListRQUpgrade &&
            stateListRQUpgrade.map((item, index) => (
              <>
                <Box className="row">
                  <Box className="col-md-9">
                    {" "}
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                    >
                      <Typography fontWeight={"600"} variant="subtitle1">
                        Mã gói nâng cấp : {item?.code_upgrade}
                      </Typography>
                      <Typography variant="subtitle2">
                        Thời điểm nâng cấp :{" "}
                        {CaseDatetimeToYYYYMMDDHHMMSS(item?.created_at)}
                      </Typography>
                      <Typography variant="subtitle2">
                        Tên gói : {item?.package_info?.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        Thời hạn : {item?.package_info?.duration_in_months}{" "}
                        Tháng
                      </Typography>

                      <Typography variant="subtitle2" display={"flex"}>
                        Trạng thái :
                        {item?.status ===
                        parseInt(
                          process.env.REACT_APP_STATUS_UPGRADE_PENDING
                        ) ? (
                          <Typography ml={1} color={"orangered"}>
                            Đang chờ duyệt
                          </Typography>
                        ) : item?.status ===
                          parseInt(
                            process.env.REACT_APP_STATUS_UPGRADE_ACCEPT
                          ) ? (
                          <Typography ml={1} color={"green"}>
                            Đang dùng
                          </Typography>
                        ) : item?.status ===
                          parseInt(
                            process.env.REACT_APP_STATUS_UPGRADE_EXPIRED
                          ) ? (
                          <Typography ml={1} color={"red"}>
                            Hết hạn
                          </Typography>
                        ) : (
                          <Typography ml={1} color={"GrayText"}>
                            Ngừng hoạt động
                          </Typography>
                        )}
                      </Typography>

                      <Typography variant="subtitle2" display={"flex"}>
                        Tình trạng :
                        {item?.in_use ? (
                          <Typography ml={1} color={"green"}>
                            Đang sử dụng
                          </Typography>
                        ) : (
                          <Typography ml={1} color={"orangered"}>
                            Chưa dùng
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="col-md-3">
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleOpen();
                        const filterData = stateListRQUpgrade.filter(
                          (obj, index) => {
                            return obj._id === item?._id;
                          }
                        );
                        setStateModal(filterData[0]);
                      }}
                      disabled={item?.is_sent_payment}
                    >
                      Thanh toán
                    </Button>
                  </Box>
                </Box>

                <hr></hr>
              </>
            ))}
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...styleModal,
              width: "400px",
              height: "auto",
              maxHeight: "85vh",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <CloseModal handleclose={handleClose}></CloseModal>

            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight={600}
              component="h2"
            >
              Mã gói nâng cấp : {stateModal?.code_upgrade}
            </Typography>
            <Box className="row">
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={1}
                className="col-xs-12 col-md-12"
              >
                {" "}
                <Typography>**Thông tin chuyển khoản</Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={2}
                  bgcolor={"#eeeeee"}
                  borderRadius={"10px"}
                >
                  <Typography>Số tài khoản: 123</Typography>
                  <Button>
                    <ContentCopyIcon></ContentCopyIcon>
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={2}
                  bgcolor={"#eeeeee"}
                  borderRadius={"10px"}
                >
                  <Typography>Chủ tài khoản: 123</Typography>
                  <Button>
                    <ContentCopyIcon></ContentCopyIcon>
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={2}
                  bgcolor={"#eeeeee"}
                  borderRadius={"10px"}
                >
                  <Typography>
                    Nội dung: Nâng cấp {stateModal?.code_upgrade}
                  </Typography>
                  <Button>
                    <ContentCopyIcon></ContentCopyIcon>
                  </Button>
                </Box>
                <Box mt={2}>
                  <label
                    htmlFor="file"
                    style={{
                      border: "1px solid #ccc",
                      display: "inline-block",
                      padding: "6px 12px",
                      cursor: "pointer",
                      color: "black",
                      borderRadius: "4px",
                    }}
                    className="custom-file-upload"
                  >
                    Chọn hoá đơn
                  </label>
                  <Input
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    name="images"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Box>
                {imageFile ? (
                  <img
                    style={{
                      marginRight: "5px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      objectFit: "cover",
                      border: "solid 1px",
                      height: "200px",
                    }}
                    src={stateImg}
                    width={"100%"}
                  ></img>
                ) : (
                  <Typography pl={2} variant="subtitle2" color={"GrayText"}>
                    **Vui lòng chọn hoá đơn
                  </Typography>
                )}
                {Loading ? (
                  <Button
                    sx={{ marginTop: "10px" }}
                    fullWidth
                    variant="outlined"
                  >
                    Đang tiến hành giao dịch....
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSend(stateModal?._id);
                    }}
                  >
                    Xác nhận thanh toán
                  </Button>
                )}
                <Typography
                  color={"#0628C7"}
                  bgcolor={"#E6E9FF"}
                  p={1}
                  fontSize={"14px"}
                >
                  Thanh toán đúng nội dung chuyển khoản để được kích hoạt tài
                  khoản tự động. Trường hợp chuyển khoản sai nội dung, vui lòng
                  gọi đến số xxxxxxx để được hổ trợ chi tiết.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default ListUpgrade;
