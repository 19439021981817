import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import styled from "styled-components";

const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(1);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
      >
        <StyledOption value={""}>Lấy tất cả khách hàng</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_CUSTOMER_NOTLINK)}
        >
          Chưa liên kết
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_CUSTOMER_LINK)}
        >
          Đã tham gia MALL
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_CUSTOMER_ACTIVE)}
        >
          Đã liên kết
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(1);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "70vh",
  overflow: "scroll",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 2,
  pt: 2,
  px: 4,
  pb: 3,
};
