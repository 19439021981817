import BarChart from "./Barchart";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import * as React from "react";

import { styled } from "@mui/material/styles";

import { useParams } from "react-router-dom";
import { Create_Customer } from "../../services/handleCustomer/handleCustomer";
import { useMutation } from "@tanstack/react-query";
import LinkIcon from "@mui/icons-material/Link";
import SearchIcon from "@mui/icons-material/Search";

import { useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import IMGEMPTYKH from "../../assets/image/emptyKH.jpg";
import Modal from "@mui/material/Modal";
import {
  GET_ALL_CUSTOMER_BY_ID,
  GET_ALL_CUSTOMER,
} from "../../services/handleCustomer/handleCustomer";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Back from "../../component/Back";
import {
  SelectOldNewOrder,
  UnstyledSelectIntroduction,
  style,
} from "./component/components";
import { CloseModal } from "../../component/function";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";

const Customer = () => {
  const [open, setOpen] = useState(false);
  const [tinh, setTinh] = useState([]);
  const [quan, setQuan] = useState([]);
  const [phuong, setPhuong] = useState([]);
  const [selectedTinh, setSelectedTinh] = useState("");
  const [selectedQuan, setSelectedQuan] = useState("");
  const [selectedPhuong, setSelectedPhuong] = useState("");
  const [errors, seterrors] = useState({});
  const [Isloading, setIsLoaing] = useState(false);
  const [formCreateCustomer, setFromCreateCustomer] = useState({
    name: "",
    phone: "",
    address: "",
  });
  const [stateCustomer, setStateCustomer] = useState({});
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateSort, setStateSort] = useState("asc");
  const [stateDate, setStateDate] = useState("latest");
  const [stateStatus, setStateStatus] = useState("");
  const [stateCount, setStateCount] = useState(0);
  const [stateName, setStateName] = useState("");
  let nav = useNavigate();
  const Initall = () => {
    setFromCreateCustomer({
      name: "",
      phone: "",
      address: "",
    });
    setTinh([]);
    setQuan([]);
    setPhuong([]);
    setSelectedTinh("");
    setSelectedQuan("");
    setSelectedPhuong("");
    seterrors({});
  };
  const useMutation_CreateCustomer = useMutation({
    mutationFn: (body) => {
      return Create_Customer(body);
    },
  });
  const getAll_Customer = useQuery({
    queryKey: [
      "get_all_customer",
      statePage,
      stateDate,
      stateStatus,
      stateName,
    ],
    queryFn: async () => {
      const result = await GET_ALL_CUSTOMER({
        page: statePage,
        limit: stateLimit,
        sort: stateSort,
        date: stateDate,
        status: stateStatus,
        name: stateName,
      });
      if (result?.data?.data) {
        setStateCustomer(result?.data?.data);
        setStateCount(result?.data?.data?.total_page);
      }
      return result; // Ensure the result is returned
    },
  });
  const handleChange = (event, value) => {
    setStatePage(value);
  };
  const onChange = (e) => {
    seterrors({});
    setFromCreateCustomer({
      ...formCreateCustomer,
      [e.target.name]: e.target.value,
    });
  };
  const TriggerFetchTinh = async () => {
    fetch("https://esgoo.net/api-tinhthanh/1/0.htm")
      .then((response) => response.json())
      .then((data_tinh) => {
        if (data_tinh.error === 0) {
          setTinh(data_tinh.data);
        }
      });
  };
  React.useEffect(() => {
    // Lấy danh sách tỉnh thành
    fetch("https://esgoo.net/api-tinhthanh/1/0.htm")
      .then((response) => response.json())
      .then((data_tinh) => {
        if (data_tinh.error === 0) {
          setTinh(data_tinh.data);
        }
      });
  }, []);

  React.useEffect(() => {
    if (selectedTinh) {
      // Lấy danh sách quận huyện
      fetch(`https://esgoo.net/api-tinhthanh/2/${selectedTinh}.htm`)
        .then((response) => response.json())
        .then((data_quan) => {
          if (data_quan.error === 0) {
            setQuan(data_quan.data);
            setPhuong([]); // Reset phường/xã khi thay đổi quận/huyện
          }
        });
    }
  }, [selectedTinh]);

  React.useEffect(() => {
    if (selectedQuan) {
      // Lấy danh sách phường xã
      fetch(`https://esgoo.net/api-tinhthanh/3/${selectedQuan}.htm`)
        .then((response) => response.json())
        .then((data_phuong) => {
          if (data_phuong.error === 0) {
            setPhuong(data_phuong.data);
          }
        });
    }
  }, [selectedQuan]);
  const validate = () => {
    const newErrors = {};

    if (!formCreateCustomer.name) {
      newErrors.name = "Tên không được bỏ trống";
    }

    if (!formCreateCustomer.phone) {
      newErrors.phone = "Số điện thoại không được bỏ trống";
    }
    if (!formCreateCustomer.address) {
      newErrors.address = "Địa chỉ không được bỏ trống";
    }

    seterrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateCustomer = () => {
    setIsLoaing(true);
    if (validate()) {
      let objectTinh = tinh.filter((item, index) => {
        return item?.id === selectedTinh;
      });
      let objectQuan = quan.filter((item, index) => {
        return item?.id === selectedQuan;
      });
      let objectPhuong = phuong.filter((item, index) => {
        return item?.id === selectedPhuong;
      });
      let TINH = objectTinh[0]?.full_name || "";
      let QUAN = objectQuan[0]?.full_name || "";
      let PHUONG = objectPhuong[0]?.full_name || "";
      let FormCreate = {
        name: formCreateCustomer.name,
        address:
          TINH + " " + QUAN + " " + PHUONG + " " + formCreateCustomer.address,
        phone: formCreateCustomer.phone,
      };
      useMutation_CreateCustomer.mutate(FormCreate, {
        onSuccess: (data) => {
          if (data?.errors) {
            const newErrors = {};
            newErrors.phone = data?.errors?.phone?.msg;

            seterrors(newErrors);
            setIsLoaing(false);
          } else {
            alert("Khởi tạo khách hàng thành công");
            Initall();
            setOpen(false);
            setIsLoaing(false);
            getAll_Customer.refetch();
            TriggerFetchTinh();
          }
        },
      });
      return;
    } else {
      setIsLoaing(false);
    }
  };
  return (
    <>
      <Box className="PaddingLRForCustomer">
        <Back content={"Quản lý khách hàng"}></Back>
        <Box
          width={"100%"}
          p={2}
          height={"auto"}
          sx={{ backgroundColor: "#dfe3ff" }}
        >
          <Box
            justifyContent={"space-between"}
            alignItems={"center"}
            display={"flex"}
          >
            <Box className="col-4">
              {" "}
              <Typography color={"#393E40"} fontSize={"18px"} fontWeight={600}>
                Khách hàng
              </Typography>
            </Box>
            <Box className="col-8">
              <TextField
                variant="outlined"
                placeholder="Tìm kiếm khách hàng.."
                value={stateName}
                onChange={(e) => {
                  setStateName(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ height: "40px" }} position="start">
                      <SearchIcon
                        sx={{
                          "&:hover": {
                            color: "white",
                            transition: "transform 0.3s, color 0.3s",
                            cursor: "pointer",
                          },
                        }}
                        fontSize="large"
                        color="action"
                      ></SearchIcon>
                    </InputAdornment>
                  ),
                  style: {
                    height: "40px",
                  },
                }}
                fullWidth
                sx={{
                  backgroundColor: "#EFEFEF",
                  borderRadius: "20px",
                  height: "40px",
                  cursor: "pointer",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none", // Xóa viền ngoài
                    },
                    "&:hover fieldset": {
                      border: "none", // Xóa viền khi hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "none", // Xóa viền khi có focus
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px",
                    height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
                  },
                  "& .MuiInputBase-input": {
                    padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
                  },
                }}
              />
            </Box>
          </Box>
          <Box gap={3} p={2} className="row">
            <Box sx={{ flexGrow: 1, flexBasis: 200 }}>
              <Box p={1} display={"flex"} borderRadius={20} bgcolor={"white"}>
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Chưa liên kết {" : "} {stateCustomer?.customer_not_linked}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, flexBasis: 200 }}>
              <Box p={1} display={"flex"} borderRadius={20} bgcolor={"white"}>
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Đã tham gia MALL {" : "}
                  {stateCustomer?.customer_linked}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, flexBasis: 200 }}>
              <Box p={1} display={"flex"} borderRadius={20} bgcolor={"white"}>
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Đã liên kết {" : "}
                  {stateCustomer?.customer_active}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1, flexBasis: 200 }}>
              <Box p={1} display={"flex"} borderRadius={20} bgcolor={"white"}>
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Khách hàng {" : "}
                  {stateCustomer?.total_customers}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width={"100%"}>
          <Stack
            direction="row"
            borderBottom={"solid 3px gainsboro"}
            // divider={<Divider orientation="vertical" flexItem />}
            justifyContent={"space-between"}
          >
            <Box
              p={2}
              flex={0.5}
              textAlign={"center"}
              borderRight={"solid 1px #f7f7f7"}
            >
              <SelectOldNewOrder
                setStatePage={setStatePage}
                setStateSortDate={setStateSort}
                stateSortDate={setStateDate}
              ></SelectOldNewOrder>
            </Box>
            <Box
              p={2}
              flex={1}
              textAlign={"center"}
              borderRight={"solid 1px #f7f7f7"}
            >
              {" "}
              <Typography fontWeight={600}>
                <UnstyledSelectIntroduction
                  stateStatus={stateStatus}
                  setStatePage={setStatePage}
                  setStateStatus={setStateStatus}
                ></UnstyledSelectIntroduction>
              </Typography>
            </Box>
          </Stack>
        </Box>
        {getAll_Customer.isLoading ? (
          <Typography p={3}>Đang tải dữ liệu....</Typography>
        ) : (
          <>
            {stateCustomer ? (
              stateCustomer &&
              Array.isArray(stateCustomer?.customers) &&
              stateCustomer?.customers.map((item, index) => (
                <Box style={{ backgroundColor: "white" }}>
                  <Box
                    display={"flex"}
                    padding={"12px 12px 0px 12px"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    borderBottom={"solid 1px gainsboro"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      nav(`/cDetails/${item?._id}`);
                    }}
                  >
                    <ul style={{ padding: "0px", width: "80%" }}>
                      {item?.status ===
                      parseInt(
                        process.env.REACT_APP_STATUS_CUSTOMER_NOTLINK
                      ) ? (
                        <li>
                          <Typography
                            fontWeight={700}
                            color={"#607CFB"}
                            fontSize={".7rem"}
                          >
                            <LinkIcon color={"#607CFB"} fontSize="small">
                              {" "}
                            </LinkIcon>{" "}
                            CHƯA LIÊN KẾT
                          </Typography>
                        </li>
                      ) : item?.status ===
                        parseInt(process.env.REACT_APP_STATUS_CUSTOMER_LINK) ? (
                        <Typography
                          fontWeight={700}
                          color={"orangered"}
                          fontSize={".7rem"}
                          // bgcolor={"orangered"}
                        >
                          <LinkIcon color={"orangered"} fontSize="small">
                            {" "}
                          </LinkIcon>{" "}
                          ĐÃ THAM GIA MALL
                        </Typography>
                      ) : (
                        <Typography
                          fontWeight={700}
                          color={"blueviolet"}
                          fontSize={".7rem"}
                        >
                          <LinkIcon color={"blueviolet"} fontSize="small">
                            {" "}
                          </LinkIcon>{" "}
                          ĐÃ ĐƯỢC LIÊN KẾT
                        </Typography>
                      )}

                      <li style={{ paddingBottom: "2%" }}>
                        <Typography variant="subtitle1" fontWeight={700}>
                          {item?.name}
                        </Typography>
                      </li>
                      <li>
                        <Typography> {item?.phone}</Typography>
                      </li>
                      <li>
                        <Typography color={"#607CFB"}>
                          {" "}
                          {item?.address}
                        </Typography>
                      </li>
                    </ul>
                    <Box
                      display={"flex"}
                      justifyContent={"space-around"}
                      alignItems={"center"}
                    >
                      <Button>
                        <ModeEditTwoToneIcon fontSize="small"></ModeEditTwoToneIcon>
                      </Button>

                      {/* <Button>
                    <ShoppingCartIcon fontSize="small"></ShoppingCartIcon>
                  </Button> */}
                    </Box>
                  </Box>
                  {item?.status !==
                  parseInt(process.env.REACT_APP_STATUS_CUSTOMER_ACTIVE) ? (
                    <>
                      {" "}
                      <Box borderBottom={"solid 5px #eeedee"} p={"12px"}>
                        <Typography fontSize={".7rem"}>
                          Chưa phát sinh đơn nào
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              ))
            ) : (
              <img src={IMGEMPTYKH} p={3}></img>
            )}
          </>
        )}

        <Box></Box>

        <Box p={4}>
          <Stack spacing={2}>
            <Typography>Trang: {statePage}</Typography>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={stateCount}
              page={statePage}
              onChange={handleChange}
            />
          </Stack>
        </Box>
        <Box
          // bgcolor={"white"}
          position={"fixed"}
          bottom={"50px"}
          left={"0%"}
          height={"100px"}
          width={"100%"}
          display={"flex"}
        >
          <Box
            width={"48px"}
            height={"48px"}
            bgcolor={"blue"}
            borderRadius={"100%"}
            display={"flex"}
            textAlign={"center"}
            justifyContent={"center"}
            margin={"auto"}
            boxShadow={5}
            sx={{ cursor: "pointer" }}
            onClick={handleOpen}
          >
            <Typography fontSize={"2rem"} alignSelf={"center"} color={"white"}>
              +
            </Typography>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <Box>
              <CloseModal handleclose={handleClose}></CloseModal>
              <Typography mb={3} variant="h5" fontWeight={700}>
                Điền thông tin khách hàng
              </Typography>
              <div class="form-group">
                <TextField
                  fullWidth
                  error={errors.phone ? true : false}
                  sx={{ marginBottom: "2rem" }}
                  id="standard-basic"
                  name="phone"
                  value={formCreateCustomer.phone}
                  onChange={onChange}
                  type="number"
                  label="Số điện thoại khách hàng"
                  variant="standard"
                  helperText={errors.phone}
                />
                <TextField
                  fullWidth
                  error={errors.name ? true : false}
                  sx={{ marginBottom: "2rem" }}
                  id="standard-basic"
                  name="name"
                  value={formCreateCustomer.name}
                  onChange={onChange}
                  label="Họ tên khách hàng"
                  variant="standard"
                  helperText={errors.name}
                />
                <FormControl fullWidth sx={{ marginBottom: "2rem" }}>
                  <InputLabel id="lableTinh">Tỉnh</InputLabel>
                  <Select
                    labelId="lableTinh"
                    label="Tỉnh"
                    id="tinh"
                    name="tinh"
                    value={selectedTinh}
                    onChange={(e) => setSelectedTinh(e.target.value)}
                  >
                    {tinh &&
                      tinh.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {" "}
                          {item?.full_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "2rem" }}>
                  <InputLabel id="lableQuan">Quận</InputLabel>
                  <Select
                    labelId="lableQuan"
                    label="Quận"
                    id="quan"
                    name="quan"
                    value={selectedQuan}
                    disabled={!selectedTinh}
                    onChange={(e) => setSelectedQuan(e.target.value)}
                  >
                    {quan &&
                      quan.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {" "}
                          {item?.full_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "2rem" }}>
                  <InputLabel id="lablePhuong">Phường</InputLabel>
                  <Select
                    labelId="lablePhuong"
                    label="Phường"
                    id="phuong"
                    name="phuong"
                    title="Chọn Phường Xã"
                    value={selectedPhuong}
                    disabled={!selectedQuan}
                    onChange={(e) => setSelectedPhuong(e.target.value)}
                  >
                    {phuong &&
                      phuong.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {" "}
                          {item?.full_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{ marginBottom: "2rem" }}
                  error={errors.address ? true : false}
                  id="standard-basic"
                  label="Số nhà tên đường"
                  variant="standard"
                  onChange={onChange}
                  name="address"
                  value={formCreateCustomer.address}
                  helperText={errors.address}
                />
              </div>
              <Box width={"100%"}>
                {Isloading ? (
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                    class="btn btn-primary"
                  >
                    Đang khởi tạo....
                  </button>
                ) : (
                  <button
                    style={{ width: "100%" }}
                    class="btn btn-primary"
                    onClick={handleCreateCustomer}
                  >
                    Khởi tạo
                  </button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default Customer;
