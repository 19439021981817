import {
  Avatar,
  Box,
  Button,
  Input,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  CHANGE_AVATAR,
  Get_statistics_Infor,
} from "../../services/handleInfor/handleInfor";
import ImgCamera from "../../assets/logo/avatar.png";
import AvatarImg from "../../assets/logo/tài khoản.png";
import IMG from "../../assets/image/referral.png";
import IMGKH from "../../assets/logo/khách hàng (2).png";
import IMGLN from "../../assets/logo/lợi nhuận.png";
import IMGQLDH from "../../assets/logo/quản lí đơn hàng.png";
import IMGQLGH from "../../assets/logo/giỏ hàng.png";
import IMGTHONGKEKHACHHANG from "../../assets/logo/thống kê khách hàng 1.png";
import IMGTHONGKEDONHANG from "../../assets/logo/thống kê đơn hàng 1.png";
import IMGTHONGKESELLER from "../../assets/logo/saler 1.png";
import IMGTHONGTINTHANHTOAN from "../../assets/logo/thông tin thanh toán.png";
import IMGTHONGTINTAIKHOAN from "../../assets/logo/thông tin tài khoản.png";
import IMGVERIFY from "../../assets/logo/Logovrf.png";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { CHECK_UPGRADE } from "../../services/handleInfor/handleInfor";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CloseModal } from "../../component/function";
import { styleModal } from "./components/componets";
import "./style.css";
import Back from "../../component/Back";
import NavbarSecond from "../../component/NavBarSecond/navbarSecond";
const Infor = ({
  stateData,
  stateAccount,
  stateCart,
  handleTrggerRefreshAccount,
}) => {
  const [stateImg, setStateImg] = useState(stateAccount?.avatar || "");
  const [imageFile, setImageFile] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [stateStatistic, setStateStatistic] = useState({});
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  let nav = useNavigate();
  const mutation_update_avatar = useMutation({
    mutationFn: (body) => {
      return CHANGE_AVATAR(body);
    },
  });
  const DATA_GET_STATISTIC = useQuery({
    queryKey: ["GET_DATA_STATISTIC_INFO"],
    queryFn: async () => {
      const rs = await Get_statistics_Infor();
      if (rs?.data?.data) {
        setStateStatistic(rs?.data?.data);
      }
      return rs;
    },
  });
  const handleChangeAvatar = () => {
    setLoading(true);
    if (imageFile) {
      const dataForm = new FormData();

      dataForm.append("image", imageFile);

      mutation_update_avatar.mutate(dataForm, {
        onSuccess: (data) => {
          if (data?.data) {
            alert("Thay đổi ảnh thành công ");
            setLoading(false);
            setImageFile(null);
            handleTrggerRefreshAccount();
          } else {
            alert("Vui lòng thử lại !!");
            setLoading(false);
          }
        },
        onError: (err) => {
          console.log("err : ", err);
          alert("Vui lòng thử lại !!");
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStateImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleClick = (newState) => () => {
  //   var currentURL = window.location.origin;
  //   let getlink = currentURL + `/#/registry?ref=${localStorage.getItem("id")}`;
  //   if (!localStorage.getItem("id")) {
  //     alert("Đã có lỗi xảy ra, vui lòng đăng xuất vào lại");
  //   } else {
  //     navigator.clipboard
  //       .writeText(getlink)
  //       .then(() => {
  //         setState({ ...newState, open: true });
  //         console.log(`Base URL copied to clipboard: ${getlink}`);
  //       })
  //       .catch((err) => {
  //         console.error("Failed to copy: ", err);
  //       });
  //   }
  // };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const TextCheck = () => {
    return (
      <span>
        <CheckIcon sx={{ marginRight: "10px" }}></CheckIcon> Đã lấy link giới
        thiệu
      </span>
    );
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Box
          position={"relative"}
          sx={{
            background: "linear-gradient(to top, #00f, #0005a3)",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          }}
          width={"100%"}
          height={"20vh"}
        >
          <NavbarSecond
            stateAccount={stateAccount}
            stateCart={stateCart}
            IsArrow={true}
            IsSearch={false}
            IsSticky={false}
            IsBackgroundTransparent={true}
            IsWhiteIcon={true}
          ></NavbarSecond>
          <Box
            sx={{
              position: "absolute",
              left: "5%",
              bottom: "-30%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              position={"relative"}
              width={"100px"}
              sx={{ cursor: "pointer" }}
              height={"100px"}
              onClick={handleOpenModal}
            >
              <Avatar
                sx={{
                  width: 96,
                  height: 96,
                  backgroundColor: "white",
                  border: "solid 2px white",
                }}
                alt="Remy Sharp"
                src={stateAccount?.avatar || AvatarImg}
              />
              <img
                src={ImgCamera}
                width="40px"
                height="20px"
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  padding: "2px 5px",
                  borderRadius: "25px",
                }}
              ></img>
            </Box>
            <Typography fontSize={"18px"} fontWeight={"700"}>
              {stateAccount?.name || ""}
            </Typography>
          </Box>
        </Box>
        <Box width={"100%"} height={"72px"} bgcolor={"ghostwhite"}></Box>
        <Box
          width={"100%"}
          height={"72px"}
          display={"flex"}
          bgcolor={"rgb(255 240 217/1)"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"80%"}
            margin={"auto"}
          >
            {stateData?.days_remaining ? (
              <Typography color={"#CC7600"} fontSize={"14px"} fontWeight={500}>
                Hạn sử dụng còn {stateData?.days_remaining} ngày
              </Typography>
            ) : (
              <Typography color={"#CC7600"} fontSize={"12px"} fontWeight={500}>
                Nâng cấp tài khoản để nhận nhiều quyền lợi hơn
              </Typography>
            )}
            {stateData?.days_remaining ? (
              <Box>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#F79009",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    nav("/infor/upgrade");
                  }}
                >
                  {"Gia hạn"}
                </button>
              </Box>
            ) : (
              <Box>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#F79009",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    nav("/infor/upgrade");
                  }}
                >
                  Nâng cấp
                </button>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="row">
          <Box
            className="col-6"
            sx={{
              height: "80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRight: ".2px solid #a8adaf",
            }}
          >
            <Avatar
              src={IMGKH}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/customer");
              }}
            ></Avatar>
            <Typography
              sx={{ color: "#393E40", fontSize: "14px", fontWeight: "600" }}
            >
              Khách hàng
            </Typography>
          </Box>

          <Box
            className="col-6"
            sx={{
              height: "80px",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              src={IMGLN}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/Revenue");
              }}
            ></Avatar>
            <Typography
              sx={{ color: "#393E40", fontSize: "14px", fontWeight: "600" }}
            >
              Doanh thu
            </Typography>
          </Box>
        </Box>
        {/* Thống kê */}
        <Box sx={{ borderWidth: "1px" }} className="row">
          <Box
            className="col-4"
            sx={{
              height: "80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRight: ".2px solid #a8adaf",
            }}
          >
            <Avatar src={IMGTHONGKEKHACHHANG}></Avatar>
            <Typography
              sx={{ color: "#393E40", fontSize: "12px", fontWeight: "600" }}
            >
              Khách hàng : {stateStatistic?.count_customer}
            </Typography>
          </Box>

          <Box
            className="col-4"
            sx={{
              height: "80px",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRight: ".2px solid #a8adaf",
            }}
          >
            <Avatar src={IMGTHONGKESELLER}></Avatar>
            <Typography
              sx={{ color: "#393E40", fontSize: "12px", fontWeight: "600" }}
            >
              Người tham gia : {stateStatistic?.count_my_seller}
            </Typography>
          </Box>
          <Box
            className="col-4"
            sx={{
              height: "80px",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar src={IMGTHONGKEDONHANG}></Avatar>
            <Typography
              sx={{ color: "#393E40", fontSize: "12px", fontWeight: "600" }}
            >
              Đơn hàng: {stateStatistic?.count_purchase}
            </Typography>
          </Box>
        </Box>
        <Box
          className="row"
          sx={{
            background:
              "linear-gradient(105.12deg, rgb(234, 245, 255) 10.29%, rgb(242, 249, 255) 88.66%)",
            height: "152px",
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            className="col-6"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#393E40",
                fontSize: "16px",
                marginBottom: "4px",
                fontWeight: "600",
              }}
            >
              Giới thiệu Tikpii
            </Typography>
            <Typography
              sx={{
                color: "#747B7E",
                marginBottom: "8px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              Lan tỏa Kinh doanh cùng Tikpii để nhiều người biết hơn bạn nhé
            </Typography>

            {stateData?.benefits ? (
              stateData?.benefits.includes(
                process.env.REACT_APP_AFFILIATE_INVITE_FUNC
              ) ? (
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: "13px",
                    color: "#1B3FE4",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    nav("/infor/ref-link");
                  }}
                >
                  Lấy link ngay{" "}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: "13px",
                    color: "#1B3FE4",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    alert("Tài khoản này không được phép dùng tính năng này !");
                  }}
                >
                  Lấy link ngay{" "}
                </Button>
              )
            ) : (
              <Button
                variant="outlined"
                sx={{ fontSize: "13px", color: "#1B3FE4", textAlign: "center" }}
                onClick={() => {
                  alert("Tài khoản này không được phép dùng tính năng này !");
                }}
              >
                Lấy link ngay{" "}
              </Button>
            )}

            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              message={<TextCheck></TextCheck>}
              key={vertical + horizontal}
            />
          </Box>
          <Box
            className="col-4"
            sx={{ color: "#393E40", fontSize: "14px", fontWeight: "600" }}
          >
            <img width={100} height={"auto"} src={IMG}></img>
          </Box>
        </Box>
        <ul
          style={{
            padding: "0px",
            borderTop: "solid 8px #eeedee",
            borderBottom: "solid 8px #eeedee",
          }}
        >
          <li className="lihover">
            <Box
              className="row"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/list-order");
              }}
              p={3}
              alignItems={"center"}
            >
              <Box display={"flex"} className="col-2">
                <img
                  style={{
                    margin: "auto",
                    color: "blue",
                    filter:
                      "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)",
                  }}
                  src={IMGQLDH}
                  width={20}
                  height={20}
                ></img>
              </Box>
              <Box className="col-8">
                <Typography>Quản lý đơn hàng</Typography>
              </Box>
              <Box className="col-2">
                <KeyboardArrowRightRoundedIcon></KeyboardArrowRightRoundedIcon>
              </Box>
            </Box>
          </li>

          <li className="lihover">
            <Box
              className="row"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/cart");
              }}
              p={3}
              alignItems={"center"}
            >
              <Box display={"flex"} className="col-2">
                <img
                  style={{
                    margin: "auto",
                    color: "blue",
                    filter:
                      "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)",
                  }}
                  src={IMGQLGH}
                  width={20}
                  height={20}
                ></img>
              </Box>
              <Box className="col-8">
                <Typography>Quản lý giỏ hàng</Typography>
              </Box>
              <Box className="col-2">
                <KeyboardArrowRightRoundedIcon></KeyboardArrowRightRoundedIcon>
              </Box>
            </Box>
          </li>

          <li className="lihover">
            <Box
              className="row"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/infor/view");
              }}
              p={3}
              alignItems={"center"}
            >
              <Box display={"flex"} className="col-2">
                <img
                  style={{
                    margin: "auto",
                    color: "blue",
                    filter:
                      "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)",
                  }}
                  src={AvatarImg}
                  width={20}
                  height={20}
                ></img>
              </Box>
              <Box className="col-8">
                <Typography>Thông tin cá nhân</Typography>
              </Box>
              <Box className="col-2">
                <KeyboardArrowRightRoundedIcon></KeyboardArrowRightRoundedIcon>
              </Box>
            </Box>
          </li>

          <li className="lihover">
            <Box
              className="row"
              sx={{
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/infor/bank");
              }}
              p={3}
              alignItems={"center"}
            >
              <Box display={"flex"} className="col-2">
                <img
                  style={{
                    margin: "auto",
                    color: "blue",
                    filter:
                      "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)",
                  }}
                  src={IMGTHONGTINTHANHTOAN}
                  width={20}
                  height={20}
                ></img>
              </Box>
              <Box className="col-8">
                <Typography>Thông tin thanh toán</Typography>
              </Box>
              <Box className="col-2">
                <KeyboardArrowRightRoundedIcon></KeyboardArrowRightRoundedIcon>
              </Box>
              {stateAccount?.bank_info?.bank_name ? (
                ""
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    // backgroundColor: "rgb(207 96 0 / 50%)",
                    width: "50px",

                    padding: "10px",
                    borderRadius: "0 0 10px 0",
                  }}
                >
                  <ReportGmailerrorredIcon color="error"></ReportGmailerrorredIcon>
                </Box>
              )}
            </Box>
          </li>

          <li className="lihover">
            <Box
              className="row"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                nav("/infor/password");
              }}
              p={3}
              alignItems={"center"}
            >
              <Box display={"flex"} className="col-2">
                <img
                  style={{
                    margin: "auto",
                    color: "blue",
                    filter:
                      "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)",
                  }}
                  src={IMGTHONGTINTAIKHOAN}
                  width={20}
                  height={20}
                ></img>
              </Box>
              <Box className="col-8">
                <Typography>Thay đổi mật khẩu</Typography>
              </Box>
              <Box className="col-2">
                <KeyboardArrowRightRoundedIcon></KeyboardArrowRightRoundedIcon>
              </Box>
            </Box>
          </li>
        </ul>
        <Box
          mb={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
          alignItems={"center"}
        >
          <Avatar src={IMGVERIFY} sx={{ width: 100, height: 50 }}></Avatar>
          <Typography fontSize={"12px"} color={"#747B7E"}>
            CÔNG TY TNHH KIM - SONAKI
          </Typography>
          <Typography fontSize={"12px"} color={"#747B7E"}>
            Mã số doanh nghiệp: 0315956737
          </Typography>
          {/* <Typography fontSize={"12px"} color={"#747B7E"}>
            Số điện thoại: 1900 3126
          </Typography> */}
          <Typography
            width={"70%"}
            fontSize={"12px"}
            textAlign={"center"}
            color={"#747B7E"}
          >
            Địa chỉ: 58 đường 34B, khu đô thị An Phú An Khánh, TP.Thủ Đức
          </Typography>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...styleModal, width: "350px" }}>
            <CloseModal handleclose={handleCloseModal}></CloseModal>

            <Typography id="modal-modal-title" variant="h4" component="h2">
              Thay đổi ảnh đại diện
            </Typography>

            <Box className="row">
              <Box className="col-xs-12 col-md-12">
                {" "}
                <Box mt={2}>
                  <label
                    htmlFor="file"
                    style={{
                      border: "1px solid #ccc",
                      display: "inline-block",
                      padding: "6px 12px",
                      cursor: "pointer",
                      color: "black",
                      borderRadius: "4px",
                    }}
                    className="custom-file-upload"
                  >
                    Chọn hình ảnh
                  </label>
                  <Input
                    type="file"
                    sx={{ display: "none" }}
                    id="file"
                    name="images"
                    accept="image/*"
                    multiple
                    max={4}
                    onChange={handleFileChange}
                  />
                </Box>
                <img
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    borderRadius: "10px",
                    objectFit: "cover",
                    border: "solid 1px",
                    height: "200px",
                  }}
                  src={stateImg || stateAccount?.avatar}
                  width={"100%"}
                ></img>
              </Box>
            </Box>
            {Loading ? (
              <Button sx={{ marginTop: "10px" }} fullWidth variant="outlined">
                Đang tiến hành thay đổi ....
              </Button>
            ) : (
              <Button
                sx={{ marginTop: "10px" }}
                onClick={handleChangeAvatar}
                fullWidth
                variant="outlined"
              >
                Thay đổi
              </Button>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default Infor;
