import { useLocation, useParams } from "react-router-dom";
import Back from "../../component/Back";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { GET_PRODUCT_OF_PROVIDER } from "../../services/handleProduct/handleProduct";
import { useQuery } from "@tanstack/react-query";
import { PriceText } from "../../component/Styles/PriceText";
import { TitleProduct as TitleComponent } from "../../component/Styles/Title";
import { StyledRating } from "../Product/Components/Components";
import { useNavigate } from "react-router-dom";
import NavbarSecond from "../../component/NavBarSecond/navbarSecond";
const ProviderDetails = ({ stateAccount, stateCart, IsDaiLy }) => {
  const navigate = useNavigate();

  let nav = useNavigate("");
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [stateCount, setStateCount] = useState(0);

  const [stateProduct, setStateProduct] = useState([]);

  const handleGoBack = () => {
    navigate(-1); // Lùi về trang trước
  };
  const DataAllProduct = useQuery({
    queryKey: ["GetAllProduct", page],
    queryFn: async () => {
      const result = await GET_PRODUCT_OF_PROVIDER({
        id: id,
        page: page,
        limit: limit,
      });

      if (result?.data?.data) {
        setStateCount(result?.data?.data?.total_page);
        setStateProduct(result?.data?.data?.products);
      }
      return result; // Ensure the result is returned
    },
  });
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <NavbarSecond
        stateAccount={stateAccount}
        stateCart={stateCart}
        IsArrow={true}
        className="AddpadingForNavS"
      ></NavbarSecond>
      <Box
        className="AddpadingLeftProduct"
        sx={{
          width: "100%",
          height: "100%",
          paddingLeft: "5px",
          paddingRight: "5px",
          backgroundColor: "white",
        }}
      >
        <Box mt={2}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
            sx={{ cursor: "pointer" }}
          >
            <Avatar
              alt="Remy Sharp"
              src={state?.avatar}
              sx={{
                width: 70,
                height: 70,
                borderWidth: "1px",
              }}
            />
            <Typography textAlign={"center"} fontSize={"12px"} fontWeight={600}>
              {state?.name}
            </Typography>
          </Box>
          <Typography variant="h7" fontWeight={700}>
            Mục sản phẩm
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {DataAllProduct.isFetching ? (
              <>
                <Box mt={2} className="row">
                  <Box className="col-md-6" display={"grid"}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={"200px"}
                    />
                    <Box mt={2}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Box>
                  <Box className="col-md-6" display={"grid"}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={"200px"}
                    />
                    <Box mt={2}>
                      <Skeleton />
                      <Skeleton width="20%" />
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Grid display={"flex"} justifyContent={"space-between"} container>
                {stateProduct &&
                  stateProduct.map((item, index) => (
                    <Grid
                      mt={2}
                      sx={{
                        width: "50%",
                        padding: "10px",
                        borderColor: "#f3f3f3",
                        borderWidth: ".5px",
                      }}
                      display={"flex"}
                      key={index}
                    >
                      <Box
                        style={{
                          position: "relative",
                          margin: "auto",
                          marginTop: "10px",

                          cursor: "pointer",
                          height: "100%",
                          minWidth: "100%",
                          maxWidth: "100%%",
                        }}
                      >
                        {item?.sales ? (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              backgroundColor: "rgb(207 96 0 / 50%)",
                              fontWeight: 600,
                              color: "white",
                              padding: "10px",
                              borderRadius: "0 0 10px 0",
                            }}
                          >
                            Khuyến mãi {parseInt(item?.sales || 0)} %
                          </div>
                        ) : (
                          ""
                        )}
                        <CardMedia
                          component="img"
                          alt="green iguana"
                          sx={{ height: "140px" }}
                          image={item?.images[0]?.path || ""}
                          onClick={() => {
                            nav(`/pDetails/${item?._id}`);
                          }}
                        />
                        <CardContent sx={{ padding: "8px" }}>
                          <TitleComponent>{item?.name || ""}</TitleComponent>

                          <Typography mb={1} fontSize="12px" color="#747B7E">
                            Point:{" "}
                            {parseInt(item?.point).toLocaleString("en-us") || 0}
                          </Typography>

                          <Button
                            variant="body2"
                            sx={{
                              padding: "5px",
                              color: "#1B3FE4",
                              borderRadius: ".5rem",
                              fontSize: "12px",
                              fontWeight: "700",
                              backgroundColor: "rgb(230 233 255/1)",
                            }}
                            className="text-primary"
                          >
                            LN:{" "}
                            {IsDaiLy
                              ? parseInt(
                                  item?.profit_for_seller_and_collaborator || 0
                                ).toLocaleString("en-US")
                              : parseInt(
                                  item?.profit_for_collaborator || 0
                                ).toLocaleString("en-US")}
                            đ
                          </Button>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            {item?.sales ? (
                              <s style={{ fontSize: ".8rem" }}>
                                {parseInt(
                                  item?.price_retail || 0
                                ).toLocaleString("en-US")}{" "}
                                VND
                              </s>
                            ) : (
                              ""
                            )}

                            <Box display={"flex"}>
                              <PriceText
                                style={{
                                  fontSize: "14px",
                                  color: "#F79009",
                                  fontWeight: "700",
                                }}
                              >
                                đ
                                {item?.price_for_customer.toLocaleString(
                                  "en-US"
                                )}{" "}
                                đ
                              </PriceText>
                            </Box>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Rating
                              name="half-rating-read"
                              defaultValue={item?.rating}
                              precision={0.5}
                              readOnly
                              sx={{
                                fontSize: "15px",
                                marginBottom: "5px",
                              }}
                            />{" "}
                            <Typography ml={1} fontSize={".7rem"}>
                              {"("} {item?.total_rating} {")"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography fontSize={"12px"} color={"#5C6366"}>
                              Đã bán : {parseInt(item?.sold)}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Box>
        </Box>
        <Box p={4}>
          <Stack spacing={2}>
            <Typography>Trang: {page}</Typography>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={stateCount}
              page={page}
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default ProviderDetails;
