import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { styleModal } from "../../component/component";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Back from "../../component/Back";
import { useState } from "react";
import { CloseModal } from "../../component/function";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GradeIcon from "@mui/icons-material/Grade";
import { Create_send_payment_Order } from "../../services/handleInfor/handleInfor";
import { ADD_REVIEW_OF_PRODUCT } from "../../services/handleReviews/handleReviews";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Store } from "react-notifications-component";
import {
  PURCHASE_CANCEL,
  GET_ONE_PURCHASE,
} from "../../services/handleOrder/handleOrder";
import { ThongBaoFails, Input } from "../../component/component";
import { confirmAlert } from "react-confirm-alert";

const OrderDetails = () => {
  const { orderId } = useParams();

  const [stateImg, setStateImg] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [stateModal, setStateModal] = useState({});
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [stateComment, setStateComment] = useState("");
  const [valueRating, setValueRating] = useState(5);
  const [stateModalReview, setStateModalReview] = useState({});
  const [openReviews, setOpenReviews] = useState(false);
  const [state, setState] = useState({});
  const handleOpenReviews = () => setOpenReviews(true);
  const handleCloseReviews = () => setOpenReviews(false);

  let nav = useNavigate();
  const location = useLocation();

  const DATA_GET_ONE_PURCHASE = useQuery({
    queryKey: ["GET_ONE_PURCHASE"],
    queryFn: async () => {
      const rs = await GET_ONE_PURCHASE({
        id: orderId,
      });
      if (rs?.data?.data) {
        setState(rs?.data?.data);
      }
      return rs;
    },
  });
  const mutation_send_payment = useMutation({
    mutationFn: (body) => {
      return Create_send_payment_Order(body);
    },
  });
  const mutation_cancel_purchase = useMutation({
    mutationFn: (body) => {
      return PURCHASE_CANCEL(body);
    },
  });
  const handle_create_reviews = useMutation({
    mutationFn: (body) => {
      return ADD_REVIEW_OF_PRODUCT(body);
    },
  });
  const handleDelete = () => {
    confirmAlert({
      title: "Xác nhận xoá",
      message: "Bạn có chắc là huỷ đơn hàng này chứ?",
      buttons: [
        {
          label: "Có",
          onClick: () => {
            let FormDelete = {
              purchase_id: state?._id,
            };
            mutation_cancel_purchase.mutate(FormDelete, {
              onSuccess: (data) => {
                if (data?.data?.data) {
                  alert("Đã xoá huỷ đơn hàng này");
                  nav("/list-order");
                }
              },
            });
          },
        },
        {
          label: "Không",
        },
      ],
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStateImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCreateReview = () => {
    let Form = {
      purchase_id: state?._id,
      product_id: stateModalReview?.product_id,
      rating: valueRating,
      comment: stateComment,
    };
    setLoading(true);
    handle_create_reviews.mutate(Form, {
      onSuccess: (data) => {
        if (data?.errorInfo) {
          alert("Bạn đã đánh giá sản phẩm này rồi");
        } else {
          alert("Cảm ơn sự đóng góp của bạn");
        }
        setStateComment("");
        handleCloseReviews();
        setStateModalReview({});
        setValueRating(5);
        setLoading(false);
      },
      onError: (err) => {
        alert("Lỗi, Vui lòng thử lại !");
        setLoading(false);
      },
    });
  };
  const handleSend = (id) => {
    setLoading(true);
    if (imageFile) {
      const dataForm = new FormData();

      dataForm.append("image", imageFile);
      dataForm.append("purchase_id", id);

      mutation_send_payment.mutate(dataForm, {
        onSuccess: (data) => {
          if (data?.errorInfo) {
            Store.addNotification({
              ...ThongBaoFails({
                title: "Thông báo",
                message: "Bạn đã thanh toán đơn này rồi",
              }),
              container: "top-right",
            });
            setLoading(false);
          }
          if (data?.data) {
            alert("Thao tác thành công ");
            setLoading(false);
            setImageFile(null);
            setStateImg("");
            handleClose();
          }
        },
        onError: (err) => {
          console.log("err : ", err);
          alert("Vui lòng thử lại !!");
          setLoading(false);
        },
      });
    } else {
      alert("Vui lòng gửi hoá đơn giao dịch !!");
      setLoading(false);
    }
  };
  return (
    <>
      <Box className="PaddingLRForCustomer">
        <Back content={"Chi tiết đơn hàng"}></Back>
        <Box p={1}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/#/list-order">
              Đơn hàng
            </Link>
            <Typography color="text.primary">Chi tiết đơn hàng</Typography>
          </Breadcrumbs>
        </Box>
        {/* <Box
          onClick={() => {
            nav(`/share/orderDetails`, { state: state });
          }}
        >
          Chia sẽ đơn hàng
        </Box> */}
        {state && state?.code_purchase ? (
          <Box style={{ backgroundColor: "white" }}>
            <Box
              display={"flex"}
              padding={"12px 12px 0px 12px"}
              justifyContent={"space-between"}
              width={"100%"}
              borderBottom={"solid 1px gainsboro"}
              sx={{ cursor: "pointer" }}
            >
              <ul style={{ padding: "0px", width: "80%" }}>
                <li>
                  <Typography
                    fontWeight={700}
                    color={"#607CFB"}
                    fontSize={".7rem"}
                  >
                    <LinkIcon color={"#607CFB"} fontSize="small">
                      {" "}
                    </LinkIcon>{" "}
                    Mã đơn hàng : {state?.code_purchase}
                  </Typography>
                </li>

                <Typography variant="subtitle1" fontWeight={700}>
                  **Thông tin đơn hàng
                </Typography>
                <li style={{ paddingBottom: "2%" }}>
                  <Typography variant="subtitle2">
                    Phương thức thanh toán : {state?.payment_method?.name}
                  </Typography>
                </li>
                <li style={{ paddingBottom: "2%" }}>
                  <Typography variant="subtitle2">
                    Người chịu phí vận chuyển : {state?.cost_bearer?.name}
                  </Typography>
                </li>

                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle2">
                    - Phí vận chuyển :{" "}
                  </Typography>
                  <Typography
                    ml={1}
                    variant="subtitle1"
                    color={"#df4a4a"}
                    fontWeight={700}
                  >
                    {parseInt(state?.shipping_fee).toLocaleString("en-us")} đ
                  </Typography>
                </li>

                <Typography variant="subtitle1" fontWeight={700}>
                  **Thông tin khách hàng
                </Typography>
                <li>
                  <Typography variant="subtitle2">
                    Tên : {state?.customer?.name}
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle2">
                    Số điện thoại : {state?.customer?.phone}
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle2" color={"#607CFB"}>
                    Địa chỉ :{state?.customer?.address}
                  </Typography>
                </li>

                <Typography variant="subtitle1" fontWeight={700}>
                  **Thống kê số tiền
                </Typography>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle2">
                    Tổng giá trị đơn hàng :{" "}
                  </Typography>
                  <Typography
                    ml={1}
                    variant="subtitle1"
                    color={"green"}
                    fontWeight={700}
                  >
                    {parseInt(state?.purchase_total_price).toLocaleString(
                      "en-us"
                    )}
                    đ
                  </Typography>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle2">
                    Lợi nhuận thu về :{" "}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color={"green"}
                    ml={1}
                    fontWeight={700}
                  >
                    {parseInt(
                      state?.purchase_total_profit_final_for_seller || 0
                    ).toLocaleString("en-US")}
                    đ
                  </Typography>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle2">Point : </Typography>
                  <Typography
                    ml={1}
                    variant="subtitle1"
                    color={"green"}
                    fontWeight={700}
                  >
                    {parseInt(state?.purchase_total_point).toLocaleString(
                      "en-us"
                    )}{" "}
                  </Typography>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "2%",
                    gap: "10px",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight={"600"}>
                    {state?.payment_method?._id ===
                    process.env.REACT_APP_PAYMENT_METHOD_CK ? (
                      <>
                        <Button
                          onClick={() => {
                            handleOpen();
                            setStateModal({
                              ...state,
                              nd: "Thanh toán chuyển khoản",
                            });
                          }}
                          variant="contained"
                        >
                          xác nhận thanh toán chuyển khoản
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </li>
                <Typography variant="subtitle1" fontWeight={700}>
                  **Thông tin sản phẩm (
                  {state?.purchase_items ? state?.purchase_items.length : 0})
                </Typography>

                {state?.purchase_items &&
                  state?.purchase_items.map((item, index) => (
                    <>
                      <div className="row">
                        <div className="col-9">
                          {" "}
                          <li>
                            <Typography fontWeight={700} variant="subtitle2">
                              Tên sản phẩm : {item?.product_name}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Số lượng mua : {item?.buy_count}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Giá gốc :{" "}
                              {(
                                parseInt(item?.total_price) /
                                parseInt(item?.buy_count)
                              ).toLocaleString("en-us")}{" "}
                              đ
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Tổng số tiền :{" "}
                              {parseInt(item?.total_price).toLocaleString(
                                "en-us"
                              )}{" "}
                              đ
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Tổng số Point :{" "}
                              {parseInt(item?.total_point).toLocaleString(
                                "en-us"
                              )}{" "}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Lợi nhuận từ sản phẩm :{" "}
                              {parseInt(
                                item?.total_profit_final_for_seller || 0
                              ).toLocaleString("en-US")}{" "}
                              đ
                            </Typography>
                          </li>
                          <li style={{ marginTop: "10px" }}>
                            {state?.status ===
                            parseInt(process.env.REACT_APP_DELIVERED) ? (
                              <Button
                                sx={{ textAlign: "center" }}
                                variant="outlined"
                                color="warning"
                                onClick={() => {
                                  handleOpenReviews();
                                  setStateModalReview(item);
                                }}
                              >
                                <GradeIcon fontSize="10px"></GradeIcon>
                                <Typography variant="subtitle2" ml={1}>
                                  Đánh giá
                                </Typography>
                              </Button>
                            ) : (
                              ""
                            )}
                          </li>
                        </div>
                        <div style={{ display: "flex" }} className="col-3">
                          {" "}
                          <img
                            style={{ margin: "auto" }}
                            src={
                              item?.product_images &&
                              item?.product_images[0]?.path
                            }
                            width={"100%"}
                            height={150}
                          ></img>
                        </div>
                      </div>

                      <hr></hr>
                    </>
                  ))}
              </ul>
            </Box>
            <Box
              borderBottom={"solid 5px #eeedee"}
              borderTop={"solid 5px #eeedee"}
              p={"12px"}
            >
              {state?.status ===
              parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION) ? (
                <Typography color={"#9b9b61"}>
                  Tình trạng đơn : ĐANG CHỜ XÁC NHẬN
                </Typography>
              ) : state?.status ===
                parseInt(process.env.REACT_APP_WAIT_FOR_GETTING) ? (
                <Typography color={"#00BFFF"}>
                  Tình trạng đơn : ĐANG ĐƯỢC LẤY HÀNG
                </Typography>
              ) : state?.status ===
                parseInt(process.env.REACT_APP_IN_PROGRESS) ? (
                <Typography color={"#FFA500"}>
                  Tình trạng đơn : ĐƠN ĐANG VẬN CHUYỂN
                </Typography>
              ) : state?.status ===
                parseInt(process.env.REACT_APP_DELIVERED) ? (
                <Typography color={"#008000"}>
                  Tình trạng đơn : ĐÃ ĐƯỢC GIAO
                </Typography>
              ) : (
                <Typography color={"#FF0000"}>
                  Tình trạng đơn : ĐÃ HUỶ
                </Typography>
              )}
            </Box>
            <Box>
              {state?.status === parseInt(process.env.REACT_APP_DELIVERED) ||
              state?.status === parseInt(process.env.REACT_APP_CANCELLED) ? (
                " "
              ) : (
                <Button
                  onClick={handleDelete}
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Huỷ đơn hàng
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Typography p={"12px"}>
            Không tìm thấy dữ liệu đơn hàng này ...{" "}
          </Typography>
        )}

        {/* //MODAL PAYMENT */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...styleModal,
              width: "350px",
              height: "auto",
              maxHeight: "70vh",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <CloseModal handleclose={handleClose}></CloseModal>

            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight={600}
              component="h2"
            >
              Mã đơn hàng: {stateModal?.code_purchase}
            </Typography>
            <Box className="row">
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                gap={1}
                className="col-xs-12 col-md-12"
              >
                {" "}
                <Typography>**Thông tin chuyển khoản</Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={2}
                  bgcolor={"#eeeeee"}
                  borderRadius={"10px"}
                >
                  <Typography>Số tài khoản: 123</Typography>
                  <Button>
                    <ContentCopyIcon></ContentCopyIcon>
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={2}
                  bgcolor={"#eeeeee"}
                  borderRadius={"10px"}
                >
                  <Typography>Chủ tài khoản: 123</Typography>
                  <Button>
                    <ContentCopyIcon></ContentCopyIcon>
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={2}
                  bgcolor={"#eeeeee"}
                  borderRadius={"10px"}
                >
                  <Typography>
                    Nội dung: {stateModal?.nd} - {stateModal?.code_purchase}
                  </Typography>
                  <Button>
                    <ContentCopyIcon></ContentCopyIcon>
                  </Button>
                </Box>
                <Box mt={2}>
                  <label
                    htmlFor="file"
                    style={{
                      border: "1px solid #ccc",
                      display: "inline-block",
                      padding: "6px 12px",
                      cursor: "pointer",
                      color: "black",
                      borderRadius: "4px",
                    }}
                    className="custom-file-upload"
                  >
                    Chọn hoá đơn
                  </label>

                  <Input
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    name="images"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Box>
                {imageFile ? (
                  <img
                    style={{
                      marginRight: "5px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      objectFit: "cover",
                      border: "solid 1px",
                      height: "200px",
                    }}
                    src={stateImg}
                    width={"100%"}
                  ></img>
                ) : (
                  <Typography pl={2} variant="subtitle2" color={"GrayText"}>
                    **Vui lòng chọn hoá đơn
                  </Typography>
                )}
                {Loading ? (
                  <Button
                    sx={{ marginTop: "10px" }}
                    fullWidth
                    variant="outlined"
                  >
                    Đang tiến hành giao dịch....
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSend(stateModal?._id);
                    }}
                  >
                    Xác nhận thanh toán
                  </Button>
                )}
                <Typography
                  color={"#0628C7"}
                  bgcolor={"#E6E9FF"}
                  p={1}
                  fontSize={"14px"}
                >
                  Thanh toán đúng nội dung chuyển khoản để được kích hoạt tài
                  khoản tự động. Trường hợp chuyển khoản sai nội dung, vui lòng
                  gọi đến số xxxxxxx để được hổ trợ chi tiết.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* MODAL REVIEWS */}

        <Modal
          open={openReviews}
          onClose={handleCloseReviews}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...styleModal,
              width: "350px",
              height: "auto",
              maxHeight: "70vh",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <CloseModal handleclose={handleCloseReviews}></CloseModal>
            <Typography color={"#393E40"} fontSize={"14px"} fontWeight={600}>
              Đánh Giá Sản Phẩm : {stateModalReview?.product_name}
            </Typography>
            <Rating
              name="half-rating"
              value={valueRating}
              sx={{
                color: "red",
                fontSize: "2rem",
                "& .MuiRating-iconFilled": {
                  color: "#faaf00",
                  fontSize: "2rem",
                },
              }}
              onChange={(event, newValue) => {
                setValueRating(newValue);
              }}
            />

            <textarea
              cols={10}
              rows={5}
              placeholder="Nội dung đánh giá sản phẩm ...."
              value={stateComment}
              onChange={(e) => {
                setStateComment(e.target.value);
              }}
              style={{
                width: "100%",
                borderWidth: "1px",
                borderRadius: "10px",
                padding: "10px",
              }}
            ></textarea>
            {Loading ? (
              <Button variant="outlined">Đang thực hiện ...</Button>
            ) : (
              <Button variant="contained" onClick={handleCreateReview}>
                Gửi đánh giá
              </Button>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default OrderDetails;
