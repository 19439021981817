import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import Navbar from "../../component/Navbar/navbar";

import { useEffect, useState } from "react";
import IMGCROWN from "../../assets/image/rank/crown.png";
import IMGBGRANK from "../../assets/image/rank/bg.jpg";
import { GET_ALL_ACCOUNT_SELLER_RANK } from "../../services/handleAccount/handleAccount";
import { MOCK_DATA_USER } from "../../data/data";
import { useMutation, useQuery } from "@tanstack/react-query";

const Rank = ({ stateProduct, stateCart }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [stateUser, setStateUser] = useState([]);

  const GET_ALL_ACCOUNT_RANK = useMutation({
    mutationKey: ["GET_ALL_ACCOUNT_RANK"],
    mutationFn: (body) => {
      return GET_ALL_ACCOUNT_SELLER_RANK(body);
    },
  });
  const handleIncrease = async () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    if (newDate.getMonth() === 0) {
      newDate.setFullYear(newDate.getFullYear());
    }

    setCurrentDate(newDate);
  };
  const handleDecrease = async () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);

    if (newDate.getMonth() === 11) {
      newDate.setFullYear(newDate.getFullYear());
    }

    setCurrentDate(newDate);
  };
  useEffect(() => {
    // Ngày bắt đầu của tháng
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    // Ngày kết thúc của tháng
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1
    const formattedDateF = `${year}-${month}-${String(
      startOfMonth.getDate()
    ).padStart(2, "0")}`;
    const formattedDateT = `${year}-${month}-${String(
      endOfMonth.getDate()
    ).padStart(2, "0")}`;

    let Form = {
      startTime: formattedDateF,
      endTime: formattedDateT,
      top: 10,
      sort: "desc",
    };
    GET_ALL_ACCOUNT_RANK.mutate(Form, {
      onSuccess: (data) => {
        if (data?.data?.data) {
          setStateUser(data?.data?.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }, [currentDate]);
  return (
    <>
      <Navbar
        stateProduct={stateProduct}
        stateCart={stateCart}
        activeIndex={1}
      ></Navbar>

      <Box className="AddpadingLeftProduct">
        <Box
          position={"relative"}
          className="row"
          justifyContent={"center"}
          alignContent={"center"}
          height={"300px"}
          sx={{ backgroundImage: `url(${IMGBGRANK})`, objectFit: "contain" }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              display: "flex",
              height: "50px",
              // top: "1%",
              // left: "50%",
              // transform: "translate(0 %, 50%)",
            }}
          >
            <ButtonGroup
              sx={{ margin: "auto" }}
              variant="contained"
              aria-label="Basic button group"
            >
              <Button onClick={handleDecrease}>{"<"}</Button>
              <Button>Tháng {currentDate.getMonth() + 1}</Button>
              <Button onClick={handleIncrease}>{">"}</Button>
            </ButtonGroup>
          </Box>
          {/* TOP 2 */}
          <Box
            height={"100%"}
            alignContent={"end"}
            justifyContent={"end"}
            className="col-3"
            p={0}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              position={"relative"}
              borderRadius={"10px 10px 0px 0px"}
              bgcolor={"rgba(0, 0, 0, 0.3)"}
              height={"150px"}
            >
              <Box
                sx={{
                  position: "absolute",

                  left: "50%",
                  top: "-10%",

                  transform: "translate(-50%, -10%)",
                }}
              >
                <Avatar
                  src={stateUser[1]?.avatar}
                  sx={{
                    position: "absolute",
                    width: 56,
                    height: 56,
                    left: "50%",
                    top: "-10%",

                    transform: "translate(-50%, -10%)",
                  }}
                ></Avatar>
                <Typography
                  sx={{
                    position: "absolute",
                    width: 30,
                    height: 30,
                    left: "50%",
                    top: "120%",
                    borderRadius: "100%",
                    fontSize: "18px",
                    fontWeight: 700,
                    backgroundColor: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 120%)",
                    border: "solid 2px #000f80",
                  }}
                >
                  2
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    width: "100px",
                    height: 30,
                    left: "50%",
                    top: "220%",
                    borderRadius: "100%",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 220%)",
                  }}
                >
                  {parseInt(stateUser[1]?.totalOrderMoney || 0).toLocaleString(
                    "en-us"
                  )}
                </Typography>
              </Box>

              <Box></Box>
            </Box>
          </Box>

          {/* TOP 1 */}
          <Box
            height={"100%"}
            alignContent={"end"}
            justifyContent={"end"}
            className="col-3"
            p={0}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              position={"relative"}
              borderRadius={"10px 10px 0px 0px"}
              bgcolor={"rgba(0, 0, 0, 0.5)"}
              height={"170px"}
            >
              <Box
                sx={{
                  position: "absolute",

                  left: "50%",
                  top: "-10%",

                  transform: "translate(-50%, -10%)",
                }}
              >
                <Avatar
                  src={IMGCROWN}
                  sx={{
                    position: "absolute",
                    width: 66,
                    height: 66,
                    left: "50%",
                    top: "-80%",
                    zIndex: 2,
                    transform: "translate(-50%, -80%)",
                  }}
                ></Avatar>
                <Avatar
                  src={stateUser[0]?.avatar}
                  sx={{
                    position: "absolute",
                    width: 56,
                    height: 56,
                    left: "50%",
                    top: "-10%",
                    border: "solid 4px #fedf72",
                    transform: "translate(-50%, -10%)",
                  }}
                ></Avatar>
                <Typography
                  sx={{
                    position: "absolute",
                    width: 30,
                    height: 30,
                    left: "50%",
                    top: "120%",
                    borderRadius: "100%",
                    fontSize: "18px",
                    fontWeight: 700,
                    backgroundColor: "#fedf72",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 120%)",
                    border: "solid 2px #000f80",
                  }}
                >
                  1
                </Typography>

                <Typography
                  sx={{
                    position: "absolute",
                    width: "100px",
                    height: 30,
                    left: "50%",
                    top: "220%",
                    borderRadius: "100%",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 220%)",
                  }}
                >
                  <br></br>
                  {parseInt(stateUser[0]?.totalOrderMoney || 0).toLocaleString(
                    "en-us"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* TOP 3 */}
          <Box
            height={"100%"}
            alignContent={"end"}
            justifyContent={"end"}
            className="col-3"
            p={0}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              position={"relative"}
              borderRadius={"10px 10px 0px 0px"}
              bgcolor={"rgba(0, 0, 0, 0.4)"}
              height={"130px"}
            >
              <Box
                sx={{
                  position: "absolute",

                  left: "50%",
                  top: "-10%",

                  transform: "translate(-50%, -10%)",
                }}
              >
                <Avatar
                  src={stateUser[2]?.avatar}
                  sx={{
                    position: "absolute",
                    width: 56,
                    height: 56,
                    left: "50%",
                    top: "-10%",
                    border: "solid 4px #fa541b",
                    transform: "translate(-50%, -10%)",
                  }}
                ></Avatar>
                <Typography
                  sx={{
                    position: "absolute",
                    width: 30,
                    height: 30,
                    left: "50%",
                    top: "120%",
                    borderRadius: "100%",
                    fontSize: "18px",
                    fontWeight: "700",
                    backgroundColor: "#fa541b",
                    color: "black",

                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 120%)",
                    border: "solid 2px #000f80",
                  }}
                >
                  3
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    width: "100px",
                    height: 30,
                    left: "50%",
                    top: "220%",
                    borderRadius: "100%",
                    fontSize: "13px",
                    fontWeight: 700,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    transform: "translate(-50%, 220%)",
                  }}
                >
                  {parseInt(stateUser[2]?.totalOrderMoney || 0).toLocaleString(
                    "en-us"
                  )}
                </Typography>
              </Box>

              <Box></Box>
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor={"#fafbff"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          p={4}
          gap={2}
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          {stateUser.length === 0 ? (
            <Typography>*Chưa có dữ liệu thống kê</Typography>
          ) : stateUser && stateUser[0]?.name ? (
            stateUser.map((item, index) => (
              <Box
                p={"16px"}
                sx={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "24px",
                  boxShadow: " 0 2px 6px rgba(0, 0, 0, 0.2)",
                  display: "flex",
                }}
                className="row"
              >
                <Box className="col-3" p={0}>
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    src={item?.avatar}
                  ></Avatar>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  className="col-9"
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                      color: "#1F1F1F",
                      fontWeight: "600",
                    }}
                  >
                    {item?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#1F1F1F",
                    }}
                  >
                    Với tổng doanh thu lợi nhuận
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FF6314",
                      fontWeight: "600",
                    }}
                  >
                    đ{" "}
                    {parseInt(item?.totalOrderMoney || 0).toLocaleString(
                      "en-us"
                    )}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};
export default Rank;
