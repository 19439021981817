import * as React from "react";
import { DataGrid } from "@mui/x-data-grid/";
import { GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Input, InputAdornment, Pagination, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import PropTypes from "prop-types";
import { Snackbar } from "@mui/base/Snackbar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
export const SpanLable = ({ content, fontSize, fontWeight, icon }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {icon ? (
        <img
          width={40}
          height={40}
          style={{ objectFit: "contain" }}
          src={icon}
        ></img>
      ) : (
        ""
      )}
      <Typography
        ml={3}
        color={"#1F1F1F"}
        fontWeight={fontWeight || 500}
        fontSize={fontSize || ".9rem"}
      >
        {" "}
        {content}
      </Typography>
    </Box>
  );
};

export const TriggerButton = styled("button")(
  ({ theme }) => `
  width:100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);

export const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  z-index: 5500;
  display: flex;
  bottom: 16px;
  right: 16px;
  max-width: 560px;
  min-width: 300px;
`;

export const SnackbarContent = styled("div")(
  ({ theme }) => `
  display: flex;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: ${theme.palette.mode === "dark"
      ? `0 2px 16px rgba(0,0,0, 0.5)`
      : `0 2px 16px ${grey[200]}`
    };
  padding: 0.75rem;
  color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;

  & .snackbar-message {
    flex: 1 1 0%;
    max-width: 100%;
  }

  & .snackbar-title {
    margin: 0;
    line-height: 1.5rem;
    margin-right: 0.5rem;
  }

  & .snackbar-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
  }

  & .snackbar-close-icon {
    cursor: pointer;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 4px;

    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }
  }
  `
);

export const positioningStyles = {
  entering: "translateX(0)",
  entered: "translateX(0)",
  exiting: "translateX(500px)",
  exited: "translateX(500px)",
  unmounted: "translateX(500px)",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `
);

const StyledInput = styled("input")(
  ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
    };
    border-radius: 8px;
    margin: 0 8px;
    padding: 10px 12px;
    outline: 0;
    min-width: 0;
    width: 4rem;
    text-align: center;
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
    }
  
    &:focus-visible {
      outline: 0;
    }
  `
);

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
    width: 32px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
      border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `
);
const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment",
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});
export const Columns = [
  //   { flex: 1, field: "id", headerName: "ID", width: 70 },
  {
    flex: 2,
    field: "name",

    headerName: "Sản Phẩm",
    renderCell: ProductCell,
  },
  {
    flex: 1,
    field: "price",
    headerName: "Đơn giá",
  },
  {
    flex: 1,
    field: "amount",
    headerName: "Số Lượng",
    renderCell: ProductQuantCell,
  },

  {
    flex: 1,
    field: "fullName",
    headerName: "Thao Tác",
    renderCell: ProductTTCell,
  },
];

function ProductQuantCell(params) {
  return (
    <>
      <Box display={"flex"}>
        <NumberInput aria-label="Quantity Input" min={1} max={99} />;
      </Box>
    </>
  );
}
function ProductCell(params) {
  return (
    <>
      <Box
        display={"flex"}
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
      >
        <img height={"200"} width={"100"} src={params.row.image[0]}></img>
        <Typography
          ml={2}
          width={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          fontSize={".8rem"}
          fontWeight={"200"}
        >
          {params.value}
        </Typography>
        <Typography ml={2} fontSize={".8rem"} fontWeight={"200"}>
          Lợi nhuận : {params.row.Profit}
        </Typography>
      </Box>
    </>
  );
}
function ProductTTCell(params) {
  return (
    <>
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Button color="error">Xoá</Button>
      </ButtonGroup>
    </>
  );
}
export function SimpleDialog(props) {
  let nav = useNavigate();
  const { onClose, selectedValue, open, Emails, stateCount, statePage, setStatePage, isloadingCustomer, stateName, setStateName } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const handleChange = (event, value) => {
    setStatePage(value);
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Chọn ra khách hàng</DialogTitle>

      <Box sx={{ padding: "0px 10px" }}>

        <TextField
          variant="outlined"
          placeholder="Tìm kiếm khách hàng.."
          value={stateName}
          onChange={(e) => {
            setStateName(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ height: "40px" }} position="start">
                <SearchIcon
                  sx={{
                    "&:hover": {
                      color: "white",
                      transition: "transform 0.3s, color 0.3s",
                      cursor: "pointer",
                    },
                  }}
                  fontSize="large"
                  color="action"
                ></SearchIcon>
              </InputAdornment>
            ),
            style: {
              height: "40px",

            },
          }}
          fullWidth
          sx={{
            backgroundColor: "#EFEFEF",
            borderRadius: "20px",
            height: "40px",
            cursor: "pointer",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& fieldset": {
                border: "none", // Xóa viền ngoài
              },
              "&:hover fieldset": {
                border: "none", // Xóa viền khi hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // Xóa viền khi có focus
              },
            },
            "& .MuiOutlinedInput-input": {
              padding: "12px",
              height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
            },
            "& .MuiInputBase-input": {
              padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
            },
          }}
        />
      </Box>

      <Box p={4}>
        <Stack spacing={2}>
          <Typography>Trang: {statePage}</Typography>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={stateCount}
            page={statePage}
            onChange={handleChange}
          />
        </Stack>
      </Box>

      <List sx={{ pt: 0 }}>
        {isloadingCustomer ? <Typography>Đang tải dử liệu...</Typography> : <>  {Emails && Emails.map((item, index) => (

          <ListItem disableGutters key={item?._id}>
            <ListItemButton onClick={() => handleListItemClick(item)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))}</>}

        <ListItem disableGutters>
          <ListItemButton
            autoFocus
            onClick={() => {
              nav("/customer")
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText

              primary="Thêm khách hàng"
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog >
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export const Rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];
