import { Alert, Box, Button, CircularProgress, Input } from "@mui/material";
import Back from "../../component/Back";

import { useState } from "react";
import { CHANGE_PASS } from "../../services/handleInfor/handleInfor";
import { useMutation } from "@tanstack/react-query";
const InforPassword = ({ stateAccount, handleTrggerRefreshAccount }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [IsloadingChangePassword, setIsloadingChangePassword] = useState(false);
  const [DataError, setDataError] = useState({});

  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };

  const [changePass, setChangePass] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const handleChangePass = (e) => {
    setChangePass({
      ...changePass,
      [e.target.name]: e.target.value,
    });
  };

  const HANDLE_CHANGE_PASSWORD = useMutation({
    mutationFn: (body) => {
      return CHANGE_PASS(body);
    },
  });
  const handleFormSubmitChangePass = () => {
    setIsloadingChangePassword(true);
    HANDLE_CHANGE_PASSWORD.mutate(changePass, {
      onSuccess: (data) => {
        console.log("data?.trigger " + data?.trigger);
        if (data?.trigger) {
          setDataError({
            errors: {
              password: {
                msg: "Mật khẩu cũ không hợp lệ",
              },
            },
          });
        } else {
          if (data?.errors) {
            setDataError(data);
          } else {
            setChangePass({
              old_password: "",
              password: "",
              confirm_password: "",
            });
            setDataError({});
            alert("Đã thay đổi mật khẩu thành công");
            handleTrggerRefreshAccount();
          }
        }

        setIsloadingChangePassword(false);
      },
      onError: (err) => {
        console.log("err", err);
        setIsloadingChangePassword(false);
      },
    });
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Back content={"Thay đổi mật khẩu"}></Back>
        <Box p={3} className="PaddingLRForInfor">
          <Box mb={2}>
            <label style={{ fontSize: "16px", color: "black" }}>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={handleCheckboxChange}
              />
              Hiển thị mật khẩu
            </label>
          </Box>
          <Box>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent={"space-between"}
              gap="30px"
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                {" "}
                <label style={{ width: "200px", color: "#b9b9b9" }}>
                  Mật khẩu cũ
                </label>
                <Input
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="old_password"
                  onChange={handleChangePass}
                  value={changePass?.old_password}
                  sx={{ gridColumn: "span 2", paddingLeft: "10px" }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <label style={{ width: "200px", color: "#b9b9b9" }}>
                  Mật khẩu mới
                </label>
                <Input
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChangePass}
                  value={changePass?.password}
                  sx={{ gridColumn: "span 4", paddingLeft: "10px" }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <label style={{ width: "200px", color: "#b9b9b9" }}>
                  Xác nhận lại mật khẩu
                </label>
                <Input
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="confirm_password"
                  onChange={handleChangePass}
                  value={changePass?.confirm_password}
                  sx={{ gridColumn: "span 4", paddingLeft: "10px" }}
                />
              </Box>

              {DataError && DataError.errors?.password?.msg ? (
                <Alert style={{ marginTop: "1rem" }} severity="error">
                  {DataError.errors?.password?.msg}
                </Alert>
              ) : (
                ""
              )}

              {DataError && DataError.errors?.confirm_password?.msg ? (
                <Alert style={{ marginTop: "1rem" }} severity="error">
                  {DataError.errors?.confirm_password?.msg}
                </Alert>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {IsloadingChangePassword ? (
                <Button
                  color="secondary"
                  sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                  variant="contained"
                >
                  <CircularProgress></CircularProgress>
                </Button>
              ) : (
                <Button
                  color="secondary"
                  sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                  variant="contained"
                  onClick={handleFormSubmitChangePass}
                >
                  Đổi mật khẩu
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default InforPassword;
