import NProgress from "nprogress";
import axios from "axios";
import { URL_BACKEND } from "../../contanst/contanst";

const api = axios.create({
  baseURL: `${URL_BACKEND}`, // Đặt base URL cho tất cả các yêu cầu
  withCredentials: true,
  timeout: 15000,
});
export const RF_TOKEN_F = async (req) => {
  try {
    NProgress.start();

    const res = await api.post(`/auth/refresh-token`, {
      role: parseInt(process.env.REACT_APP_ROLE_SELLER),
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
