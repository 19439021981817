import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  FormBase,
  FooterBase,
  AppContainer,
  HeaderAuth,
  ButtonSignIn,
  ErrorMessage,
} from "./Component/Component";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

import {
  HandleRegister,
  HandleRegisterCustomer,
} from "../../services/handleAccount/handleAccount";
import { useNavigate } from "react-router-dom";
const SignupForm = (props) => {
  let nav = useNavigate();
  const [errorRequired, setErrorRequired] = useState("");
  const [stateError, setStateError] = useState({});
  const [stateTimeSales, SetstateTimeSales] = useState("");

  const handleChange = (event) => {
    SetstateTimeSales(event.target.value);
  };
  const [FormRegister, setFormRegister] = useState({
    name: "",
    phone: "",
    email: "",
    date_of_birth: "2024-05-07T08:06:45.082Z",
    address: "...",
    password: "",
    confirm_password: "",
  });
  const initStateRegister = () => {
    setFormRegister({
      name: "",
      phone: "",
      email: "",
      date_of_birth: "2024-05-07T08:06:45.082Z",
      address: "...",
      password: "",
      confirm_password: "",
    });
    SetstateTimeSales("none");
  };

  const onChange = (e) => {
    setErrorRequired("");
    setStateError({});
    setFormRegister({
      ...FormRegister,
      [e.target.name]: e.target.value,
    });
  };
  const useMutation_Register = useMutation({
    mutationFn: (body) => {
      return HandleRegister(body);
    },
  });
  const useMutation_Register_Cus = useMutation({
    mutationFn: (body) => {
      return HandleRegisterCustomer(body);
    },
  });
  const validate = () => {
    const newErrors = {};
    function isValidPhoneNumber(phoneNumber) {
      // Biểu thức chính quy để kiểm tra số điện thoại Việt Nam
      const regex = /^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})$/;

      // Kiểm tra xem số điện thoại có khớp với biểu thức chính quy không
      return regex.test(phoneNumber);
    }
    function isValidEmail(email) {
      // Biểu thức chính quy để kiểm tra định dạng email
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Kiểm tra xem email có khớp với biểu thức chính quy không
      return regex.test(email);
    }
    function containsSpecialChar(str) {
      // Cho phép chữ cái, số, dấu cách, gạch ngang, gạch dưới, dấu chấm, và các ký tự tiếng Việt có dấu
      const specialCharsRegex =
        /[^a-zA-Z0-9\s\-_áàảãạăắằẳẵặâấầẩẫậéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵđ]/u;
      return specialCharsRegex.test(str);
    }
    if (!FormRegister.name) {
      newErrors.name = "Vui lòng không bỏ trống họ và tên !!";
    } else {
      if (containsSpecialChar(FormRegister.name)) {
        newErrors.name = "Họ và tên không được chứa ký tự đặt biệt !!";
      }
    }

    if (!FormRegister.email) {
      newErrors.email = "Vui lòng không bỏ trống email !!";
    } else {
      if (!isValidEmail(FormRegister.email)) {
        newErrors.email = "Email không hợp lệ !!";
      }
    }

    if (!FormRegister.phone) {
      newErrors.phone = "Vui lòng không bỏ trống số điện thoại !!";
    } else {
      if (!isValidPhoneNumber(FormRegister.phone)) {
        newErrors.phone = "Số điện thoại không hợp lệ !!";
      }
    }
    if (stateTimeSales === "") {
      newErrors.stateTimeSales = "Vui lòng chọn mốc thời gian";
    }

    setErrorRequired(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const sRegister = () => {
    if (validate()) {
      let FormCreate = {
        name: FormRegister?.name,
        email: FormRegister?.email,
        phone: FormRegister?.phone,
        preferred_time: parseInt(stateTimeSales),
      };
      useMutation_Register_Cus.mutate(FormCreate, {
        onSuccess: (data) => {
          if (data?.errors) {
            setStateError(data?.errors);
          } else {
            alert("Đăng ký tài khoản thành công");
            initStateRegister();
          }
        },
      });
    }
  };
  return (
    <>
      <AppContainer>
        <FormBase>
          <Box sx={{ height: "91vh" }}>
            <HeaderAuth></HeaderAuth>
            <Box
              mt={2}
              sx={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                maxHeight: "77vh",
                overflowY: "scroll",
              }}
            >
              <Typography
                fontSize={"26px"}
                fontWeight={700}
                variant="subtitle1"
              >
                Trở thành đối tác kinh doanh Với Tikpii
              </Typography>

              <Typography fontSize={"16px"} variant="subtitle2">
                Vui lòng để lại thông tin đăng ký để Tikpii có thể liên hệ và
                hướng dẫn với bạn.
              </Typography>

              <form style={{ marginTop: "1rem" }}>
                <Box className="form-group">
                  <label
                    style={{ color: "#5C6366", fontSize: "14px" }}
                    htmlFor="name"
                    className="form-label"
                  >
                    Họ và Tên
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={FormRegister.name}
                    onChange={onChange}
                    style={{ height: "48px", fontSize: ".9rem" }}
                    className="form-control"
                    placeholder="Họ và Tên"
                  />
                  {errorRequired.name && (
                    <ErrorMessage>* {errorRequired.name}</ErrorMessage>
                  )}
                </Box>
                <Box style={{ marginTop: "1rem" }} className="form-group">
                  <label
                    style={{ color: "#5C6366", fontSize: "14px" }}
                    htmlFor="phone"
                    className="form-label"
                  >
                    Số điện thoại
                  </label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={FormRegister.phone}
                    onChange={onChange}
                    style={{ height: "48px", fontSize: ".9rem" }}
                    className="form-control"
                    placeholder="Số điện thoại"
                  />
                  {errorRequired.phone && (
                    <ErrorMessage>* {errorRequired.phone}</ErrorMessage>
                  )}
                  {stateError?.phone && (
                    <ErrorMessage>* {stateError?.phone?.msg}</ErrorMessage>
                  )}
                </Box>
                <Box style={{ marginTop: "1rem" }} className="form-group">
                  <label
                    style={{ color: "#5C6366", fontSize: "14px" }}
                    htmlFor="email"
                    className="form-label"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={FormRegister.email}
                    onChange={onChange}
                    style={{ height: "48px", fontSize: ".9rem" }}
                    className="form-control"
                    placeholder="Địa chỉ Email"
                  />
                  {errorRequired.email && (
                    <ErrorMessage>* {errorRequired.email}</ErrorMessage>
                  )}
                  {stateError?.email && (
                    <ErrorMessage>* {stateError?.email?.msg}</ErrorMessage>
                  )}
                </Box>

                <Box sx={{ minWidth: 120, marginTop: "3.5rem" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Thời gian nghe máy
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={stateTimeSales}
                      label="Thời gian nghe máy"
                      onChange={handleChange}
                    >
                      <MenuItem
                        value={parseInt(process.env.REACT_APP_TIMLINE_MORNING)}
                      >
                        8h-12h
                      </MenuItem>
                      <MenuItem
                        value={parseInt(
                          process.env.REACT_APP_TIMLINE_AFTERNOON
                        )}
                      >
                        13h30-18h
                      </MenuItem>
                      <MenuItem
                        value={parseInt(process.env.REACT_APP_TIMLINE_EVENING)}
                      >
                        sau 18h
                      </MenuItem>
                      <MenuItem
                        value={parseInt(process.env.REACT_APP_TIMLINE_NIGHT)}
                      >
                        sau 20h
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {errorRequired?.stateTimeSales && (
                    <ErrorMessage>
                      * {errorRequired?.stateTimeSales}
                    </ErrorMessage>
                  )}
                </Box>
              </form>
            </Box>
          </Box>
          <Box
            sx={{
              paddingLeft: "2rem",
              paddingTop: "1rem",
              paddingBottom: "2rem",
              paddingRight: "2rem",
              textAlign: "center",
              backgroundColor: "#f7f7fa",
            }}
          >
            <ButtonSignIn onClick={sRegister}>Đăng Ký</ButtonSignIn>
          </Box>
        </FormBase>
      </AppContainer>
    </>
  );
};
export default SignupForm;
