import { Box, Breadcrumbs, Button, Link, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Back from "../../component/Back";
const ShareOrderDetails = () => {
  let nav = useNavigate();
  const location = useLocation();
  const { state } = location;

  return (
    <>
      <Back content={"Chi tiết đơn hàng"}></Back>
      <Box>Share hoá đơn</Box>
      <Box className="PaddingLRForCustomer">
        <Box p={1}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/#/list-order">
              Đơn hàng
            </Link>
            <Typography color="text.primary">Chi tiết đơn hàng</Typography>
          </Breadcrumbs>
        </Box>

        {state ? (
          <Box style={{ backgroundColor: "white" }}>
            <Box
              display={"flex"}
              padding={"12px 12px 0px 12px"}
              justifyContent={"space-between"}
              width={"100%"}
              borderBottom={"solid 1px gainsboro"}
              sx={{ cursor: "pointer" }}
            >
              <ul style={{ padding: "0px", width: "80%" }}>
                <li>
                  <Typography
                    fontWeight={700}
                    color={"#607CFB"}
                    fontSize={".7rem"}
                  >
                    <LinkIcon color={"#607CFB"} fontSize="small">
                      {" "}
                    </LinkIcon>{" "}
                    Mã đơn hàng : {state?._id}
                  </Typography>
                </li>
                <span style={{ color: "GrayText" }}>
                  **Thông tin khách hàng
                </span>
                <li style={{ paddingBottom: "2%" }}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    Tên: {state?.customer?.name}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Số điện thoại: {state?.customer?.phone}
                  </Typography>
                </li>
                <li>
                  <Typography color={"#607CFB"}>
                    Địa chỉ :{state?.customer?.address}
                  </Typography>
                </li>
                <span style={{ color: "GrayText" }}>**Thống kê số tiền</span>
                <li>
                  <Typography fontWeight={"600"}>
                    Tổng giá trị đơn hàng :{" "}
                    {parseInt(state?.purchase_total_price).toLocaleString(
                      "en-us"
                    )}{" "}
                    đ
                  </Typography>
                </li>
                <li>
                  <Typography fontWeight={"600"}>
                    Lợi nhuận thu về :{" "}
                    {parseInt(
                      state?.purchase_total_profit_for_seller
                    ).toLocaleString("en-us")}{" "}
                    đ
                  </Typography>
                </li>
                <span style={{ color: "GrayText" }}>**Thông tin sản phẩm</span>
                <br></br>
                <span>
                  Số lượng sản phẩm{" "}
                  {state?.purchase_items ? state?.purchase_items.length : 0}
                </span>
                <hr></hr>
                {state?.purchase_items &&
                  state?.purchase_items.map((item, index) => (
                    <>
                      <div className="row">
                        <div className="col-md-7">
                          {" "}
                          <li>
                            <Typography>
                              Tên sản phẩm : {item?.product_name}
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              Số lượng mua : {item?.buy_count}
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              Giá gốc :{" "}
                              {(
                                parseInt(item?.total_price) /
                                parseInt(item?.buy_count)
                              ).toLocaleString("en-us")}
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={"600"}>
                              Tổng số tiền :{" "}
                              {parseInt(item?.total_price).toLocaleString(
                                "en-us"
                              )}
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={"600"}>
                              Lợi nhuận từ sản phẩm :{" "}
                              {parseInt(
                                item?.total_profit_for_seller
                              ).toLocaleString("en-us")}
                            </Typography>
                          </li>
                        </div>
                        <div className="col-md-5">
                          {" "}
                          <li>
                            <Typography fontWeight={"600"}>
                              <img
                                src={
                                  item?.product_images &&
                                  item?.product_images[0]?.path
                                }
                                width={"100%"}
                                height={100}
                              ></img>
                            </Typography>
                          </li>
                        </div>
                      </div>

                      <hr></hr>
                    </>
                  ))}
              </ul>
            </Box>

            <Box borderBottom={"solid 5px #eeedee"} p={"12px"}>
              {state?.status ===
              parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION) ? (
                <Typography color={"#9b9b61"}>
                  Tình trạng đơn : ĐANG CHỜ XÁC NHẬN
                </Typography>
              ) : state?.status ===
                parseInt(process.env.REACT_APP_WAIT_FOR_GETTING) ? (
                <Typography color={"#00BFFF"}>
                  Tình trạng đơn : ĐANG ĐƯỢC LẤY HÀNG
                </Typography>
              ) : state?.status ===
                parseInt(process.env.REACT_APP_IN_PROGRESS) ? (
                <Typography color={"#FFA500"}>
                  Tình trạng đơn : ĐƠN ĐANG VẬN CHUYỂN
                </Typography>
              ) : state?.status ===
                parseInt(process.env.REACT_APP_DELIVERED) ? (
                <Typography color={"#008000"}>
                  Tình trạng đơn : ĐÃ ĐƯỢC GIAO
                </Typography>
              ) : (
                <Typography color={"#FF0000"}>
                  Tình trạng đơn : ĐÃ HUỶ
                </Typography>
              )}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              {state?.status === parseInt(process.env.REACT_APP_DELIVERED) ? (
                ""
              ) : (
                <Button>
                  <EventBusyIcon fontSize="small"></EventBusyIcon>
                  Huỷ đơn
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Typography>Không có dữ liệu đơn hàng này :(</Typography>
        )}
      </Box>
    </>
  );
};
export default ShareOrderDetails;
