export const notification = {
  title: "Thêm giỏ hàng thành công!",
  message: "Hãy kiểm tra lại trong giỏ hàng",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
  animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
