import Box from "@mui/material/Box";

import HomeIcon from "../../assets/svg/SVG icons/50.svg";
import Product from "../../assets/svg/SVG icons/53.svg";
import Order from "../../assets/svg/SVG icons/51.svg";
import Customer from "../../assets/svg/SVG icons/52.svg";
import Account from "../../assets/svg/SVG icons/54.svg";
import { Avatar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const FooterNav = ({ stateAccount }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  let nav = useNavigate();
  return (
    <Box
      width={"480px"}
      bgcolor={"white"}
      height={"58px"}
      position={"fixed"}
      boxShadow={"24"}
      bottom={"0"}
      className="PaddingLRForForFooterNav"
    >
      <ul
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <li
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
            color: activeIndex === 0 ? "blue" : "#393E40",
            transition: "transform 0.1s ease-in-out",
            transform: activeIndex === 0 ? "scale(1.1)" : "scale(1)",
          }}
          onClick={() => {
            handleItemClick(0);
            nav("/home");
          }}
        >
          <img
            style={{
              filter:
                activeIndex === 0
                  ? "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)"
                  : "none",
            }}
            src={HomeIcon}
            width={25}
            height={25}
          ></img>
          <Typography fontWeight={"700"} fontSize={".7rem"}>
            Trang chủ
          </Typography>
        </li>
        <li
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
            color: activeIndex === 1 ? "blue" : "#393E40",
            transition: "transform 0.1s ease-in-out",
            transform: activeIndex === 1 ? "scale(1.1)" : "scale(1)",
          }}
          onClick={() => {
            handleItemClick(1);
            nav("/product");
          }}
        >
          <img
            style={{
              filter:
                activeIndex === 1
                  ? "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)"
                  : "none",
            }}
            src={Product}
            width={25}
            height={25}
          ></img>
          <Typography fontWeight={"700"} fontSize={".7rem"}>
            Sản phẩm
          </Typography>
        </li>
        <li
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
            color: activeIndex === 2 ? "blue" : "#393E40",
            transition: "transform 0.1s ease-in-out",
            transform: activeIndex === 2 ? "scale(1.1)" : "scale(1)",
          }}
          onClick={() => {
            handleItemClick(2);
            nav("/list-order");
          }}
        >
          <img
            src={Order}
            style={{
              filter:
                activeIndex === 2
                  ? "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)"
                  : "none",
            }}
            width={25}
            height={25}
          ></img>
          <Typography fontWeight={"700"} fontSize={".7rem"}>
            Đơn hàng
          </Typography>
        </li>
        <li
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
            color: activeIndex === 3 ? "blue" : "#393E40",
            transition: "transform 0.1s ease-in-out",
            transform: activeIndex === 3 ? "scale(1.1)" : "scale(1)",
          }}
          onClick={() => {
            handleItemClick(3);
            nav("/customer");
          }}
        >
          <img
            style={{
              filter:
                activeIndex === 3
                  ? "brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7490%) hue-rotate(235deg)"
                  : "none",
            }}
            src={Customer}
            width={25}
            height={25}
          ></img>
          <Typography fontWeight={"700"} fontSize={".7rem"}>
            Khách hàng
          </Typography>
        </li>

        <li
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignItems: "center",
            color: activeIndex === 4 ? "blue" : "#393E40",
            transition: "transform 0.1s ease-in-out",
            transform: activeIndex === 4 ? "scale(1.1)" : "scale(1)",
          }}
          onClick={() => {
            handleItemClick(4);
            nav("/infor");
          }}
        >
          <Avatar
            src={stateAccount?.avatar ? stateAccount?.avatar : Account}
            sx={{
              width: 30,
              height: 30,
              borderWidth: "1px",
              borderColor: activeIndex === 4 ? "blue" : "",
            }}
          ></Avatar>{" "}
          <Typography fontWeight={"700"} fontSize={".7rem"}>
            Cá nhân
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
export default FooterNav;
