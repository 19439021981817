import * as React from "react";

import NavbarTrigger from "../../pages/productDetails/Components/Navbar";
import { Button } from "@mui/material";

const Navbar = ({
  onOrderedQuant,
  onReset,
  stateProduct,
  stateCart,
  sendDataToBase,

  activeIndex,
}) => {
  return (
    <>
      <NavbarTrigger
        onOrderedQuant={onOrderedQuant}
        onReset={onReset}
        stateProduct={stateProduct}
        stateCart={stateCart}
        sendDataToBase={sendDataToBase}
        activeIndex={activeIndex}
      />
    </>
  );
};
export default Navbar;
