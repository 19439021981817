import { React, useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import BACKGROUNDLOGO from "../../assets/image/logo1.png";
import {
  FormBase,
  FooterBase,
  ButtonSignIn,
  Container,
  AppContainer,
} from "./Component/Component";
import styled from "styled-components";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { HandleLogin } from "../../services/handleAccount/handleAccount";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const AccountBox = (props) => {
  let nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [FormLogin, setFormLogin] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [DataError, setDataError] = useState({});
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const MutationLogin = useMutation({
    mutationFn: (body) => {
      return HandleLogin(body);
    },
  });
  const onChange = (e) => {
    setFormLogin({
      ...FormLogin,
      [e.target.name]: e.target.value,
    });
  };
  const sLogin = () => {
    setIsloading(true);
    MutationLogin.mutate(FormLogin, {
      onSuccess: (data) => {
        if (data?.errors) {
          setDataError(data);
          setIsloading(false);
        } else {
          const decodedToken = jwtDecode(data?.data?.data?.access_token);

          if (
            decodedToken?.roles != parseInt(process.env.REACT_APP_ROLE_SELLER)
          ) {
            setDataError({
              errors: {
                email: {
                  msg: "Tài khoản không hợp lệ",
                },
              },
            });
            setIsloading(false);
          } else {
            if (
              decodedToken?.verify !==
              parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED)
            ) {
              setDataError({
                errors: {
                  email: {
                    msg: "Tài khoản chưa được xác thực",
                  },
                },
              });
              setIsloading(false);
            } else {
              const decodedToken = jwtDecode(data?.data?.data?.access_token);
              localStorage.setItem("token", data?.data?.data?.access_token);
              localStorage.setItem("id", decodedToken?.user_id);
              nav("/product");
            }
          }
          // nav("/product");
        }
      },
      onError: (err) => {
        console.log("lỗi " + err);
        setIsloading(false);
      },
    });
  };

  return (
    <>
      <AppContainer>
        <FormBase>
          <Box sx={{ backgroundColor: "white" }}>
            <Container style={{ display: "grid", justifyContent: "center" }}>
              <Box>
                {" "}
                <img
                  style={{
                    margin: "auto",
                    paddingTop: "1.5rem",
                    paddingBottom: ".5rem",
                  }}
                  src={BACKGROUNDLOGO}
                ></img>
                <Typography color={"white"} textAlign={"center"}>
                  TIKPII Business
                </Typography>
                <Typography color={"white"} textAlign={"center"}>
                  Nền tảng hỗ trợ Kinh Doanh Online
                </Typography>
              </Box>
            </Container>
            <Box
              // className="AddpadingLeft"
              mt={4}
              sx={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                width: "100%",
                height: "62%",
              }}
            >
              <Box mb={2} display={"flex"}>
                <Typography
                  color={"#393E40"}
                  fontWeight={"700"}
                  fontSize={"25px"}
                  margin={"auto"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    nav("/sign-up");
                  }}
                >
                  Đăng nhập tài khoản
                </Typography>
              </Box>
              <form>
                <Box class="form-group">
                  <TextField
                    type="email"
                    name="email"
                    value={FormLogin.email}
                    onChange={onChange}
                    sx={{
                      marginTop: "1rem",
                      "& .MuiInputBase-root": {
                        height: "48px", // Điều chỉnh độ cao của ô input
                        borderRadius: "8px", // Điều chỉnh border-radius
                      },
                    }}
                    placeholder="Tên đăng nhập"
                    fullWidth
                  />
                </Box>
                <Box class="form-group">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={FormLogin.password}
                    onChange={onChange}
                    sx={{
                      marginTop: "1rem",
                      "& .MuiInputBase-root": {
                        height: "48px", // Điều chỉnh độ cao của ô input
                        borderRadius: "8px", // Điều chỉnh border-radius
                      },
                    }}
                    placeholder="Mật khẩu"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </form>
              {DataError && DataError.errors?.email?.msg ? (
                <Alert style={{ marginTop: "1rem" }} severity="error">
                  {DataError.errors?.email?.msg}
                </Alert>
              ) : (
                ""
              )}
              {DataError && DataError.errors?.password?.msg ? (
                <Alert style={{ marginTop: "1rem" }} severity="error">
                  {DataError.errors?.password?.msg}
                </Alert>
              ) : (
                ""
              )}

              <Box mt={2} display={"flex"} justifyContent={"end"}>
                <Typography fontWeight={"500"} color={"#393E40"}>
                  Quên mật khẩu ?
                </Typography>
              </Box>
              <Box mt={2} display={"flex"} justifyContent={"end"}>
                {isLoading ? (
                  <ButtonSignIn disabled={isLoading}>
                    {" "}
                    <CircularProgress
                      sx={{ fontSize: "10px" }}
                    ></CircularProgress>
                  </ButtonSignIn>
                ) : (
                  <ButtonSignIn onClick={sLogin}>Đăng nhập</ButtonSignIn>
                )}
              </Box>
              <Box mt={2} display={"grid"} justifyContent={"center"}>
                <Typography
                  variant={"subtitle2"}
                  color={"#5C6366"}
                  textAlign={"center"}
                >
                  hoặc
                </Typography>

                <Typography
                  mt={1}
                  fontSize={"14px"}
                  fontWeight={"600"}
                  color={"#1B3FE4"}
                >
                  Đăng nhập bằng số điện thoại
                </Typography>
              </Box>
            </Box>
          </Box>
          <FooterBase></FooterBase>
        </FormBase>
      </AppContainer>
    </>
  );
};

export default AccountBox;
