import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Back from "../../component/Back";
import { CaseDatetimeToYYYYMMDD } from "../../component/function";
import { styleModal } from "./components/componets";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { UPDATE_INFOR } from "../../services/handleInfor/handleInfor";
import { useMutation } from "@tanstack/react-query";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField";

const InforView = ({ stateAccount, handleTrggerRefreshAccount }) => {
  // Chuyển đổi chuỗi thành dayjs

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [IsloadingChangeInfor, setIsloadingChangeInfor] = useState(false);
  const [stateInfor, setStateInfor] = useState(stateAccount || {});
  const [dateF, onChangeF] = useState(dayjs(stateAccount?.date_of_birth));
  const handleChangeState = (e) => {
    setStateInfor({
      ...stateInfor,
      [e.target.name]: e.target.value,
    });
    onChangeF(stateInfor?.date_of_birth);
  };
  const HANDLE_CHANGE_INFOR = useMutation({
    mutationFn: (body) => {
      return UPDATE_INFOR(body);
    },
  });
  const handleFormSubmit = () => {
    setIsloadingChangeInfor(true);
    let FormUpdate = {
      ...stateInfor,
      date_of_birth: dateF,
    };
    HANDLE_CHANGE_INFOR.mutate(FormUpdate, {
      onSuccess: (data) => {
        setIsloadingChangeInfor(false);
        handleTrggerRefreshAccount();
        alert("Thay đổi thông tin thành công !!");
      },
      onError: (err) => {
        console.log("err", err);
        setIsloadingChangeInfor(false);
      },
    });
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Back content={"Thông tin cá nhân"}></Back>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={2}
          p={"16px"}
          borderBottom={"solid 5px #eeedee"}
        >
          <Box
            color={"#000000"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"14px"} fontWeight={600}>
              Họ và tên
            </Typography>
            <Typography color={"#5C6366"} fontSize={"14px"} fontWeight={600}>
              {stateAccount?.name}
            </Typography>
          </Box>

          <Box
            color={"#000000"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"14px"} fontWeight={600}>
              Người giới thiệu
            </Typography>
            <Typography color={"#5C6366"} fontSize={"14px"} fontWeight={600}>
              {stateAccount?.referrer?.name}
            </Typography>
          </Box>

          <Box
            color={"#000000"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"14px"} fontWeight={600}>
              Email
            </Typography>
            <Typography color={"#5C6366"} fontSize={"14px"} fontWeight={600}>
              {stateAccount?.email}
            </Typography>
          </Box>
          <Box
            color={"#000000"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"14px"} fontWeight={600}>
              Số điện thoại
            </Typography>
            <Typography color={"#5C6366"} fontSize={"14px"} fontWeight={600}>
              {stateAccount?.phone}
            </Typography>
          </Box>

          <Box
            color={"#000000"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"14px"} fontWeight={600}>
              Ngày sinh
            </Typography>
            <Typography color={"#5C6366"} fontSize={"14px"} fontWeight={600}>
              {CaseDatetimeToYYYYMMDD(stateAccount?.date_of_birth)}
            </Typography>
          </Box>

          <Box
            color={"#000000"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={"14px"} fontWeight={600}>
              Ngày Khởi tạo
            </Typography>
            <Typography color={"#5C6366"} fontSize={"14px"} fontWeight={600}>
              {CaseDatetimeToYYYYMMDD(stateAccount?.created_at)}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            onClick={handleOpen}
            color="primary"
            sx={{ fontSize: "16px" }}
          >
            Sửa thông tin
          </Button>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
          p={"16px"}
          borderBottom={"solid 5px #eeedee"}
        >
          <Typography fontWeight={600} color={"#000000"} fontSize={"18px"}>
            Gói thành viên
          </Typography>
          <hr></hr>

          <Box
            color={"#000000"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={1}
          >
            {stateAccount?.upgrade ? (
              <>
                <Box
                  color={"#000000"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <Typography color={"#5C6366"} fontWeight={400}>
                    Tên gói : {stateAccount?.upgrade?.package?.name}
                  </Typography>
                  <Typography color={"#5C6366"} fontWeight={400}>
                    Hạn sử dụng còn {stateAccount?.upgrade?.days_remaining} ngày
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box
                  color={"#000000"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <Typography color={"#5C6366"} fontWeight={400}>
                    Tài khoản chưa nâng cấp
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ fontSize: "16px" }}
                  >
                    Nâng cấp
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Typography mb={3} variant="h5" fontWeight={700}>
              Điều chỉnh thông tin cá nhân
            </Typography>
            <div class="form-group">
              <TextField
                fullWidth
                sx={{ marginTop: "2rem" }}
                id="standard-basic"
                onChange={handleChangeState}
                value={stateInfor?.name || stateAccount?.name}
                name="name"
                label="Họ và tên"
                variant="standard"
              />
              {/* <Box
                display={"flex"}
                sx={{ marginTop: "2rem" }}
                justifyContent={"space-between"}
              >
                {" "}
                <label
                  style={{ width: "200px", color: "#b9b9b9", display: "block" }}
                >
                  Ngày sinh
                </label>
                <DatePicker
                  clearIcon={null}
                  format="y-MM-dd"
                  name="date_of_birth"
                  onChange={(e) => {
                    let year = JSON.stringify(e)
                      .replace('"', "")
                      .split("T")[0]
                      .split("-")[0];
                    let Month = JSON.stringify(e)
                      .replace('"', "")
                      .split("T")[0]
                      .split("-")[1];
                    let day = parseInt(
                      parseInt(
                        JSON.stringify(e)
                          .replace('"', "")
                          .split("T")[0]
                          .split("-")[2]
                      ) + 1
                    )
                      .toString()
                      .padStart(2, "0");

                    let Formdate = year + "-" + Month + "-" + day;

                    onChangeF(Formdate);
                  }}
                  value={dateF || stateAccount?.date_of_birth}
                />
              </Box> */}
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ width: "100%" }}
                    components={["DateField"]}
                  >
                    <DateField
                      fullWidth
                      onChange={(e) => {
                        onChangeF(e);
                      }}
                      value={dayjs(dateF)}
                      label="Ngày tháng năm sinh (MM-DD-YYYY)"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

              <TextField
                fullWidth
                sx={{ marginTop: "2rem" }}
                type="text"
                label="Địa chỉ"
                onChange={handleChangeState}
                name="address"
                value={stateInfor?.address || stateAccount?.address}
                variant="standard"
              />
            </div>

            <Box width={"100%"}>
              {IsloadingChangeInfor ? (
                <Button
                  color="secondary"
                  sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                  variant="contained"
                >
                  <CircularProgress></CircularProgress>
                </Button>
              ) : (
                <Button
                  color="info"
                  sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                  variant="contained"
                  onClick={handleFormSubmit}
                >
                  Điều Chỉnh Thông Tin
                </Button>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default InforView;
