import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  GET_ALL_PACKAGE,
  SELLER_UPGRADE_ACCOUNT,
} from "../../services/handleInfor/handleInfor";
import { useQuery, useMutation } from "@tanstack/react-query";
import Back from "../../component/Back";
import { useState } from "react";
import { SpanLable } from "../Cart/components/components";
import CircularProgress from "@mui/material";
import { ThongBaoSuccess, ThongBaoFails } from "../../component/component";
import { Store } from "react-notifications-component";
import { useNavigate } from "react-router-dom";
import IMGUPG1 from "../../assets/svg/icon_upgrade.svg";

const InforUpgrade = ({ stateAccount, handleTrggerRefreshAccount }) => {
  let nav = useNavigate();
  const [selected, setSelected] = useState(null);
  const [statePackage, setStatePackage] = useState([]);
  const [stateChosse, setStateChosse] = useState([]);
  const [Isloading, setIsloading] = useState(false);
  const DATA_ALL_PACKAGE = useQuery({
    queryKey: ["ALL_PACKAGE"],
    queryFn: async () => {
      const rs = await GET_ALL_PACKAGE();
      if (rs?.data?.data) {
        setStatePackage(rs?.data?.data);
      }
      return rs;
    },
  });

  const ContentUpgrade = ({ name }) => {
    return (
      <Box margin={"auto"} display={"flex"} height={"100px"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          margin={"auto"}
          gap={1}
        >
          {" "}
          <img
            src={IMGUPG1}
            style={{ width: "100px", height: "50px", mixBlendMode: "multiply" }}
          ></img>
          <Typography fontSize={"14px"} fontWeight={700}>
            {name}
          </Typography>
        </Box>
      </Box>
    );
  };
  const HANDLE_UPGRADE = useMutation({
    mutationFn: (data) => {
      return SELLER_UPGRADE_ACCOUNT(data);
    },
  });
  const handleUpgrade = () => {
    setIsloading(true);
    if (stateChosse.length === 0) {
      alert("Vui lòng chọn gói nâng cấp");
      setIsloading(false);
    } else {
      let FormCreate = {
        package_id: stateChosse[0]?._id,
      };
      HANDLE_UPGRADE.mutate(FormCreate, {
        onSuccess: (data) => {
          if (data?.data) {
            handleTrggerRefreshAccount();
            setIsloading(false);
            Store.addNotification({
              ...ThongBaoSuccess({
                title: "Đã gửi yêu cầu thành công",
                message: "Vui lòng kiểm tra lại, và xác nhận thanh toán",
              }),
              container: "top-right",
            });
            nav("/infor/list-upgrade");
          } else {
            Store.addNotification({
              ...ThongBaoFails({
                title: "Gửi yêu cầu thất bại",
                message: "Bạn đã gửi yêu cầu gói này rồi !",
              }),
              container: "top-right",
            });
          }
        },
        onError: (err) => {
          alert("Vui lòng thử lại !!");
          setIsloading(false);
        },
      });
    }
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Back content={"Nâng cấp tài khoản"}></Back>

        {/* ///Tính năng này chưa mở ( tính năng nâng cấp) */}

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          p={2}
          height={"85vh"}
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          <FormControl>
            <FormLabel
              fontWeight="500"
              id="demo-radio-buttons-group-label"
              sx={{ color: "black", fontWeight: "600" }}
            >
              {" "}
              Danh sách gói nâng cấp
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="NH"
              name="radio-buttons-group"
              onChange={(e) => {
                const filter =
                  statePackage &&
                  statePackage.filter((item, index) => {
                    return item?._id === e.target.value;
                  });
                setStateChosse(filter);
              }}
            >
              {DATA_ALL_PACKAGE.isFetching ? (
                <Typography color={"black"}>
                  Đang tải dữ liệu vui lòng chờ ....{" "}
                </Typography>
              ) : (
                <>
                  {" "}
                  {statePackage &&
                    statePackage.map((item, index) => (
                      <FormControlLabel
                        key={item?._id}
                        style={{
                          backgroundColor:
                            selected === item?._id ? "#b93ff9" : "orange",
                          display: "flex",
                          justifyContent: "center", // Canh giữa theo chiều ngang
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                        value={item?._id}
                        control={
                          <Radio
                            checked={selected === item?._id}
                            sx={{ display: "none" }}
                            onChange={(e) => {
                              setSelected(e.target.value);
                            }}
                          />
                        }
                        label={
                          <ContentUpgrade name={item?.name}></ContentUpgrade>
                        }
                      />
                    ))}
                </>
              )}
            </RadioGroup>
          </FormControl>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"column"}
            gap={1}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontSize: "16px" }}
              onClick={handleUpgrade}
            >
              Nâng cấp
            </Button>

            <Button
              variant="contained"
              color="secondary"
              sx={{ fontSize: "16px" }}
              onClick={() => {
                nav("/infor/list-upgrade");
              }}
            >
              Danh sách gửi yêu cầu
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default InforUpgrade;
