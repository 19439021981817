import api from "../common/apis";
import NProgress from "nprogress";
export const GET_ALL_PURCHASE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/search/purchases-of-seller`, {
      params: {
        sort: req.sort,
        status: req.status,
        page: req.page,
        limit: req.limit,
        date: req.date,
        code_purchase: req.code_purchase,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_PURCHASE_SEARCH = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/search/purchases`, {
      params: {
        page: req.page,
        limit: req.limit,
        code_purchase: req.date,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ONE_PURCHASE = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/purchases/get-purchase/${req.id}`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const PURCHASE_CANCEL = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/sellers/purchases/cancel-order`, {
      purchase_id: req.purchase_id,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
