import api from "../common/apis";
import NProgress from "nprogress";
export const GET_ALL_REVIEW_OF_PRODUCT = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/products/get-reviews/${req.id}`, {
      params: {
        page: req.page,
        limit: req.limit,
        date: req.date,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const ADD_REVIEW_OF_PRODUCT = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/sellers/reviews/add-review`, {
      product_id: req.product_id,
      purchase_id: req.purchase_id,
      rating: req.rating,
      comment: req.comment,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
