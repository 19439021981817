// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lihover:hover {
    background-color: aliceblue;
}

.headerNav {
    cursor: pointer;
    position: relative;
  }
  
  .headerNav::after {
    content: '';
    position: absolute;
    top: 2;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: red;
    transition: width 0.3s ease;
  }
  
  .active::after {
    width: 200px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Infor/style.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;IACR,WAAW;IACX,qBAAqB;IACrB,2BAA2B;EAC7B;;EAEA;IACE,YAAY;EACd","sourcesContent":[".lihover:hover {\r\n    background-color: aliceblue;\r\n}\r\n\r\n.headerNav {\r\n    cursor: pointer;\r\n    position: relative;\r\n  }\r\n  \r\n  .headerNav::after {\r\n    content: '';\r\n    position: absolute;\r\n    top: 2;\r\n    left: 0;\r\n    bottom: 0;\r\n    width: 0;\r\n    height: 2px;\r\n    background-color: red;\r\n    transition: width 0.3s ease;\r\n  }\r\n  \r\n  .active::after {\r\n    width: 200px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
