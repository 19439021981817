import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Back from "../../component/Back";
import { CaseDatetimeToYYYYMMDD } from "../../component/function";

import { styleModal } from "./components/componets";
import { useState } from "react";
import { GET_INFOR_BANK } from "../../services/publicApi/handleBank";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  CREATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from "../../services/handleInfor/handleInfor";
import CircularProgress from "@mui/material";
const InforBank = ({ stateAccount, handleTrggerRefreshAccount }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const [stateBank, setStateBank] = useState([]);

  const [stateError, setStateError] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [stateEdit, setStateEdit] = useState({});
  const [stateFormCreate, setStateFormCreate] = useState({
    bank_name: "",
    account_number: 0,
    account_name: "",
  });

  const HANDLE_CREATE_BANK = useMutation({
    mutationFn: (body) => {
      return CREATE_BANK_ACCOUNT(body);
    },
  });
  const HANDLE_EDIT_BANK = useMutation({
    mutationFn: (body) => {
      return UPDATE_BANK_ACCOUNT(body);
    },
  });
  const onChange = (e) => {
    setStateFormCreate({
      ...stateFormCreate,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeEdit = (e) => {
    setStateEdit({
      ...stateEdit,
      [e.target.name]: e.target.value,
    });
  };
  const TriggerData = () => {
    handleTrggerRefreshAccount();
  };
  const handleChangeInput = (event, newInputValue) => {
    setStateEdit({
      ...stateEdit,
      bank_name: newInputValue,
    });
  };
  const createBank = () => {
    setIsloading(true);
    if (validate()) {
      HANDLE_CREATE_BANK.mutate(stateFormCreate, {
        onSuccess: (data) => {
          if (data?.errors) {
            const error = {};
            error.account_number = "Tài khoản ngân hàng không hợp lệ!";
            setStateError(error);
          } else {
            alert("Đã thêm thành công");
            setOpen(false);
            TriggerData();
          }
          setIsloading(false);
        },
        onError: (err) => {
          console.log("err " + err);
          setIsloading(false);
        },
      });
    } else {
      setIsloading(false);
    }
  };
  const EditBank = () => {
    setIsloading(true);
    if (validateEdit()) {
      HANDLE_EDIT_BANK.mutate(stateEdit, {
        onSuccess: (data) => {
          if (!data?.errors) {
            alert("Đã thay đổi thành công");
            TriggerData();
          } else {
            alert("Vui lòng thử lại");
          }
          setOpenEdit(false);
          setIsloading(false);
        },
        onError: (err) => {
          console.log("err " + err);
          setIsloading(false);
        },
      });
    } else {
      setIsloading(false);
    }
  };
  const DATA_BANK_NAME = useQuery({
    queryKey: ["GET_BANK_NAME"],
    queryFn: async () => {
      const rs = await GET_INFOR_BANK();
      if (rs?.data?.data) {
        const arrayWithId = rs?.data?.data.map((obj, index) => {
          return { ...obj, label: obj.short_name };
        });
        setStateBank(arrayWithId);
      }
      return rs;
    },
  });
  const validate = () => {
    const newErrors = {};
    if (!stateFormCreate.bank_name) {
      newErrors.bank_name = "Vui lòng không bỏ trống thông tin !!";
    }
    if (!stateFormCreate.account_number) {
      newErrors.account_number = "Vui lòng không bỏ trống thông tin !!";
    }

    if (!stateFormCreate.account_name) {
      newErrors.account_name = "Vui lòng không bỏ trống thông tin !!";
    }
    setStateError(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validateEdit = () => {
    const newErrors = {};
    if (!stateEdit.bank_name) {
      newErrors.bank_name = "Vui lòng không bỏ trống thông tin !!";
    }
    if (!stateEdit.account_number) {
      newErrors.account_number = "Vui lòng không bỏ trống thông tin  !!";
    }

    if (!stateEdit.account_name) {
      newErrors.account_name = "Vui lòng không bỏ trống thông tin !!";
    }
    setStateError(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Back content={"Thông tin ngân hàng"}></Back>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          p={"16px"}
          borderBottom={"solid 5px #eeedee"}
        >
          <Box
            color={"#000000"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={3}
          >
            {stateAccount?.bank_info ? (
              <>
                {" "}
                <Box
                  color={"#000000"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography fontSize={"14px"} fontWeight={600}>
                    Ngân hàng
                  </Typography>
                  <Typography
                    color={"#5C6366"}
                    fontSize={"14px"}
                    fontWeight={600}
                  >
                    {stateAccount?.bank_info?.bank_name}
                  </Typography>{" "}
                </Box>
                <Box
                  color={"#000000"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography fontSize={"14px"} fontWeight={600}>
                    Số tài khoản
                  </Typography>
                  <Typography
                    color={"#5C6366"}
                    fontSize={"14px"}
                    fontWeight={600}
                  >
                    {stateAccount?.bank_info?.account_number}
                  </Typography>{" "}
                </Box>
                <Box
                  color={"#000000"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography fontSize={"14px"} fontWeight={600}>
                    Chủ tài khoản
                  </Typography>
                  <Typography
                    color={"#5C6366"}
                    fontSize={"14px"}
                    fontWeight={600}
                  >
                    {stateAccount?.bank_info?.account_name}
                  </Typography>{" "}
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: "16px" }}
                  onClick={() => {
                    setStateEdit(stateAccount?.bank_info);

                    handleOpenEdit();
                  }}
                >
                  Điều chỉnh
                </Button>
              </>
            ) : (
              <>
                <Box
                  color={"#000000"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <Typography color={"#5C6366"} fontWeight={400}>
                    Hiện tại chưa có thông tin ngân hàng
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ fontSize: "16px" }}
                    onClick={handleOpen}
                  >
                    Thiết lập
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {/* MODAL CREATE */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Typography mb={3} variant="h5" fontWeight={700}>
              Điền thông tin ngân hàng
            </Typography>
            <div class="form-group">
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                name="bank_name"
                options={stateBank}
                sx={{ marginBottom: "2rem" }}
                inputValue={stateFormCreate.bank_name}
                onInputChange={(event, newInputValue) => {
                  setStateFormCreate({
                    ...stateFormCreate,
                    bank_name: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={stateError?.bank_name ? true : false}
                    helperText={stateError?.bank_name}
                    fullWidth
                    label="Tên ngân hàng"
                    variant="standard"
                  />
                )}
              />
              <TextField
                fullWidth
                sx={{ marginBottom: "2rem" }}
                id="standard-basic"
                name="account_number"
                type="number"
                error={stateError?.account_number ? true : false}
                helperText={stateError?.account_number}
                value={stateFormCreate?.account_number}
                onChange={onChange}
                label="Số tài khoản"
                variant="standard"
              />
              <TextField
                fullWidth
                sx={{ marginBottom: "2rem" }}
                id="standard-basic"
                name="account_name"
                error={stateError?.account_name ? true : false}
                helperText={stateError?.account_name}
                value={stateFormCreate?.account_name}
                onChange={onChange}
                type="text"
                label="Chủ tài khoản"
                variant="standard"
              />
            </div>
            {}

            <Box width={"100%"}>
              {isLoading ? (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  class="btn btn-primary"
                >
                  Đang khởi tạo....
                </button>
              ) : (
                <Button onClick={createBank} variant="outlined" fullWidth>
                  Khởi tạo
                </Button>
              )}
            </Box>
          </Box>
        </Modal>
        {/* MODAL EDIT */}
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Typography mb={3} variant="h5" fontWeight={700}>
              Điều chỉnh thông tin ngân hàng
            </Typography>
            <div class="form-group">
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                name="bank_name"
                options={stateBank}
                inputValue={stateEdit?.bank_name}
                onInputChange={(e, input) => {
                  handleChangeInput(e, input);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tên ngân hàng"
                    variant="standard"
                  />
                )}
              />
              {stateError?.bank_name ? (
                <label style={{ color: "red" }}>
                  * Lỗi ngân hàng không được để trống
                </label>
              ) : (
                ""
              )}

              <TextField
                fullWidth
                sx={{ marginTop: "2rem", marginBottom: "2rem" }}
                id="standard-basic"
                name="account_number"
                type="number"
                error={stateError?.account_number ? true : false}
                helperText={stateError?.account_number}
                value={parseInt(stateEdit?.account_number)}
                onChange={onChangeEdit}
                label="Số tài khoản"
                variant="standard"
              />
              <TextField
                fullWidth
                sx={{ marginBottom: "2rem" }}
                id="standard-basic"
                name="account_name"
                error={stateError?.account_name ? true : false}
                helperText={stateError?.account_name}
                value={stateEdit?.account_name}
                onChange={onChangeEdit}
                type="text"
                label="Chủ tài khoản"
                variant="standard"
              />
            </div>

            <Box width={"100%"}>
              {isLoading ? (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  class="btn btn-primary"
                >
                  Đang xử lý ....
                </button>
              ) : (
                <Button onClick={EditBank} variant="outlined" fullWidth>
                  Xác nhận điều chỉnh
                </Button>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
export default InforBank;
