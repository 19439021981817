//LOCAL
// export const URL_BACKEND = "https://api-tikpii.click";
// export const URL_BACKEND = "http://localhost:5000";
// export const LINKAFFILIATEPRODUCT = "http://localhost:3000/#/pDetails";

//DEPLOY
// export const URL_BACKEND = "https://betikpii.onrender.com";
// export const LINKAFFILIATEPRODUCT = "https://tikpiimall.netlify.app";

//HOSTING
export const URL_BACKEND = "https://api-tikpii.click";

export const LINKAFFILIATEPRODUCT = "https://tikpii.vn/#/pDetails";
