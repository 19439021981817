import React from "react";
import plus from "../Pictures/icon-plus.svg";
import minus from "../Pictures/icon-minus.svg";

const QuantityButton = ({ IsSelectStore, onQuant, store, onRemove, onAdd }) => {
  return (
    <div className="amount">
      <button
        className="minus"
        onClick={() => {
          if (!IsSelectStore) {
            alert("Vui lòng chọn kho trước");
          } else {
            onRemove();
          }
        }}
        disabled={onQuant === 0}
      >
        <img src={minus} alt="icon-minus" />
      </button>
      <p>{onQuant}</p>
      <button
        className="plus"
        onClick={() => {
          if (!IsSelectStore) {
            alert("Vui lòng chọn kho trước");
          } else {
            onAdd();
          }
        }}
        disabled={onQuant === parseInt(store?.stock)}
      >
        <img src={plus} alt="icon-plus" />
      </button>
    </div>
  );
};

export default QuantityButton;
