import CartPC from "./CartPC";
import { Box } from "@mui/material";
import CartMB from "./CartMB";
import { GET_ALL_CUSTOMER } from "../../services/handleCustomer/handleCustomer";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
const Cart = ({ sendDataToBase }) => {
  const [stateCustomer, setStateCustomer] = useState({});
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(5);
  const [stateSort, setStateSort] = useState("asc");
  const [stateDate, setStateDate] = useState("latest");
  const [stateStatus, setStateStatus] = useState("");
  const [stateName, setStateName] = useState("")

  const [stateCount, setStateCount] = useState(0)
  const getAll_Customer = useQuery({
    queryKey: ["get_all_customer", statePage, stateName],
    queryFn: async () => {
      const result = await GET_ALL_CUSTOMER({
        page: statePage,
        limit: stateLimit,
        sort: stateSort,
        date: stateDate,
        status: stateStatus,
        name: stateName
      });
      if (result?.data?.data) {
        setStateCustomer(result?.data?.data);
        setStateCount(result?.data?.data?.total_page)
      }
      return result; // Ensure the result is returned
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const TriggerRefeshData = () => {
    getAll_Customer.refetch();
  };
  return (
    <>
      <CartMB
        stateCustomer={stateCustomer?.customers || []}
        sendDataToBase={sendDataToBase}
        TriggerRefeshData={TriggerRefeshData}
        stateCount={stateCount}
        statePage={statePage}
        setStatePage={setStatePage}
        isloadingCustomer={getAll_Customer.isFetching}
        stateName={stateName}
        setStateName={setStateName}
      ></CartMB>
    </>
  );
};

export default Cart;
