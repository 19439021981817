import { Box, Pagination, Rating, Skeleton } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";

import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgress } from "@mui/material";
import {
  MenuButton,
  MenuItem,
  Listbox,
  StyledRating,
} from "./Components/Components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import "./App.css";
import { TitleProduct as TitleComponent } from "../../component/Styles/Title";
import { PriceText } from "../../component/Styles/PriceText";
import AddIcon from "@mui/icons-material/Add";
import {
  GET_ALL_PRODUCT,
  GET_ALL_PROVIDER,
} from "../../services/handleProduct/handleProduct";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DATAIMG_PANEL_PRODUCT } from "../../data/data";
import { Carousel } from "react-responsive-carousel";
import { notification } from "./Components/Components";
import { Store } from "react-notifications-component";
import { CREATE_CART } from "../../services/handleCart/handleCart";
import { GET_ALL_CATEGORY } from "../../services/handleApiPublic/handleApiPublic";
import NavbarSecond from "../../component/NavBarSecond/navbarSecond";
const Product = ({
  Isloading = false,
  handleTrggerRefreshAccount,
  sendDataToBase,
  stateCart,
  stateAccount,
  activeIndex,
  setShowInfor,
  showInfor,
  stateData,
  IsDaiLy,
}) => {
  const [stateProvider, setStateProvider] = React.useState([]);

  const [TriggerLoad, setTriggerLoad] = React.useState(false);
  const [viewMore, setViewMore] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [stateCategory, setStateCategory] = useState([]);
  const [stateCount, setStateCount] = React.useState(0);

  const [stateProduct, setStateProduct] = useState([]);
  const DataAllCategory = useQuery({
    queryKey: ["GetAllCategory"],
    queryFn: async () => {
      const result = await GET_ALL_CATEGORY();
      if (result?.data?.data) {
        setStateCategory(result?.data?.data);
      }
      return result;
    },
  });
  const DataAllProduct = useQuery({
    queryKey: ["GetAllProduct", viewMore],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT({
        page: viewMore,
        limit: limit,
      });

      if (result?.data?.data) {
        let newArrayInsertID = result?.data?.data?.products.map(
          (item, index) => {
            return {
              ...item,
              id: item?._id,
            };
          }
        );

        const MergeProduct = stateProduct.concat(newArrayInsertID);

        setStateCount(result?.data?.data?.total_page);
        setStateProduct(
          TriggerLoad
            ? MergeProduct
            : stateProduct.length > 0
            ? stateProduct
            : newArrayInsertID
        );
        setTriggerLoad(false);
      }
      return result; // Ensure the result is returned
    },
  });

  const DataAllProvider = useQuery({
    queryKey: ["GetAllProvider"],
    queryFn: async () => {
      const result = await GET_ALL_PROVIDER();
      if (result?.data?.data) {
        setStateProvider(result?.data?.data);
      }
      return result; // Ensure the result is returned
    },
  });
  const HANDLE_CREATE_CART = useMutation({
    mutationFn: (body) => {
      return CREATE_CART(body);
    },
  });
  const handleCreateCart = (item) => {
    let FormCreate = {
      purchase_items: [
        {
          product_id: item?._id,
          buy_count: 1,
          store_id: item?.store_company?.id,
        },
      ],
    };

    HANDLE_CREATE_CART.mutate(FormCreate, {
      onSuccess: (data) => {
        sendDataToBase();
        Store.addNotification({
          ...notification,
          container: "bottom-center",
        });
      },
      onError: (err) => {
        console.log("on Err " + err);
      },
    });
  };
  let nav = useNavigate();
  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };
  const divs = Array.from({ length: 20 }, (_, i) => i + 1);

  const AnimatedListbox = React.forwardRef(function AnimatedListbox(
    props,
    ref
  ) {
    const { ownerState, ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
      throw new Error(
        "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
      );
    }

    const verticalPlacement = popupContext.placement.split("-")[0];

    return (
      <CssTransition
        className={`placement-${verticalPlacement}`}
        enterClassName="open"
        exitClassName="closed"
      >
        <Listbox {...other} ref={ref} />
      </CssTransition>
    );
  });

  AnimatedListbox.propTypes = {
    ownerState: PropTypes.object.isRequired,
  };

  return (
    <>
      <NavbarSecond
        stateAccount={stateAccount}
        stateCart={stateCart}
        className="AddpadingForNavS"
        IsContactSearch={true}
      ></NavbarSecond>
      <Box sx={{ width: "100%" }}>
        <div className="col-12 ">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            showThumbs={false}
          >
            {DATAIMG_PANEL_PRODUCT &&
              DATAIMG_PANEL_PRODUCT.map((item, index) => (
                <div>
                  <img className="bgtitle" src={item}></img>
                  {/* <p className="legend">Legend 1</p> */}
                </div>
              ))}
          </Carousel>
        </div>

        <Box
          className="AddpadingLeftProduct"
          sx={{
            width: "100%",
            height: "100%",
            paddingLeft: "5px",
            paddingRight: "5px",
            backgroundColor: "white",
          }}
        >
          <div style={{ marginTop: "2rem" }} class="col-12 mb-2">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h7" fontWeight={700}>
                Các nhãn hàng
              </Typography>
            </Box>
          </div>
          <div
            style={{
              overflowX: "scroll",
              display: "flex",

              width: "100%",

              justifyContent: "flex-start",
            }}
            class="col-12"
          >
            {stateProvider &&
              stateProvider.map((item, index) => (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  p={2}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    nav(`/providerDetails/${item?._id}`, { state: item });
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={item?.avatar}
                    sx={{ width: 70, height: 70, borderWidth: "1px" }}
                  />
                  <Typography
                    textAlign={"center"}
                    fontSize={"12px"}
                    fontWeight={600}
                    whiteSpace={"nowrap"}
                    width={"70px"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              ))}
          </div>

          <Box
            className="col-12"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h7" fontWeight={700}>
              Dành cho bạn
            </Typography>
            <FormControlLabel
              sx={{
                display: "block",
                fontSize: "10px",
                color: "black",
                fontWeight: "700",
                textAlign: "center",
              }}
              control={
                <Switch checked={showInfor} name="loading" color="primary" />
              }
              onClick={() => {
                setShowInfor(!showInfor);
              }}
              label="Hiển thị thông tin"
            />
          </Box>

          <div
            style={{ overflowX: "scroll", paddingBottom: "10px" }}
            class="col-12"
          >
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              {stateCategory &&
                stateCategory.map((item, index) => (
                  <>
                    <Box
                      width={"120px"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        nav(`/categories/${item?._id}`, { state: item });
                      }}
                    >
                      <img
                        style={{
                          padding: "10px",
                          borderWidth: "1px",
                          borderRadius: "10px",
                          objectFit: "contain",
                          width: "77px",
                          height: "77px",
                        }}
                        src={item?.image}
                      ></img>

                      <Box
                        textAlign={"center"}
                        sx={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "#393E40",
                        }}
                      >
                        {item?.name}
                      </Box>
                    </Box>
                  </>
                ))}
            </ButtonGroup>
          </div>
          <div
            style={{
              overflowX: "scroll",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
            class="col-12"
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction="row" spacing={1}>
                <Chip label="Liên quan" variant="outlined" />

                <Chip label="Mới nhất" variant="outlined" />

                <Chip label="Bán chạy" variant="outlined" />

                <Chip label="Lợi nhuận cao" variant="outlined" />

                <Chip label="Điểm cao nhất" variant="outlined" />
              </Stack>
            </Box>
          </div>
        </Box>
        <Box className="AddpadingLeftProduct">
          <Box mt={2}>
            <Box sx={{ flexGrow: 1 }}>
              {DataAllProduct.isFetching ? (
                <>
                  <Box mt={2} className="row">
                    <Box className="col-6">
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"200px"}
                      />
                      <Box mt={2}>
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>
                    </Box>
                    <Box className="col-6">
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"200px"}
                      />
                      <Box mt={2}>
                        <Skeleton />
                        <Skeleton width="20%" />
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={2} className="row">
                    <Box className="col-6">
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"200px"}
                      />
                      <Box mt={2}>
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>
                    </Box>
                    <Box className="col-6">
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"200px"}
                      />
                      <Box mt={2}>
                        <Skeleton />
                        <Skeleton width="20%" />
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  container
                >
                  {stateProduct &&
                    stateProduct.map((item, index) => (
                      <Grid
                        sx={{
                          width: "50%",
                          padding: "10px",
                          borderColor: "#f3f3f3",
                          borderWidth: ".5px",
                        }}
                        display={"flex"}
                        key={index}
                      >
                        <Box
                          style={{
                            position: "relative",
                            margin: "auto",
                            marginTop: "10px",
                            cursor: "pointer",
                            height: "100%",
                            minWidth: "100%",
                            maxWidth: "100%%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,

                              borderRadius: "0 0 10px 0",
                            }}
                          >
                            <Avatar
                              sx={{ width: 25, height: 25 }}
                              src={item?.provider?.avatar}
                            ></Avatar>
                          </div>

                          <CardMedia
                            component="img"
                            alt="green iguana"
                            sx={{ height: "140px" }}
                            image={item?.images[0]?.path ?? ""}
                            onClick={() => {
                              nav(`/pDetails/${item?.id}`);
                            }}
                          />
                          <CardContent sx={{ padding: "8px" }}>
                            <TitleComponent style={{ fontSize: "14px" }}>
                              {item?.name || ""}
                            </TitleComponent>
                            {showInfor ? (
                              <>
                                <Typography
                                  mb={1}
                                  fontSize="12px"
                                  color="#747B7E"
                                >
                                  Point:{" "}
                                  {parseInt(item?.point).toLocaleString(
                                    "en-us"
                                  ) || 0}
                                </Typography>

                                <Button
                                  variant="body2"
                                  sx={{
                                    padding: "5px",
                                    color: "#1B3FE4",
                                    borderRadius: ".5rem",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                    backgroundColor: "rgb(230 233 255/1)",
                                  }}
                                  className="text-primary"
                                >
                                  LN:{" "}
                                  {IsDaiLy
                                    ? parseInt(
                                        item?.profit_for_seller_and_collaborator ||
                                          0
                                      ).toLocaleString("en-US")
                                    : parseInt(
                                        item?.profit_for_collaborator || 0
                                      ).toLocaleString("en-US")}
                                  đ
                                </Button>
                              </>
                            ) : (
                              ""
                            )}

                            <Box display={"flex"} flexDirection={"column"}>
                              <Box sx={{ fontSize: "12px", color: "#747B7E" }}>
                                {item?.sales ? (
                                  <s style={{ fontSize: ".8rem" }}>
                                    đ{" "}
                                    {parseInt(
                                      item?.price_retail || 0
                                    ).toLocaleString("en-us")}
                                    VND
                                  </s>
                                ) : (
                                  ""
                                )}
                              </Box>
                              <Box display={"flex"}>
                                <PriceText
                                  style={{
                                    fontSize: "14px",
                                    color: "#F79009",
                                    fontWeight: "700",
                                  }}
                                >
                                  đ
                                  {item?.price_for_customer.toLocaleString(
                                    "en-US"
                                  )}{" "}
                                </PriceText>
                              </Box>
                            </Box>
                            <Box
                              display={"flex"}
                              width={"100%"}
                              justifyContent={"space-between"}
                            >
                              <Box>
                                {" "}
                                <Box display={"flex"} alignItems={"center"}>
                                  <Rating
                                    name="half-rating-read"
                                    defaultValue={item?.rating}
                                    precision={0.5}
                                    readOnly
                                    sx={{
                                      fontSize: "15px",
                                      marginBottom: "5px",
                                    }}
                                  />{" "}
                                  <Typography ml={1} fontSize={".7rem"}>
                                    {"("} {item?.total_rating} {")"}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    fontSize={"12px"}
                                    color={"#5C6366"}
                                  >
                                    Đã bán :{item?.sold}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"end"}
                                width={"30px"}
                              >
                                {HANDLE_CREATE_CART?.isPending ? (
                                  <button
                                    className="btn"
                                    style={{
                                      width: "30px",
                                      backgroundColor: "gainsboro",
                                      height: "30px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* <AddIcon fontSize="small"></AddIcon> */}
                                    <CircularProgress></CircularProgress>
                                  </button>
                                ) : (
                                  <button
                                    className="btn"
                                    style={{
                                      width: "30px",
                                      backgroundColor: "gainsboro",
                                      height: "30px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      handleCreateCart(item);
                                    }}
                                  >
                                    <AddIcon fontSize="small"></AddIcon>
                                  </button>
                                )}
                              </Box>
                            </Box>
                          </CardContent>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              padding: "2rem",
            }}
          >
            {viewMore === stateCount ? (
              ""
            ) : (
              <Typography
                sx={{ margin: "auto", cursor: "pointer", fontWeight: 600 }}
                margin={"auto"}
                onClick={() => {
                  setViewMore(viewMore + 1);
                  setTriggerLoad(true);
                }}
              >
                {">>"} Xem thêm sản phẩm {"<<"}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Product;
