import React from "react";
import Product from "./Product";
import { useNavigate } from "react-router-dom";
import { CloseModal } from "../../../component/function";
const Cart = ({
  onOrderedQuant,
  onReset,
  onShow,
  stateProduct,
  stateCart,
  sendDataToBase,
}) => {
  let nav = useNavigate();
  const closeCart = () => {
    onShow(false);
  };
  return (
    <section className="cart">
      <div className="head">
        <p>Giỏ hàng</p>
        <CloseModal handleclose={closeCart}></CloseModal>
      </div>
      <hr />
      <div className="cart-content">
        {stateCart ? (
          <>
            <Product
              onOrderedQuant={onOrderedQuant}
              stateProduct={stateProduct}
              stateCart={stateCart}
              onReset={onReset}
              Trigger={sendDataToBase}
            />
            <button
              className="checkout"
              onClick={() => {
                nav("/cart");

                onShow(false);
              }}
            >
              Tạo đơn
            </button>
          </>
        ) : (
          <p className="empty">Your Cart Is Empty</p>
        )}
      </div>
    </section>
  );
};

export default Cart;
