import Navbar from "../Navbar/navbar";
import Footer from "../Footer/footer";
import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FooterNav from "../FooterNav/FooterNav";
import { jwtDecode } from "jwt-decode";
import { GET_CART } from "../../services/handleCart/handleCart";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReactNotifications } from "react-notifications-component";
import NavbarSecond from "../NavBarSecond/navbarSecond";
import { RF_TOKEN_F } from "../../services/refreshToken/refreshToken";
import {
  CHECK_UPGRADE,
  GET_INFOR,
} from "../../services/handleInfor/handleInfor";
import { MOCK_DATA_USER } from "../../data/data";
import {
  GET_ALL_ACCOUNT_ORDER,
  LOG_OUT,
} from "../../services/handleAccount/handleAccount";
const AppContainer = styled.div`
  width: 100%;
  height: 100vh; /* Đảm bảo chiếm toàn bộ chiều cao màn hình */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow-y: scroll;
  overflow-x: hidden;
`;
const Base = ({ children }) => {
  let Nav = useNavigate();

  const [stateCart, setStateCart] = useState({});
  const [stateAccount, setStateAccount] = useState({});
  const [render, setRender] = useState(false);
  const [stateData, setStateData] = useState({});
  const [stateUser, setStateUser] = useState(MOCK_DATA_USER);
  const [showInfor, setShowInfor] = useState(true);
  const [IsDaiLy, setIsDaiLy] = useState(false);

  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });
  const MutationRF = useMutation({
    mutationKey: ["Start"],
    mutationFn: (body) => {
      return RF_TOKEN_F(body);
    },
  });
  const DATA_GET_UPGRADE = useQuery({
    queryKey: ["USER_GET_STATUS_UPGRADE"],
    queryFn: async () => {
      const result = await CHECK_UPGRADE();
      if (result?.data?.data) {
        setStateData(result?.data?.data);

        if (result?.data?.data?.user_id) {
          setIsDaiLy(true);
        }
      }
      return result; // Ensure the result is returned
    },
  });

  const DATA_GET_ALL_PURCHASE_LASEST = useQuery({
    queryKey: ["DATA_GET_ALL_PURCHASE_LASEST"],
    queryFn: async () => {
      const result = await GET_ALL_ACCOUNT_ORDER({
        page: 1,
        limit: 10,
        date: "latest",
      });
      if (result?.data?.data) {
        setStateUser(result?.data?.data?.news_feed);
      }
      return result; // Ensure the result is returned
    },
  });

  const GET_CART_USER = useQuery({
    queryKey: ["GET_CART"],
    queryFn: async () => {
      const result = await GET_CART();
      if (result?.data?.data[0]) {
        setStateCart(result?.data?.data[0]);
      }
      return result; // Ensure the result is returned
    },
  });
  const GET_INFOR_ACCOUNT = useQuery({
    queryKey: ["GET_ACCOUNT"],
    queryFn: async () => {
      const result = await GET_INFOR();
      if (result?.data?.data) {
        setStateAccount(result?.data?.data);
      } else {
        MutationLogout.mutate(
          {},
          {
            onSuccess: (data) => {
              localStorage.clear();
              Nav("/login");
            },
            onError: (err) => {
              localStorage.clear();
              Nav("/login");
            },
          }
        );
      }
      return result;
    },
  });
  const handleTriggerRefreshStatusAccountUpgrade = () => {
    DATA_GET_UPGRADE.refetch();
  };
  const handleDataFromProduct = (data) => {
    console.log("T");
    GET_CART_USER.refetch();
  };
  const handleTrggerRefreshAccount = () => {
    GET_INFOR_ACCOUNT.refetch();
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      sendDataToBase: handleDataFromProduct,
      handleTrggerRefreshAccount: handleTrggerRefreshAccount,
      handleTriggerRefreshStatusAccountUpgrade:
        handleTriggerRefreshStatusAccountUpgrade,
      stateAccount: stateAccount,
      stateData: stateData,
      stateCart: stateCart,
      stateUser: stateUser,
      IsDaiLy: IsDaiLy,

      setShowInfor: setShowInfor,
      showInfor: showInfor,
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    const interval = setInterval(() => {
      if (!localStorage.getItem("token")) {
        return Nav("/");
      } else {
        const decodedToken = jwtDecode(localStorage.getItem("token"));

        if (stateAccount?._id && decodedToken?.user_id !== stateAccount?._id) {
          localStorage.clear();
        }
        if (
          decodedToken?.roles != parseInt(process.env.REACT_APP_ROLE_SELLER)
        ) {
          localStorage.clear();
        } else {
          setRender(true);
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {render ? (
        <>
          <AppContainer>
            {/* <PopupNotice></PopupNotice> */}
            <ReactNotifications></ReactNotifications>
            <Box
              sx={{
                width: "480px",
                maxWidth: "1320px",
                height: "100vh",

                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  height: "calc(100vh - 59px)",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  backgroundColor: "white",
                }}
              >
                {childrenWithProps}
              </Box>

              <FooterNav stateAccount={stateAccount}></FooterNav>
            </Box>
          </AppContainer>
        </>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            p: 4,
          }}
        >
          <img
            width={200}
            height={200}
            src={
              "https://global.discourse-cdn.com/sitepoint/original/3X/e/3/e352b26bbfa8b233050087d6cb32667da3ff809c.gif"
            }
          ></img>
        </Box>
      )}
    </>
  );
};

export default Base;
