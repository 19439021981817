import Infor from "./pages/Infor/Infor";
import Cart from "./pages/Cart/Cart";
import Order from "./pages/Order/Order";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import Product from "./pages/Product/ProductMB";
import ProductCategory from "./pages/productCategory/ProductCategory";
import Revenue from "./pages/Infor/revenue";
import ProductDetails from "./pages/productDetails/ProductDetails";
import ProviderDetails from "./pages/providerDetails/ProviderDetails";
import CustomerDetails from "./pages/CustomerDetails/CustomerDetails";
import Customer from "./pages/Customers/Customers";
import Homepage from "./pages/Homepage";
import InforView from "./pages/Infor/InforView";
import InforBank from "./pages/Infor/InforBank";
import InforPassword from "./pages/Infor/InforPassword";
import InforUpgrade from "./pages/Infor/InforUpgrade";
import InforRefLink from "./pages/Infor/inforRefLink";
import ListUpgrade from "./pages/Infor/InforListUpgrade";
import Rank from "./pages/Rank";
export const RoutePublic = [
  { path: "/home", element: <Homepage></Homepage> },
  { path: "/rank", element: <Rank></Rank> },
  { path: "/cart", element: <Cart></Cart> },
  { path: "/list-order", element: <Order></Order> },
  { path: "/orderDetails/:orderId", element: <OrderDetails></OrderDetails> },
  { path: "/product", element: <Product></Product> },
  {
    path: "/categories/:categoryId",
    element: <ProductCategory></ProductCategory>,
  },
  { path: "/pDetails/:productid", element: <ProductDetails></ProductDetails> },
  {
    path: "/providerDetails/:id",
    element: <ProviderDetails></ProviderDetails>,
  },
  { path: "/customer", element: <Customer></Customer> },
  {
    path: "/cDetails/:customerid",
    element: <CustomerDetails></CustomerDetails>,
  },
  { path: "/infor", element: <Infor></Infor> },
  { path: "/infor/view", element: <InforView></InforView> },
  { path: "/infor/bank", element: <InforBank></InforBank> },
  { path: "/infor/edit", element: <Order></Order> },
  { path: "/infor/password", element: <InforPassword></InforPassword> },
  { path: "/infor/upgrade", element: <InforUpgrade></InforUpgrade> },
  { path: "/infor/list-upgrade", element: <ListUpgrade></ListUpgrade> },
  { path: "/infor/ref-link", element: <InforRefLink></InforRefLink> },
  { path: "/Revenue", element: <Revenue></Revenue> },
];
