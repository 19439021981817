import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  Columns as columns,
  Rows as rows,
  TriggerButton,
  StyledSnackbar,
  SnackbarContent,
  positioningStyles,
} from "./components/components";
import IMGTHANHTOANCHUYENHANG from "../../assets/logo/chuyển khoản ngân hàng.png";
import IMGTHANHTOANNHANHANG from "../../assets/logo/thanh toán khi nhận hàng.png";
import IMGNGUOIBANCHIUPHI from "../../assets/logo/người bán chịu phí.png";
import IMGKHACHHANGCHIUPHI from "../../assets/logo/khách hàng chịu phí.png";
import IMGEMPTYCART from "../../assets/logo/Bạn chưa có sản phẩm nào trong giỏ hàng.png";
import PaidIcon from "@mui/icons-material/Paid";
import { SimpleDialog } from "./components/components";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { mockdataProduct } from "../../data/data";
import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Transition } from "react-transition-group";
import { styled } from "@mui/system";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import {
  CREATE_ORDER,
  GET_CART,
  DELETE_ALL_PRODUCT_IN_CART,
} from "../../services/handleCart/handleCart";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Store } from "react-notifications-component";
import { ThongBaoThanhCong, ThongBaoThatBai } from "../../component/component";
import { REMOVE_ITEM_CART } from "../../services/handleCart/handleCart";
import ErrorIcon from "@mui/icons-material/Error";
import { SpanLable } from "./components/components";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Back from "../../component/Back";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
const CartMB = ({
  stateCustomer,
  sendDataToBase,
  TriggerRefeshData,
  stateCount,
  statePage,
  setStatePage,
  isloadingCustomer,
  stateName,
  setStateName,
}) => {
  const nav = useNavigate();

  const [openFail, setOpenFail] = useState(false);
  const [exited, setExited] = useState(true);
  const [stateCountTotal, setStateCountTotal] = React.useState({
    TotalProduct: 0,
    TotalMoney: 0,
  });
  const [error, setError] = useState({
    handleErrorChoose: false,
    handleErrorProduct: false,
  });

  const [stateOption, setStateOption] = useState({
    NBCP: "669fc269b373e28b740eecf0",
    KHCP: "669fc27cb373e28b740eecf1",
    TTNH: "669fbf6c5e23cda7dbdf2474",
    TTCK: "669fbf8a5e23cda7dbdf2475",
  });
  const [selectCustomer, setSelectCustomer] = useState({});
  const [selectMethodBuy, setSelectMethodBuy] = useState({
    id: stateOption.TTNH,
    name: "Thanh toán khi nhận hàng",
  });
  const [selectCostBearer, setSelectCostBearer] = useState({
    id: stateOption.NBCP,
    name: "Người bán chịu phí",
  });
  const [selectProduct, setSelectProduct] = useState([]);
  const [stateProduct, setStateProduct] = useState([]);
  const [Isloading, setIsloading] = useState(false);
  const [stateErrors, setStateErrors] = useState({});

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const nodeRef = useRef(null);

  const Handle_Remove_All_Prodcut_In_Cart = useMutation({
    mutationFn: (body) => {
      return DELETE_ALL_PRODUCT_IN_CART(body);
    },
  });
  const Handle_Remove_Item_cart = useMutation({
    mutationFn: (body) => {
      return REMOVE_ITEM_CART(body);
    },
  });
  const GET_CART_USER = useQuery({
    queryKey: ["GET_ALL_PRODUCT_OF_CART"],
    queryFn: async () => {
      const result = await GET_CART();
      if (result?.data?.data[0]) {
        setStateProduct(result?.data?.data[0]?.purchase_items);
        setSelectProduct(result?.data?.data[0]?.purchase_items);
        setChecked(result?.data?.data[0]?.purchase_items.map(() => true));
        setStateCountTotal({
          ...stateCountTotal,
          TotalMoney: result?.data?.data[0]?.purchase_items.reduce(
            (accumulator, currentItem) => {
              return (
                accumulator +
                currentItem?.product_price_for_customer *
                  parseInt(currentItem?.buy_count)
              );
            },
            0
          ),
          TotalProduct: result?.data?.data[0]?.purchase_items.length || 0,
        });
      }
      return result; // Ensure the result is returned
    },
  });
  const SELLER_CREATE_ORDER = useMutation({
    mutationFn: (body) => {
      return CREATE_ORDER(body);
    },
  });
  const handleCloseFail = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFail(false);
  };
  // Hàm để thay đổi view theo id
  const updateBuyCount = (id, buyCount) => {
    const update = stateProduct.map((obj) => {
      if (obj.product_id === id) {
        // Kiểm tra nếu buy_count >= 1
        if (obj.buy_count + buyCount < 1) {
          alert("Số lượng mua phải từ 1");
        } else {
          if (obj.buy_count + buyCount > obj.initial_stock) {
            alert("Số lượng mua đã vượt quá kho");
          } else {
            return { ...obj, buy_count: obj.buy_count + buyCount };
          }
        }
        // Cập nhật buy_count
      }
      return obj;
    });
    let updatedChecked;

    updatedChecked = [...checked];
    const selectedItems = update.filter((_, i) => updatedChecked[i]);
    setSelectProduct(selectedItems);

    setStateProduct(update);
    setStateCountTotal({
      ...stateCountTotal,
      TotalMoney: selectedItems.reduce((accumulator, currentItem) => {
        return (
          accumulator +
          currentItem?.product_price_for_customer *
            parseInt(currentItem?.buy_count)
        );
      }, 0),
      TotalProduct: selectedItems.length,
    });
    setError({ ...error, handleErrorProduct: false });
  };

  // Gọi hàm này khi cần thay đổi view
  const handleChangeBuyCount = (id, buyCount) => {
    console.log("change");
    updateBuyCount(id, buyCount);
  };

  const handleChangeBuyCountInput = (id, buyCount) => {
    let insert = buyCount < 1 ? 1 : buyCount;
    const update = stateProduct.map((obj) =>
      obj.product_id === id ? { ...obj, buy_count: insert } : obj
    );

    let updatedChecked;

    updatedChecked = [...checked];
    const selectedItems = update.filter((_, i) => updatedChecked[i]);
    setSelectProduct(selectedItems);
    setStateProduct(update);
    setStateCountTotal({
      ...stateCountTotal,
      TotalMoney: selectedItems.reduce((accumulator, currentItem) => {
        return (
          accumulator +
          currentItem?.product_price_for_customer *
            parseInt(currentItem?.buy_count)
        );
      }, 0),
      TotalProduct: selectedItems.length,
    });
    setError({ ...error, handleErrorProduct: false });
  };
  const handleClickFail = () => {
    setIsloading(true);
    if (!selectCustomer?._id && selectProduct.length === 0) {
      setError({ ...error, handleErrorChoose: true, handleErrorProduct: true });
      setOpenFail(true);
      setIsloading(false);
      return;
    } else {
      if (!selectCustomer?._id) {
        setError({ ...error, handleErrorChoose: true });
        setOpenFail(true);
        setIsloading(false);
        return;
      }

      if (selectProduct.length === 0) {
        setError({ ...error, handleErrorProduct: true });
        setOpenFail(true);
        setIsloading(false);
        return;
      }
    }

    if (
      selectCustomer?._id &&
      selectMethodBuy?.id &&
      selectProduct.length !== 0
    ) {
      let FORM = {
        purchase_items: selectProduct,
        customer_id: selectCustomer?._id,
        cost_bearer_id: selectCostBearer?.id,
        payment_method_id: selectMethodBuy?.id,
      };
      SELLER_CREATE_ORDER.mutate(FORM, {
        onSuccess: (data) => {
          if (data?.data) {
            setIsloading(false);

            const processItemsSequentially = async () => {
              for (const item of selectProduct) {
                const FormSend = {
                  purchase_items: [
                    {
                      product_id: item?.product_id,
                    },
                  ],
                };

                try {
                  await new Promise((resolve, reject) => {
                    Handle_Remove_Item_cart.mutate(FormSend, {
                      onSuccess: resolve,
                      onError: reject,
                    });
                  });
                } catch (error) {
                  console.log("Error: ", error);
                  return; // Dừng lại nếu có lỗi
                }
              }

              // Sau khi hoàn thành tất cả các thao tác
              sendDataToBase();
              GET_CART_USER.refetch();
              TriggerRefeshData();
            };

            processItemsSequentially();

            Store.addNotification({
              ...ThongBaoThanhCong,
              container: "top-right",
            });
            setSelectMethodBuy({
              id: stateOption.TTNH,
              name: "Thanh toán khi nhận hàng",
            });
            setSelectCostBearer({
              id: stateOption.NBCP,
              name: "Người bán chịu phí",
            });
            setStateCountTotal({
              TotalProduct: 0,
              TotalMoney: 0,
            });
            setSelectCustomer({});
            setStateErrors({});
            setSelectProduct([]);
            setStateProduct([]);
            setChecked(stateProduct.map(() => false));
          } else {
            setStateErrors(data);
            console.log("data?.errors " + JSON.stringify(data?.errors));
            Store.addNotification({
              ...ThongBaoThatBai,
              container: "top-right",
            });
            setIsloading(false);
          }
        },
        onError: (err) => {
          alert("Lỗi, vui lòng thử lại sau");
          console.log("err " + err);
          setIsloading(false);
        },
      });
    }
  };

  const handleOnEnterFail = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };

  const handleChange = (event, index) => {
    //Chọn vật phẩm
    let updatedChecked;
    if (index === -1) {
      const allChecked = event.target.checked;
      updatedChecked = checked.map(() => allChecked);
    } else {
      updatedChecked = [...checked];
      updatedChecked[index] = event.target.checked;
    }

    setChecked(updatedChecked);

    const selectedItems = stateProduct.filter((_, i) => updatedChecked[i]);
    setSelectProduct(selectedItems);
    setStateCountTotal({
      ...stateCountTotal,
      TotalMoney: selectedItems.reduce((accumulator, currentItem) => {
        return (
          accumulator +
          currentItem?.product_price_for_customer *
            parseInt(currentItem?.buy_count)
        );
      }, 0),
      TotalProduct: selectedItems.length,
    });
    setError({ ...error, handleErrorProduct: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setError({ ...error, handleErrorChoose: false });
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectCustomer(value);
  };
  const handleRemoveItem = (id) => {
    let FormSend = {
      purchase_items: [
        {
          product_id: id,
        },
      ],
    };

    Handle_Remove_Item_cart.mutate(FormSend, {
      onSuccess: (data) => {
        sendDataToBase();
        GET_CART_USER.refetch();
        TriggerRefeshData();
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
  };
  const ComponentIteam = ({
    index,
    stateProduct,
    handleChangeBuyCount,
    handleChangeBuyCountInput,
  }) => {
    const [stateBuyCount, setStateBuyCunt] = useState(
      stateProduct?.buy_count || 0
    );
    return (
      <ListItem>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems="center">
            <ListItemAvatar>
              <img
                src={stateProduct?.product_images[0]?.path}
                width={50}
                height={50}
              ></img>
            </ListItemAvatar>
            <ListItemText
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                color: "black",
              }}
              primary={
                <>
                  {" "}
                  <Typography
                    color={`${
                      stateErrors?.errors &&
                      stateErrors?.errors.includes(stateProduct?.product_id) ===
                        true
                        ? "red"
                        : ""
                    }`}
                    fontSize={"1rem"}
                    fontWeight={700}
                    onClick={() => {
                      nav(`/pDetails/${stateProduct?.product_id}`);
                    }}
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {stateProduct?.product_name}
                  </Typography>
                  {stateErrors?.errors &&
                  stateErrors?.errors.includes(stateProduct?.product_id) ===
                    true ? (
                    <Typography fontSize={"12px"} color={"red"}>
                      *
                      {stateErrors?.message === "Out of stock"
                        ? "Số lượng đơn hàng đã vượt quá kho"
                        : "Sản phẩm này đã ngưng hoạt động"}
                    </Typography>
                  ) : (
                    ""
                  )}
                </>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline", color: "black" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    giá :{" "}
                    {parseInt(
                      stateProduct?.product_price_for_customer
                    ).toLocaleString("en-US")}{" "}
                    VND
                  </Typography>
                  <div className="flex-auto">
                    <label
                      htmlFor="horizontal-buttons"
                      style={{ color: "black", fontWeight: "200" }}
                    >
                      <span>
                        Số lượng tồn kho: {stateProduct?.initial_stock}
                      </span>

                      <Box display={"flex"}>
                        <div className="container">
                          <button
                            onClick={() => {
                              handleChangeBuyCount(
                                stateProduct?.product_id,
                                -1
                              );
                            }}
                            className="button"
                          >
                            -
                          </button>

                          <input
                            className="input"
                            defaultValue={stateBuyCount}
                            type="number"
                            min={1}
                            max={stateProduct?.initial_stock}
                            onBlur={(e) => {
                              if (
                                parseInt(e.target.value) <=
                                  stateProduct?.initial_stock &&
                                parseInt(e.target.value) >= 0
                              ) {
                                handleChangeBuyCountInput(
                                  stateProduct?.product_id,
                                  parseInt(e.target.value)
                                );
                              } else {
                                handleChangeBuyCountInput(
                                  stateProduct?.product_id,
                                  parseInt(e.target.defaultValue)
                                );
                              }
                            }}
                          />

                          <button
                            onClick={() => {
                              handleChangeBuyCount(stateProduct?.product_id, 1);
                            }}
                            className="button"
                          >
                            +
                          </button>
                        </div>
                      </Box>
                    </label>
                  </div>
                </React.Fragment>
              }
            />
          </Box>
        </Box>
      </ListItem>
    );
  };
  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {GET_CART_USER.isFetching ? (
        <Typography>*Đang tải dữ liệu vui lòng chờ một chút.....</Typography>
      ) : (
        <>
          {stateProduct.length === 0 ? (
            <>
              <img
                src={IMGEMPTYCART}
                style={{ margin: "auto" }}
                width={500}
                height={500}
              ></img>
            </>
          ) : (
            <>
              {stateProduct &&
                stateProduct.map((item, index) => (
                  <>
                    <Box display={"flex"}>
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ justifyContent: "space-between" }}
                        style={{
                          marginBottom: "20px",
                          justifyContent: "space-evenly",
                        }}
                        control={
                          <Checkbox
                            checked={checked[index]}
                            onChange={(event) => handleChange(event, index)}
                          />
                        }
                      />

                      <ComponentIteam
                        index={index}
                        stateProduct={item}
                        handleChangeBuyCount={handleChangeBuyCount}
                        handleChangeBuyCountInput={handleChangeBuyCountInput}
                      ></ComponentIteam>

                      <Button
                        color="error"
                        style={{ margin: "auto", marginRight: "20px" }}
                        onClick={() => {
                          handleRemoveItem(item?.product_id);
                        }}
                      >
                        <DeleteForeverIcon></DeleteForeverIcon>
                      </Button>
                    </Box>
                  </>
                ))}
            </>
          )}
        </>
      )}
    </Box>
  );

  return (
    <>
      <Box className="AddpadingLeftProduct">
        <Back content={"Giỏ hàng"}></Back>
        <Box height={"auto"} width={"100%"}>
          <Box
            sx={{
              borderTop: "solid 5px #eeedee",
              borderBottom: "solid 5px #eeedee",
              padding: "16px",
            }}
          >
            <FormControlLabel
              sx={{ justifyContent: "space-between", margin: "0px" }}
              label={
                <SpanLable
                  fontWeight={600}
                  color={"#1F1F1F"}
                  fontSize={"20px"}
                  content={"Chọn tất cả sản phẩm"}
                ></SpanLable>
              }
              control={
                <Checkbox
                  checked={
                    Array.isArray(checked) && checked.length > 0
                      ? checked.every(Boolean)
                      : false
                  }
                  indeterminate={
                    Array.isArray(checked) && checked.length > 0
                      ? checked.some(Boolean) && !checked.every(Boolean)
                      : false
                  }
                  onChange={(event) => handleChange(event, -1)}
                />
              }
            />
            {error.handleErrorProduct ? (
              <Typography
                display={"flex"}
                alignItems={"center"}
                color={"error"}
              >
                <ErrorIcon></ErrorIcon>
                Vui lòng chọn sản phẩm
              </Typography>
            ) : (
              ""
            )}
            {children}
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: "solid 5px #eeedee",
            borderBottom: "solid 5px #eeedee",
            padding: "16px",
          }}
        >
          <FormLabel
            fontWeight="500"
            id="demo-radio-buttons-group-label"
            sx={{
              color: "black",
              fontWeight: "600",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <PaidIcon sx={{ fontSize: "1.4rem" }}></PaidIcon>
            <Typography
              ml={1}
              fontWeight={600}
              color={"#1F1F1F"}
              fontSize={"20px"}
            >
              {" "}
              Tổng số tiền
            </Typography>
          </FormLabel>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              <CategoryIcon sx={{ marginRight: "1rem" }}></CategoryIcon>Sản phẩm
              : {stateCountTotal?.TotalProduct}{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"600"}>
              <AttachMoneyIcon sx={{ marginRight: "1rem" }}></AttachMoneyIcon>
              Tổng Số tiền :{" "}
              {parseInt(stateCountTotal?.TotalMoney).toLocaleString(
                "en-us"
              )}{" "}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: "solid 5px #eeedee",
            borderBottom: "solid 5px #eeedee",
            padding: "16px",
          }}
        >
          <FormLabel
            fontWeight="500"
            id="demo-radio-buttons-group-label"
            sx={{
              color: "black",
              fontWeight: "600",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <LocationOnOutlinedIcon
              sx={{ fontSize: "1.4rem" }}
            ></LocationOnOutlinedIcon>
            <Typography
              ml={1}
              fontWeight={600}
              color={"#1F1F1F"}
              fontSize={"20px"}
            >
              {" "}
              Danh sách khách hàng
            </Typography>
          </FormLabel>

          <Button
            sx={{ fontSize: ".9rem", width: "100%", fontWeight: "600" }}
            variant="outlined"
            onClick={handleClickOpen}
          >
            Chọn khách hàng
          </Button>

          <Typography
            variant="subtitle1"
            sx={{
              fontSize: ".9rem",
              color: `${selectCustomer.name ? "green" : "grey"}`,
            }}
            component="div"
          >
            {selectCustomer.name ? `   Đã chọn : ${selectCustomer.name}` : ""}
            {error.handleErrorChoose ? (
              <Typography
                display={"flex"}
                alignItems={"center"}
                color={"error"}
              >
                <ErrorIcon></ErrorIcon>
                Chưa có khách hàng
              </Typography>
            ) : (
              ""
            )}
          </Typography>

          <SimpleDialog
            selectedValue={selectCustomer}
            open={open}
            onClose={handleClose}
            Emails={stateCustomer}
            setSelectCustomer={setSelectCustomer}
            stateCount={stateCount}
            statePage={statePage}
            setStatePage={setStatePage}
            isloadingCustomer={isloadingCustomer}
            stateName={stateName}
            setStateName={setStateName}
          />
        </Box>
        <FormControl
          fullWidth
          sx={{
            borderTop: "solid 5px #eeedee",
            borderBottom: "solid 5px #eeedee",
            padding: "16px",
          }}
        >
          <FormLabel
            fontWeight="500"
            id="demo-radio-buttons-group-label"
            sx={{
              color: "black",
              fontWeight: "600",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {" "}
            <PaymentOutlinedIcon
              sx={{ fontSize: "1.4rem" }}
            ></PaymentOutlinedIcon>
            <Typography
              ml={1}
              fontWeight={600}
              color={"#1F1F1F"}
              fontSize={"20px"}
            >
              {" "}
              Phương thức thanh toán
            </Typography>
          </FormLabel>
          <RadioGroup
            style={{ paddingBottom: "12px" }}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="NH"
            name="radio-buttons-group"
            onChange={(e) => {
              if (e.target.value === "NH") {
                setSelectMethodBuy({
                  id: stateOption.TTNH,
                  name: "Thanh toán khi nhận hàng",
                });
              } else {
                setSelectMethodBuy({
                  id: stateOption.TTCK,
                  name: "Thanh toán chuyển khoản",
                });
              }
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              sx={{ justifyContent: "space-between", margin: "0px" }}
              value="NH"
              control={<Radio />}
              label={
                <SpanLable
                  icon={IMGTHANHTOANNHANHANG}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  content={`Thanh toán khi nhận hàng`}
                ></SpanLable>
              }
            />
            <hr style={{ color: "#5b6265", width: "100%" }}></hr>
            <FormControlLabel
              labelPlacement="start"
              sx={{ justifyContent: "space-between", margin: "0px" }}
              value="online"
              control={<Radio />}
              label={
                <SpanLable
                  fontSize={"16px"}
                  fontWeight={"600"}
                  icon={IMGTHANHTOANCHUYENHANG}
                  content={"Thanh toán bằng chuyển khoản"}
                ></SpanLable>
              }
            />
          </RadioGroup>
        </FormControl>

        {/* phí ship */}

        <FormControl
          fullWidth
          sx={{
            borderTop: "solid 5px #eeedee",
            borderBottom: "solid 5px #eeedee",
            padding: "16px",
          }}
        >
          <FormLabel
            id="demo-radio-buttons-group-label"
            style={{ paddingBottom: "12px" }}
            sx={{
              color: "black",
              fontWeight: "600",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {" "}
            <PaidOutlinedIcon sx={{ fontSize: "1.4rem" }}></PaidOutlinedIcon>
            <Typography
              ml={1}
              fontWeight={600}
              color={"#1F1F1F"}
              fontSize={"20px"}
            >
              {" "}
              Người chịu chi phí vận chuyển
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="DL"
            name="radio-buttons-group"
            onChange={(e) => {
              if (e.target.value === "DL") {
                setSelectCostBearer({
                  id: stateOption.NBCP,
                  name: "Người bán chịu phí",
                });
              } else {
                setSelectCostBearer({
                  id: stateOption.KHCP,
                  name: "khach hàng  chịu phí",
                });
              }
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              sx={{ justifyContent: "space-between", margin: "0px" }}
              value="DL"
              control={<Radio />}
              label={
                <SpanLable
                  fontSize={"16px"}
                  fontWeight={"600"}
                  icon={IMGNGUOIBANCHIUPHI}
                  content={"Người bán chịu phí"}
                ></SpanLable>
              }
            />
            <hr style={{ color: "#5b6265", width: "100%" }}></hr>
            <FormControlLabel
              labelPlacement="start"
              sx={{ justifyContent: "space-between", margin: "0px" }}
              value="KH"
              control={<Radio />}
              label={
                <SpanLable
                  fontSize={"16px"}
                  fontWeight={"600"}
                  icon={IMGKHACHHANGCHIUPHI}
                  content={"Khách hàng chịu phí"}
                ></SpanLable>
              }
            />
          </RadioGroup>
        </FormControl>

        <Typography p={1}>
          <React.Fragment>
            {Isloading ? (
              <TriggerButton
                sx={{ backgroundColor: "orangered", color: "white" }}
                type="button"
              >
                <CircularProgress></CircularProgress>
              </TriggerButton>
            ) : (
              <TriggerButton
                sx={{ backgroundColor: "orangered", color: "white" }}
                type="button"
                onClick={handleClickFail}
              >
                Lên đơn
              </TriggerButton>
            )}

            <StyledSnackbar
              autoHideDuration={2000}
              open={openFail}
              onClose={handleCloseFail}
              exited={exited}
            >
              <Transition
                timeout={{ enter: 400, exit: 400 }}
                in={openFail}
                appear
                unmountOnExit
                onEnter={handleOnEnterFail}
                onExited={handleOnExited}
                nodeRef={nodeRef}
              >
                {(status) => (
                  <SnackbarContent
                    style={{
                      transform: positioningStyles[status],
                      transition: "transform 300ms ease",
                    }}
                    ref={nodeRef}
                  >
                    <WarningAmberIcon color="warning"></WarningAmberIcon>
                    <div className="snackbar-message">
                      <p className="snackbar-title">Thông báo</p>
                      <p className="snackbar-description">
                        Vui lòng chọn đầy đủ thông tin
                      </p>
                    </div>
                    <CloseIcon
                      onClick={handleCloseFail}
                      className="snackbar-close-icon"
                    />
                  </SnackbarContent>
                )}
              </Transition>
            </StyledSnackbar>
          </React.Fragment>
        </Typography>
      </Box>
    </>
  );
};

export default CartMB;
