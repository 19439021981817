// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-notification {
    margin-bottom: 50px !important; /* Điều chỉnh giá trị này để thay đổi khoảng cách so với cạnh dưới */
  }`, "",{"version":3,"sources":["webpack://./src/pages/Product/Components/style.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B,EAAE,oEAAoE;EACtG","sourcesContent":[".custom-notification {\r\n    margin-bottom: 50px !important; /* Điều chỉnh giá trị này để thay đổi khoảng cách so với cạnh dưới */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
