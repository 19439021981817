import CartIcon from "./Icons/CartIcon";
import QuantityButton from "./QuantityButton";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Rating,
  Snackbar,
} from "@mui/material";
import "./style.css";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styleModal } from "../../../component/component";
import { useMutation } from "@tanstack/react-query";
import { CREATE_CART } from "../../../services/handleCart/handleCart";
import CircularProgress from "@mui/material/CircularProgress";
import { notification } from "./Component";
import { Store } from "react-notifications-component";
import CheckIcon from "@mui/icons-material/Check";
import {
  CaseDatetimeToYYYYMMDDHHMMSS,
  CloseModal,
} from "../../../component/function";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import ShareIcon from "@mui/icons-material/Share";
import { LINKAFFILIATEPRODUCT } from "../../../contanst/contanst";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
const Description = ({
  TrigeerSendBase,
  IsSelectStore,
  onSelectStore,
  onQuant,
  onAdd,
  onRemove,
  onSetOrderedQuant,
  stateProduct,
  DataGetProduct,
  stateData,
  stateReview,
  stateTotalPages,
  stateTotalReview,
  setLimit,
  limit,
  isFetching,
  IsDaiLy,
}) => {
  // stateData?.benefits.includes(searchString)
  const [IsShowDesc, setIsShowDesc] = React.useState(false);
  const [value, setValue] = React.useState("female");
  const [store, setStore] = React.useState({});
  const [Buy_count, setBuy_count] = React.useState(0);

  const [Isloading, setIsloading] = React.useState(false);
  const [stateContent, setStateContent] = React.useState("");
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const onAddBuy = () => {
    setBuy_count(Buy_count + 1);
  };
  const onRemoveBuy = () => {
    setBuy_count(Buy_count - 1);
  };
  const HANDLE_CREATE_CART = useMutation({
    mutationFn: (body) => {
      return CREATE_CART(body);
    },
  });
  const handleChange = (event) => {
    onSelectStore();

    if (stateProduct?.store?.id === event.target.value) {
      setStore(stateProduct?.store);
    }
    if (stateProduct?.store_company?.id === event.target.value) {
      setStore(stateProduct?.store_company);
    }
    setBuy_count(1);
    setValue(event.target.value);
  };
  const handleCreateCart = () => {
    if (Buy_count <= 0) {
      alert("Vui lòng chọn số lượng sản phẩm !!");
    } else {
      setIsloading(true);
      let FormCreate = {
        purchase_items: [
          {
            product_id: stateProduct?._id,
            buy_count: Buy_count,
            store_id: store?.id,
          },
        ],
      };

      HANDLE_CREATE_CART.mutate(FormCreate, {
        onSuccess: (data) => {
          setIsloading(false);
          TrigeerSendBase();

          Store.addNotification({
            ...notification,
            container: "bottom-center",
          });
        },
        onError: (err) => {
          setIsloading(false);
          console.log("on Err " + err);
        },
      });
    }
  };
  const handleClick = (newState) => () => {
    setStateContent("Đã sao chép nội dung");
    let getlink = stateProduct?.content;
    // console.log("get link " + getlink);
    navigator.clipboard
      .writeText(getlink)
      .then(() => {
        setState({ ...newState, open: true });
        console.log(`Base URL copied to clipboard: ${getlink}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleClickGetAffiliate = (newState) => () => {
    setStateContent("Đã lấy link sản phẩm");
    let getlink = LINKAFFILIATEPRODUCT + `/${stateProduct?._id}`;
    // console.log("get link " + getlink);
    navigator.clipboard
      .writeText(getlink)
      .then(() => {
        setState({ ...newState, open: true });
        console.log(`Base URL copied to clipboard: ${getlink}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleDowloadOnePic = (image, name) => {
    console.log("check imgage " + image);
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${name}.jpg`; // Tên file khi tải xuống
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Failed to download image"));
  };
  const handleDowloadAll = (image, name) => {
    image.forEach((image, index) => {
      fetch(image?.path)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `${name}"-"${index + 1}.jpg`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => alert("Failed to download image"));
    });
  };
  const TextCheck = ({ content }) => {
    return (
      <span>
        <CheckIcon sx={{ marginRight: "10px" }}></CheckIcon> {content}
      </span>
    );
  };
  return (
    <>
      <section style={{ padding: "10px 25px" }} className="description">
        <p className="pre">{stateProduct?.category?.name} </p>
        <h1>{stateProduct && stateProduct?.name}</h1>
        <p
          style={{
            height: IsShowDesc ? "100%" : "150px",
          }}
          className={`desc ${IsShowDesc ? "" : "ShadowLine"}`}
        >
          {stateProduct && stateProduct?.description}
        </p>
        <Button
          variant="outlined"
          onClick={() => {
            setIsShowDesc(!IsShowDesc);
          }}
          sx={{
            borderColor: "#393E40",
            color: "#393E40",
            borderWidth: "1px",
            borderRadius: "20px",
          }}
        >
          {!IsShowDesc ? (
            <>
              {" "}
              Xem thêm
              <KeyboardArrowDownRoundedIcon
                sx={{ marginLeft: ".5rem" }}
              ></KeyboardArrowDownRoundedIcon>
            </>
          ) : (
            <>
              Thu gọn
              <KeyboardArrowUpRoundedIcon
                sx={{ marginLeft: ".5rem" }}
              ></KeyboardArrowUpRoundedIcon>
            </>
          )}
        </Button>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="row"
          gap={"10px"}
          fontWeight={700}
          fontSize={"18px"}
        >
          <Button
            className="col-5"
            onClick={handleOpenModal}
            sx={{
              backgroundColor: "#EFEFEF",
              borderRadius: "10px",
              padding: "10px",
              color: "#5C6366",
            }}
          >
            Đăng bán
          </Button>
          {stateData?.benefits ? (
            stateData?.benefits.includes(
              process.env.REACT_APP_AFFILIATE_PRODUCT_FUNC
            ) ? (
              <Button
                className="col-6"
                onClick={handleClickGetAffiliate({
                  vertical: "bottom",
                  horizontal: "center",
                })}
                variant="contained"
                color="info"
              >
                <ShareIcon></ShareIcon>
                Lấy link tiếp thị
              </Button>
            ) : (
              <Button
                className="col-6"
                onClick={() => {
                  alert("Tài khoản này không được phép dùng tính năng này !");
                }}
                variant="contained"
                color="info"
              >
                <ShareIcon></ShareIcon>
                Lấy link tiếp thị
              </Button>
            )
          ) : (
            <Button
              className="col-6"
              onClick={() => {
                alert("Tài khoản này không được phép dùng tính năng này !");
              }}
              variant="contained"
              color="info"
            >
              <ShareIcon></ShareIcon>
              Lấy link tiếp thị
            </Button>
          )}
        </Box>
        <div className="price">
          <div className="main-tag">
            <p>
              {stateProduct &&
                parseInt(stateProduct?.price_for_customer).toLocaleString(
                  "en-us"
                )}{" "}
              đ
            </p>
            <p>{stateProduct && parseInt(stateProduct?.sales)}%</p>
          </div>
        </div>

        <Box>
          {stateProduct && stateProduct?.sales > 0 ? (
            <s>
              {parseInt(stateProduct?.price_retail || 0).toLocaleString(
                "en-US"
              )}{" "}
              đ
            </s>
          ) : (
            ""
          )}

          {/* <Typography className="text-secondary">
          Đã bán{" "}
          {stateProduct && parseInt(stateProduct?.sold).toLocaleString("en-US")}
        </Typography> */}
        </Box>
        <Box className="text-primary cssforloi">
          Số tiền lợi nhuận:{" "}
          {IsDaiLy && stateProduct
            ? parseInt(
                stateProduct?.profit_for_seller_and_collaborator || 0
              ).toLocaleString("en-US")
            : parseInt(
                stateProduct?.profit_for_collaborator || 0
              ).toLocaleString("en-US")}
          đ
        </Box>
        <FormControl>
          <FormLabel
            sx={{ fontSize: "1rem", fontWeight: "700" }}
            id="demo-controlled-radio-buttons-group"
          >
            Kho
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {stateProduct?.store_company ? (
              <FormControlLabel
                value={stateProduct?.store_company?.id}
                control={<Radio />}
                label={
                  <Typography className="text-secondary">
                    {stateProduct?.store_company?.name} - Số lượng :{" "}
                    {stateProduct?.store_company?.stock}
                  </Typography>
                }
              />
            ) : (
              ""
            )}
          </RadioGroup>
        </FormControl>
        <div className="buttons">
          <QuantityButton
            store={store}
            IsSelectStore={IsSelectStore}
            onQuant={Buy_count}
            onRemove={onRemoveBuy}
            onAdd={onAddBuy}
          />
          {Isloading ? (
            <button className="add-to-cart">
              <CartIcon />
              <CircularProgress></CircularProgress>
            </button>
          ) : (
            <button
              className="add-to-cart"
              onClick={() => {
                if (store?.id) {
                  onSetOrderedQuant(onQuant);
                  handleCreateCart();
                } else {
                  alert("Vui lòng chọn kho");
                }
              }}
            >
              <CartIcon />
              Thêm vào giỏ hàng
            </button>
          )}
        </div>

        <Modal
          style={{ paddingBottom: "6%" }}
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              ...styleModal,
              width: "355px",
              height: "500px",
              overflow: "scroll",
            }}
          >
            <CloseModal handleclose={handleCloseModal}></CloseModal>
            <Box>
              {" "}
              <h2 id="child-modal-title">Đăng bán</h2>
            </Box>

            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box
                sx={{
                  height: "500px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  display: "flex",
                  overflowX: "hidden",
                  whiteSpace: "break-spaces",
                }}
              >
                {stateProduct?.content ? (
                  stateProduct?.content
                ) : (
                  <Typography
                    margin={"auto"}
                    variant="subtitle2
                "
                    color={"GrayText"}
                  >
                    *Đang cập nhật nhật nội dung
                  </Typography>
                )}
              </Box>

              <Box pt={2} pb={2} className="row">
                {stateProduct &&
                  stateProduct?.images.map((item, index) => (
                    <>
                      <div
                        onClick={() => {
                          handleDowloadOnePic(item?.path, stateProduct?.name);
                        }}
                        className="image-container col-3"
                      >
                        <img width={150} height={150} src={item?.path} />
                        <div className="overlay">
                          <div className="text">
                            <VerticalAlignBottomIcon></VerticalAlignBottomIcon>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </Box>

              <Box justifyContent={"space-between"} gap={2} className="row">
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: "13px",
                    color: "#1B3FE4",
                    textAlign: "center",
                  }}
                  onClick={handleClick({
                    vertical: "bottom",
                    horizontal: "center",
                  })}
                >
                  <ContentCopyIcon
                    sx={{ marginRight: "5px", fontSize: "15px" }}
                  ></ContentCopyIcon>
                  Sao chép nội dung
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    fontSize: "13px",
                    color: "#1B3FE4",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    handleDowloadAll(stateProduct?.images, stateProduct?.name);
                  }}
                >
                  <VerticalAlignBottomIcon
                    sx={{ marginRight: "5px", fontSize: "15px" }}
                  ></VerticalAlignBottomIcon>
                  Tải tẩt cả ảnh
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={<TextCheck content={stateContent}></TextCheck>}
          key={vertical + horizontal}
        />
      </section>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        width={"100%"}
        className="description"
        gap={2}
        sx={{
          padding: "10px 25px",
          borderTop: "solid 8px #eeedee",
        }}
        pt={3}
        pb={3}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"column"}
            gap={1}
          >
            <Typography fontSize={"14px"} color={"#393E40"} fontWeight={600}>
              Đánh Giá - Nhận Xét
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Typography fontSize={"14px"} color={"#393E40"} fontWeight={600}>
                {" "}
                {stateReview?.avg_rating}{" "}
              </Typography>
              <Rating
                name="half-rating-read"
                defaultValue={parseInt(stateReview?.avg_rating)}
                precision={0.25}
                readOnly
                sx={{ fontSize: "2rem" }}
              />{" "}
              <Typography fontSize={"13px"} color={"#747B7E"}>
                {" "}
                (Xem {stateReview?.reviews
                  ? stateReview?.reviews.length
                  : 0}{" "}
                đánh giá)
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          width={"100%"}
          gap={2}
          sx={{
            borderTop: "solid 1px #eeedee",
          }}
          pt={3}
        >
          {isFetching ? (
            <Typography p={3}>Đang tải dữ liệu....</Typography>
          ) : (
            <>
              {" "}
              {stateReview?.reviews &&
                stateReview?.reviews.map((item, index) => (
                  <>
                    {" "}
                    <Box display={"flex"} gap={2}>
                      <Avatar src={item?.seller_info?.avatar || ""}></Avatar>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                        width={"100%"}
                      >
                        <Typography
                          fontSize={"14px"}
                          color={"#393E40"}
                          fontWeight={600}
                        >
                          {item?.seller_info?.name}
                        </Typography>
                        <Rating
                          name="half-rating-read"
                          defaultValue={parseInt(item?.rating) || 5}
                          precision={0.5}
                          readOnly
                          sx={{ fontSize: "15px" }}
                        />{" "}
                        <Typography fontSize={"10px"} color={"#747B7E"}>
                          Thời điểm :{" "}
                          {CaseDatetimeToYYYYMMDDHHMMSS(item?.created_at)}
                        </Typography>
                        <Typography fontSize={"14px"} color={"#393E40"}>
                          {item?.comment}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ))}
            </>
          )}
        </Box>
      </Box>

      <Box display={"flex"} width={"100%"} p={2} sx={{ borderWidth: "1px" }}>
        <Typography
          margin={"auto"}
          sx={{
            "&:hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          fontSize={"14px"}
          color={"#000000"}
          onClick={() => {
            setLimit(stateTotalReview);
          }}
        >
          Xem tất cả {stateTotalReview ? stateTotalReview : 0} đánh giá{" "}
          <KeyboardArrowRightOutlinedIcon
            style={{ marginLeft: "5px", fontSize: "14px" }}
          ></KeyboardArrowRightOutlinedIcon>
        </Typography>
      </Box>
    </>
  );
};

export default Description;
