// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the form */
.formCss {
  background: linear-gradient(135deg, #9ddddf 0%, #a5c6db 100%); 
  padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    transition: transform 0.3s ease-in-out;
  }
  

  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    border-radius: 10px;
    border: 2px solid #457b9d;
    padding: 10px 15px;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .form-control:focus {
    border-color: #a8dadc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .form-control::placeholder {
    color: #d9d9d9;
  }
  
  label {
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
  }
  
  textarea.form-control {
    resize: none;
  }
  
  /* Additional custom styles */
  body {
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;

  }
  
  @media (max-width: 768px) {
    .formCss {
      padding: 20px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/CustomerDetails/style.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,6DAA6D;EAC7D,aAAa;IACX,mBAAmB;IACnB,0CAA0C;IAC1C,gBAAgB;IAChB,YAAY;IACZ,sCAAsC;EACxC;;;;EAIA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,uCAAuC;IACvC,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA,6BAA6B;EAC7B;IACE,yBAAyB;IACzB,gCAAgC;;EAElC;;EAEA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":["/* Custom styles for the form */\r\n.formCss {\r\n  background: linear-gradient(135deg, #9ddddf 0%, #a5c6db 100%); \r\n  padding: 20px;\r\n    border-radius: 15px;\r\n    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);\r\n    max-width: 600px;\r\n    margin: auto;\r\n    transition: transform 0.3s ease-in-out;\r\n  }\r\n  \r\n\r\n  \r\n  .form-group {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .form-control {\r\n    border-radius: 10px;\r\n    border: 2px solid #457b9d;\r\n    padding: 10px 15px;\r\n    font-size: 16px;\r\n    transition: all 0.3s ease;\r\n  }\r\n  \r\n  .form-control:focus {\r\n    border-color: #a8dadc;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n    outline: none;\r\n  }\r\n  \r\n  .form-control::placeholder {\r\n    color: #d9d9d9;\r\n  }\r\n  \r\n  label {\r\n    font-weight: bold;\r\n    color: #fff;\r\n    margin-bottom: 10px;\r\n    display: block;\r\n    font-size: 14px;\r\n  }\r\n  \r\n  textarea.form-control {\r\n    resize: none;\r\n  }\r\n  \r\n  /* Additional custom styles */\r\n  body {\r\n    background-color: #f9f9f9;\r\n    font-family: 'Arial', sans-serif;\r\n\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .formCss {\r\n      padding: 20px;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
