import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import IMGEMPTYDH from "../../assets/image/emptyDH.jpg";
import { GET_ALL_PURCHASE } from "../../services/handleOrder/handleOrder";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SearchIcon from "@mui/icons-material/Search";
import Back from "../../component/Back";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../component/function";
import {
  UnstyledSelectIntroduction,
  SelectOldNewOrder,
} from "./Components/Components";
const Order = ({ IsDaiLy }) => {
  let nav = useNavigate();
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(10);
  const [stateSort, setStateSort] = useState("asc");
  const [stateSortDate, setStateSortDate] = useState("latest");
  const [stateStatus, setStateStatus] = useState("");
  const [statePurchase, setStatePurchase] = useState([]);
  const [stateTotal, setStateTotal] = useState(0);
  const [stateCount, setStateCount] = useState(0);
  const [stateTotalPurchase, setStateTotalPurchase] = useState(0);
  const [code_purchase, setCode_purchase] = useState("");
  const handleChange = (event, value) => {
    setStatePage(value);
  };
  const GET_ALL_DATA_PURCHASE = useQuery({
    queryKey: [
      "GET_ALL_DATA_PURCHASE",
      statePage,
      stateStatus,
      stateSort,
      stateSortDate,
      code_purchase,
    ],
    queryFn: async () => {
      const result = await GET_ALL_PURCHASE({
        page: statePage,
        limit: stateLimit,
        sort: stateSort,
        status: stateStatus,
        date: stateSortDate,
        code_purchase: code_purchase,
      });

      if (result?.data?.data) {
        setStateTotal(result?.data?.data?.total_profit_for_seller);
        setStatePurchase(result?.data?.data?.purchases);
        setStateCount(result?.data?.data?.total_page);
        setStateTotalPurchase(result?.data?.data?.total_purchase);
      }
      return result; // Ensure the result is returned
    },
  });
  const handleRefreshData = () => {
    GET_ALL_DATA_PURCHASE.refetch();
  };
  return (
    <>
      <Box className="PaddingLRForCustomer">
        <Back content={"Quản lý đơn hàng"}></Back>
        <Box
          width={"100%"}
          p={2}
          height={"auto"}
          sx={{ backgroundColor: "#dfe3ff" }}
        >
          <Box
            justifyContent={"space-between"}
            alignItems={"center"}
            display={"flex"}
          >
            <Box className="col-4">
              {" "}
              <Typography color={"#393E40"} fontSize={"18px"} fontWeight={600}>
                Đơn hàng
              </Typography>
            </Box>
            <Box className="col-8">
              <TextField
                variant="outlined"
                placeholder="Tìm kiếm đơn hàng.."
                value={code_purchase}
                onChange={(e) => {
                  setCode_purchase(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ height: "40px" }} position="start">
                      <SearchIcon
                        sx={{
                          "&:hover": {
                            color: "white",
                            transition: "transform 0.3s, color 0.3s",
                            cursor: "pointer",
                          },
                        }}
                        fontSize="large"
                        color="action"
                      ></SearchIcon>
                    </InputAdornment>
                  ),
                  style: {
                    height: "40px",
                  },
                }}
                fullWidth
                sx={{
                  backgroundColor: "#EFEFEF",
                  borderRadius: "20px",
                  height: "40px",
                  cursor: "pointer",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none", // Xóa viền ngoài
                    },
                    "&:hover fieldset": {
                      border: "none", // Xóa viền khi hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "none", // Xóa viền khi có focus
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px",
                    height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
                  },
                  "& .MuiInputBase-input": {
                    padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
                  },
                }}
              />
            </Box>
          </Box>

          <Box gap={3} p={2} className="row">
            <Box className="col-md-5">
              <Box p={1} display={"flex"} borderRadius={20} bgcolor={"white"}>
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Đơn hàng : {stateTotalPurchase}
                </Typography>
              </Box>
              {/* <Box
                mt={2}
                p={1}
                display={"flex"}
                borderRadius={20}
                bgcolor={"white"}
              >
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Thành công:{" "}
                  {statePurchase &&
                    statePurchase.filter((item, index) => {
                      return (
                        item.status ===
                        parseInt(process.env.REACT_APP_DELIVERED)
                      );
                    }).length}
                </Typography>
              </Box> */}
            </Box>
            <Box className="col-md-6">
              <Box p={1} display={"flex"} borderRadius={20} bgcolor={"white"}>
                <Typography margin={"auto"} fontSize={"14px"} fontWeight={700}>
                  {" "}
                  Doanh thu {" : "}
                  {parseInt(stateTotal).toLocaleString("en-us")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Stack
          direction="row"
          borderBottom={"solid 3px gainsboro"}
          // divider={<Divider orientation="vertical" flexItem />}
          justifyContent={"space-between"}
        >
          <Box
            p={2}
            flex={0.5}
            textAlign={"center"}
            borderRight={"solid 1px #f7f7f7"}
          >
            <SelectOldNewOrder
              setStatePage={setStatePage}
              setStateSortDate={setStateSortDate}
              stateSortDate={stateSortDate}
            ></SelectOldNewOrder>
          </Box>
          <Box
            p={2}
            flex={1}
            textAlign={"center"}
            borderRight={"solid 1px #f7f7f7"}
          >
            {" "}
            <Typography fontWeight={600}>
              <UnstyledSelectIntroduction
                stateStatus={stateStatus}
                setStatePage={setStatePage}
                setStateStatus={setStateStatus}
                handleRefreshData={handleRefreshData}
              ></UnstyledSelectIntroduction>
            </Typography>
          </Box>
        </Stack>

        {GET_ALL_DATA_PURCHASE.isFetching ? (
          <Typography p={3}>Đang tải dữ liệu....</Typography>
        ) : (
          <>
            {" "}
            {statePurchase?.length !== 0 ? (
              <>
                {" "}
                {statePurchase &&
                  statePurchase.map((item, index) => (
                    <Box style={{ backgroundColor: "white" }}>
                      <Box
                        display={"flex"}
                        padding={"12px 12px 0px 12px"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        borderBottom={"solid 1px gainsboro"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          nav(`/orderDetails/${item?._id}`, {
                            state: {
                              ...item,
                              IsDaiLy: IsDaiLy,
                            },
                          });
                        }}
                      >
                        <ul
                          style={{
                            padding: "0px",
                            lineHeight: "20px",
                            width: "80%",
                          }}
                        >
                          <li>
                            <Typography
                              fontWeight={700}
                              color={"#607CFB"}
                              fontSize={".7rem"}
                            >
                              <LinkIcon color={"#607CFB"} fontSize="small">
                                {" "}
                              </LinkIcon>{" "}
                              Mã đơn hàng : {item?.code_purchase}
                            </Typography>
                          </li>

                          <Typography variant="subtitle1" fontWeight={"600"}>
                            **Thông tin khách hàng
                          </Typography>
                          <li>
                            <Typography variant="subtitle2">
                              Tên: {item?.customer?.name}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              <PhoneAndroidIcon
                                fontSize="small"
                                sx={{ marginRight: "5px" }}
                              ></PhoneAndroidIcon>
                              Số điện thoại{" : "} {item?.customer?.phone}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2" color={"#607CFB"}>
                              <LocationOnRoundedIcon
                                fontSize="small"
                                sx={{ marginRight: "5px" }}
                              ></LocationOnRoundedIcon>
                              Địa chỉ{" : "}
                              {item?.customer?.address}
                            </Typography>
                          </li>
                          <Typography variant="subtitle1" fontWeight={"600"}>
                            **Thống kê
                          </Typography>

                          <Typography variant="subtitle2">
                            Số lượng sản phẩm{" : "}
                            {item?.count_product || 0}
                          </Typography>

                          <li>
                            <Typography variant="subtitle2">
                              Tổng giá trị đơn hàng :{" "}
                              {parseInt(
                                item?.purchase_total_price
                              ).toLocaleString("en-us")}{" "}
                              đ
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="subtitle2">
                              Lợi nhuận thu về :{" "}
                              {parseInt(
                                item?.purchase_total_profit_final_for_seller ||
                                  0
                              ).toLocaleString("en-US")}
                              đ
                            </Typography>
                          </li>
                          <Typography variant="subtitle1" fontWeight={"600"}>
                            **Thời điểm lên đơn :{" "}
                            {CaseDatetimeToYYYYMMDDHHMMSS(item?.created_at)}
                          </Typography>
                          <Typography variant="subtitle1" fontWeight={"600"}>
                            {item?.payment_method?._id ===
                            process.env.REACT_APP_PAYMENT_METHOD_CK
                              ? "(+) Cần xác nhận thanh toán chuyển khoản"
                              : ""}
                          </Typography>
                        </ul>
                      </Box>

                      <Box borderBottom={"solid 5px #eeedee"} p={"12px"}>
                        {item?.status ===
                        parseInt(
                          process.env.REACT_APP_WAIT_FOR_CONFIRMATION
                        ) ? (
                          <Typography color={"#9b9b61"}>
                            Tình trạng đơn : ĐANG CHỜ XÁC NHẬN
                          </Typography>
                        ) : item?.status ===
                          parseInt(process.env.REACT_APP_WAIT_FOR_GETTING) ? (
                          <Typography color={"#00BFFF"}>
                            Tình trạng đơn : ĐANG ĐƯỢC LẤY HÀNG
                          </Typography>
                        ) : item?.status ===
                          parseInt(process.env.REACT_APP_IN_PROGRESS) ? (
                          <Typography color={"#FFA500"}>
                            Tình trạng đơn : ĐƠN ĐANG VẬN CHUYỂN
                          </Typography>
                        ) : item?.status ===
                          parseInt(process.env.REACT_APP_DELIVERED) ? (
                          <Typography color={"#008000"}>
                            Tình trạng đơn : ĐÃ ĐƯỢC GIAO
                          </Typography>
                        ) : (
                          <Typography color={"#FF0000"}>
                            Tình trạng đơn : ĐÃ HUỶ
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ))}
              </>
            ) : (
              <>
                {" "}
                <img src={IMGEMPTYDH} p={3}></img>
              </>
            )}
          </>
        )}

        <Box p={4}>
          <Stack spacing={2}>
            <Typography>Trang: {statePage}</Typography>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={stateCount}
              page={statePage}
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default Order;
