import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Back from "../../component/Back";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IMGINFOLINK from "../../assets/image/infor-link.jpg";
import CheckIcon from "@mui/icons-material/Check";
import ShareIcon from "@mui/icons-material/Share";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const InforRefLink = ({
  stateData,
  stateAccount,
  handleTrggerRefreshAccount,
}) => {
  let nav = useNavigate();
  useEffect(() => {
    if (
      !stateData?.benefits?.includes(
        process.env.REACT_APP_AFFILIATE_INVITE_FUNC
      )
    ) {
      nav("/infor");
    }
  }, [stateData]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  var currentURL = window.location.origin;
  let getlink = currentURL + `/#/registry?ref=${localStorage.getItem("id")}`;

  const TextCheck = () => {
    return (
      <span>
        <CheckIcon sx={{ marginRight: "10px" }}></CheckIcon> Đã lấy link giới
        thiệu
      </span>
    );
  };
  const handleClick = (newState) => () => {
    var currentURL = window.location.origin;
    let getlink = currentURL + `/#/registry?ref=${localStorage.getItem("id")}`;
    if (!localStorage.getItem("id")) {
      alert("Đã có lỗi xảy ra, vui lòng đăng xuất vào lại");
    } else {
      navigator.clipboard
        .writeText(getlink)
        .then(() => {
          setState({ ...newState, open: true });
          console.log(`Base URL copied to clipboard: ${getlink}`);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  return (
    <>
      <Box className="PaddingLRForInfor">
        <Back content={"Giới thiệu tikpii"}></Back>
        <img
          src={IMGINFOLINK}
          width={"100%"}
          style={{ objectFit: "cover" }}
          height={"auto"}
        ></img>
        <Box p={"16px"} bgcolor={"#f2f2f4"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={"10px"}
          >
            <Typography fontSize={"14px"} fontWeight={600} color={"#747B7E"}>
              Link giới thiệu
            </Typography>
            <Typography fontSize={"12px"} color={"#747B7E"}>
              Sao chép một liên kết bên dưới để gửi đến Đối tác quan tâm đến
              kinh doanh của Tikpii.
            </Typography>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={2}
          p={"16px"}
        >
          <Typography fontWeight={600} color={"#393E40"} fontSize={"16px"}>
            Cho phép trải nghiệm ứng dụng
          </Typography>
          <Typography color={"#393E40"} fontSize={"14px"}>
            Mời người tham gia có thể nhận được một số quyền lợi.
          </Typography>

          <Typography
            p={"12px"}
            bgcolor={"#efefef"}
            borderRadius={"10px"}
            color={"#747B7E"}
            fontSize={"14px"}
          >
            {getlink}
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box width={"48%"}>
              <Button
                onClick={handleClick({
                  vertical: "bottom",
                  horizontal: "center",
                })}
                fullWidth
                variant="outlined"
              >
                {" "}
                <ContentCopyIcon
                  sx={{ marginRight: "5px", fontSize: "15px" }}
                ></ContentCopyIcon>
                Sao chép
              </Button>
            </Box>
            <Box width={"48%"}>
              <Button fullWidth variant="contained">
                {" "}
                <ShareIcon
                  sx={{ marginRight: "5px", fontSize: "15px" }}
                ></ShareIcon>
                Chia sẻ
              </Button>
            </Box>
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={<TextCheck></TextCheck>}
          key={vertical + horizontal}
        />
      </Box>
    </>
  );
};
export default InforRefLink;
