import "./App.css";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import { RoutePublic } from "./route";
import Auth from "./pages/Auth/Auth";
import SignupForm from "./pages/AccountBox/signupForm";
import Base from "./component/Base/Base";
import Registry from "./pages/AccountBox/registry";
import ShareOrderDetails from "./pages/OrderDetails/ShareOrderDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Auth check={process.env.ADMIN}></Auth>}></Route>
      <Route
        path="/login"
        element={<Auth check={process.env.ADMIN}></Auth>}
      ></Route>
      <Route path="/sign-up" element={<SignupForm></SignupForm>}></Route>
      <Route path="/registry/" element={<Registry></Registry>}></Route>
      <Route
        path="/share/orderDetails"
        element={<ShareOrderDetails></ShareOrderDetails>}
      ></Route>

      {RoutePublic.map((item, index) => (
        <Route path={item?.path} element={<Base>{item?.element}</Base>}></Route>
      ))}
    </Routes>
  );
}

export default App;
