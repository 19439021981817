import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

import thumb1 from "../Pictures/image-product-1-thumbnail.jpg";
import deleteIcon from "../Pictures/icon-delete.svg";
import { useMutation } from "@tanstack/react-query";
import { REMOVE_ITEM_CART } from "../../../services/handleCart/handleCart";

import { useNavigate } from "react-router-dom";
const Product = ({
  onOrderedQuant,
  onReset,
  stateProduct,
  stateCart,
  Trigger,
}) => {
  let nav = useNavigate();
  const Handle_Remove_Item_cart = useMutation({
    mutationFn: (body) => {
      return REMOVE_ITEM_CART(body);
    },
  });
  const handleRemoveCartItem = (item) => {
    let FormSend = {
      purchase_items: [
        {
          product_id: item?.product_id,
        },
      ],
    };

    Handle_Remove_Item_cart.mutate(FormSend, {
      onSuccess: (data) => {
        Trigger();
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
  };
  return (
    <>
      <Box height={"400px"} width={"100%"} sx={{ overflowY: "scroll" }}>
        {stateCart && stateCart.length > 0
          ? stateCart.map((item, index) => (
              <div className="product">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  width={"100%"}
                  mb={2}
                  sx={{
                    "&:hover": {
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => {
                    window.location.href = `/#/pDetails/${item?.product_id}`;
                  }}
                >
                  <img
                    src={item ? item?.product_images[0]?.path : ""}
                    alt="product-thumbnail"
                  />
                  <div className="info">
                    <p
                      style={{
                        width: "200px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.product_name}
                    </p>
                    <p>
                      Giá sản phẩm:{" "}
                      {(item?.product_price_for_customer).toLocaleString(
                        "en-us"
                      )}{" "}
                    </p>
                    <div className="price">
                      <span style={{ color: "green" }}>
                        Lợi nhuận:{" "}
                        {parseInt(item?.total_profit_for_seller).toLocaleString(
                          "en-us"
                        )}{" "}
                        VND
                      </span>
                      <br></br>
                      <span>
                        Số lượng:{" "}
                        {(
                          parseInt(item?.total_profit_for_seller) /
                          parseInt(item?.buy_count)
                        ).toLocaleString("en-us")}{" "}
                        x {parseInt(item?.buy_count)}
                      </span>
                    </div>
                    <p
                      style={{
                        width: "200px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Kho : {item?.store_name}{" "}
                    </p>
                  </div>
                  <IconButton
                    className="delete-button"
                    size="small"
                    disableRipple
                    onClick={() => {
                      handleRemoveCartItem(item);
                    }}
                  >
                    <img src={deleteIcon} alt="delete-item" />
                  </IconButton>
                </Box>
              </div>
            ))
          : "Giỏ hàng rỗng "}
      </Box>
    </>
  );
};

export default Product;
