import { Box } from "@mui/material";
import styled from "styled-components";

export const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "70%",
  width: "330px",
  borderWidth: "1px",
  borderColor: "black",
  borderRadius: "10px",
  color: "black",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};
const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;
export const FormBase = ({ children }) => {
  return (
    <>
      <AppContainer>
        <Box
          sx={{
            width: "480px",
            maxWidth: "1320px",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // backgroundColor: "blueviolet",
          }}
        >
          {children}
        </Box>
      </AppContainer>
    </>
  );
};
export const ThongBaoSuccess = ({ title, message }) => {
  return {
    title: title,
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated", "animate__fadeOut"], // `animate.css v4` classes
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  };
};
export const ThongBaoFails = ({ title, message }) => {
  return {
    title: title,
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated", "animate__fadeOut"], // `animate.css v4` classes
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  };
};
export const ThongBaoThanhCong = {
  title: "Lên đơn thành công!",
  message: "Hãy kiểm tra lại trong mục lên đơn",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"], // `animate.css v4` classes
  animationOut: ["animate__animated", "animate__fadeOut"], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const ThongBaoThatBai = {
  title: "Giao dịch thất bại !!",
  message: "Vui lòng Kiểm tra sản phẩm",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"], // `animate.css v4` classes
  animationOut: ["animate__animated", "animate__fadeOut"], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
