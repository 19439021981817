import { Box, Typography } from "@mui/material";
import BACK from "../assets/logo/quay lại.png";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Back = ({ content }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Lùi về trang trước
  };
  return (
    <Box
      position={"sticky"}
      top={0}
      bgcolor={"white"}
      zIndex={1}
      display={"flex"}
      width={"100%"}
      alignItems={"center"}
      height={"80px"}
      p={1}
      borderBottom={"solid 2px #eeedee"}
    >
      <ArrowBackIcon
        color="primary"
        sx={{ cursor: "pointer" }}
        onClick={handleGoBack}
      ></ArrowBackIcon>
      <Typography ml={1} fontWeight={700}>
        {content}
      </Typography>{" "}
    </Box>
  );
};
export default Back;
