import api from "../common/apis";
import NProgress from "nprogress";
export const GET_CART = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/purchases/get-cart`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const CREATE_CART = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/sellers/purchases/add-to-cart`,
      {
        purchase_items: req.purchase_items,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          // Authorization: `Bearer ${localStorage.getItem("tk")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const CREATE_ORDER = async (req) => {
  try {
    NProgress.start();
    const respod = await api.post(
      `/sellers/purchases/create-order`,
      {
        purchase_items: req.purchase_items,

        customer_id: req.customer_id,
        cost_bearer_id: req.cost_bearer_id,
        payment_method_id: req.payment_method_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          // Authorization: `Bearer ${localStorage.getItem("tk")}`,
        },
      }
    );

    return respod.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_PRODUCT = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/products/${req}`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const DELETE_ALL_PRODUCT_IN_CART = async (req) => {
  try {
    NProgress.start();
    const res = await api.delete(`/sellers/purchases/remove-cart`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const REMOVE_ITEM_CART = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(`/sellers/purchases/remove-from-cart`, {
      purchase_items: req.purchase_items,
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
