import Axios from "axios";
import api from "../common/apis";
import NProgress from "nprogress";
import { URL_BACKEND } from "../../contanst/contanst";

const apiLogout = Axios.create({
  baseURL: `${URL_BACKEND}`, // Đặt base URL cho tất cả các yêu cầu
  withCredentials: true,
  timeout: 15000,
});

export const HandleLogin = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/auth/login`,
      {
        email: req.email,
        password: req.password,
        role: parseInt(process.env.REACT_APP_ROLE_SELLER),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Handle_GET_USER = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/auth/get-account-upgraded-by-id/${req}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const Handle_Check_User = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/auth/check-seller`,
      {
        identifier: req.identifier,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const HandleRegister = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/auth/register`,
      {
        name: req.name,
        phone: req.phone,
        email: req.email,
        date_of_birth: req.date_of_birth,
        address: req.address,
        password: req.password,
        confirm_password: req.confirm_password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          // 'Authorization': `Bearer ${yourAccessToken}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const HandleRegisterCustomer = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/auth/send-form`,
      {
        name: req.name,
        phone: req.phone,
        email: req.email,

        preferred_time: req.preferred_time,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          // 'Authorization': `Bearer ${yourAccessToken}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const HandleRegister_By_ref = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/auth/registry?ref=${req.userid}`,
      {
        name: req.name,
        phone: req.phone,
        email: req.email,
        date_of_birth: req.date_of_birth,
        address: req.address,
        password: req.password,
        confirm_password: req.confirm_password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Thêm các header khác nếu cần
          // 'Authorization': `Bearer ${yourAccessToken}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ALL_ACCOUNT_ORDER = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/sellers/statistics/news-feed`, {
      params: {
        page: req.page,
        limit: req.limit,
        date: req.date,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const GET_ALL_ACCOUNT_SELLER_RANK = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/sellers/statistics/statistic-top-affiliate-sellers`,
      {
        startTime: req.startTime,
        endTime: req.endTime,
        top: req.top,
        sort: req.sort,
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const LOG_OUT = async (req) => {
  try {
    const res = await api.post(
      `/auth/logout`,
      { role: parseInt(process.env.REACT_APP_ROLE_SELLER) },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
